import React from 'react';
import { Typography, Scrollbar } from 'src/ui-kit';
import { ReactComponent as CloseIcon } from 'src/assets/images/close.svg';
import styles from './Gdpr.module.scss';
import { HELP_EMAIL, PRODUCT_NAME, PROD_LINK } from 'src/constants';

const Gdpr = ({ goBack, isDarkTheme }: { goBack: () => void; isDarkTheme: boolean }) => (
  <div className={styles.termsAndConditions}>
    <div className={styles.termsAndConditionsInner}>
      <Typography.H3 className={styles.headingTC}>
        {PRODUCT_NAME} Privacy Notice
      </Typography.H3>
      <button onClick={goBack} className={styles.closeGdpr}>
        <CloseIcon />
      </button>
    </div>
    <Scrollbar height="calc(100vh - 180px)" trackYRight={-48} isDarkTheme={isDarkTheme}>
      <Typography.P1>
        This privacy notice aims to give you information on how {PRODUCT_NAME} collects,
        uses, discloses, transfers, stores and processes your information when you use our
        services, including any data you may provide through your use of our services.
      </Typography.P1>
      <Typography.P1>
        At {PRODUCT_NAME}, we understand that your privacy is important. We respect and
        value the privacy of everyone who visits {PROD_LINK} and its subdomains (the
        “Sites”) or uses any of our applications (the “Apps”) on their devices. We only
        collect and use your Data as described in this Privacy Notice (“Notice”) and as
        permitted by Data Protection Legislation.
      </Typography.P1>
      <Typography.P1>
        It is important that you read this privacy notice together with any other privacy
        notice or fair processing notice we may provide on specific occasions when we are
        collecting or processing information about you so that you are fully aware of how
        and why we are using your data. This privacy notice supplements the other notices
        and is not intended to override them.
      </Typography.P1>
      <Typography.P1>
        Capitalised terms used in this Notice are defined in the Glossary below
      </Typography.P1>
      <Typography.P1>
        Please note that our Sites are not intended for use by individuals under 18 years
        old.
      </Typography.P1>
      <Typography.P1>1. Who does this privacy notice apply to?</Typography.P1>
      <Typography.P1>This notice applies to: </Typography.P1>
      <Typography.P1>
        <ul>
          <li>
            our service users who access our services such as our websites or content
            anywhere on the web, participate in our surveys and focus groups and other
            users;
          </li>
          <li>our clients, suppliers and business partners; and</li>
          <li>other persons who interact with us, when you call us or email us.</li>
        </ul>
      </Typography.P1>
      <Typography.P1>
        This notice applies to you whether you act in your personal capacity or as
        employee or agent of an organisation.{' '}
      </Typography.P1>
      <Typography.P1>2. Who are we?</Typography.P1>
      <Typography.P1>Our Sites are owned and operated by {PRODUCT_NAME}.</Typography.P1>
      <Typography.P1>
        This privacy notice explains how we, {PRODUCT_NAME}, a company registered in the
        United Kingdom (company registration number: 15275858) at 32 Compayne Gardens,
        Flat 2, London, England, NW6 3DN, UK (“{PRODUCT_NAME}”, “we”, “us”, or “our”) and
        process your personal data (“you”, “your”) when you use our websites, content, or
        other services.
      </Typography.P1>
      <Typography.P1>
        For the purposes of the Data Protection Legislation, we are the data controller
        which means that we are responsible for determining the purposes for which and
        means of how your Data is processed.{' '}
      </Typography.P1>
      <Typography.P1>
        If you have any concerns about how we Process or protect your Data or would like
        to contact us about any aspect of this Notice, please get in touch with our Data
        Protection Officer, who oversees our handling of Data, and who can be contacted
        at: {HELP_EMAIL}
      </Typography.P1>
      <Typography.P1>
        This Notice should be read together with our Cookies Policy [link to be inserted],
        and our Sites terms of use [link to be inserted to T&Cs].{' '}
      </Typography.P1>
      <Typography.P1>3. What Data do we collect about you?</Typography.P1>
      <Typography.P1>
        We may collect Data about you from the following sources:
      </Typography.P1>
      <Typography.P1>
        <ul>
          <li>
            From you (for example, when you create an account, make a payment, tell us
            about your preferences or respond to our campaigns, communicate with us, or
            use our Sites or Apps);
          </li>
          <li>From when we host social events;</li>
          <li>
            From public sources of information such as public records or social media
            postings;
          </li>
          <li>
            From providers about your interactions on the Sites and from cookies and
            tracking devices on your devices where you have permitted their use;
          </li>
          <li>
            from third parties, advertisers and we may also receive information from other
            parties in accordance with our responsibilities as registered business
          </li>
        </ul>
      </Typography.P1>
      <Typography.P1>We will collect the following types of information:</Typography.P1>
      <table>
        <thead>
          <tr>
            <td>Type of Data</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Your personal and contact details</td>
            <td>
              Information including your name, email address, username, and password.
              Company name (optional).
            </td>
          </tr>
          <tr>
            <td>Account information </td>
            <td>
              Information about your account with us, including your login details for our
              Sites, date of birth, unique account number, unique customer identification,
              read-only API keys from cryptocurrency exchanges, transactions history on
              crypto exchanges, marketing preferences, complaints details and any notes
              added to your account.
            </td>
          </tr>
          <tr>
            <td>Financial information</td>
            <td>
              Such as information that allows us to understand your creditworthiness or
              your payment method, including bank account or payment card details. We may
              use third party payment processing services to enable us to provide our
              services to you.
            </td>
          </tr>
          <tr>
            <td>Your communications via our Sites and Apps</td>
            <td>
              Such as chat conversations via the Sites, your recorded telephone
              conversations with our customer support staff and emails.
            </td>
          </tr>
          <tr>
            <td>How you use the Sites</td>
            <td>
              Such as which pages you visit, which content you view and what links and
              buttons you click. This information may be collected through cookies or
              similar technologies. For more information, please see our Cookie Policy
              [link to be inserted].
            </td>
          </tr>
          <tr>
            <td>General Location information</td>
            <td>
              The device or computer you use to access the Sites will provide us with your
              IP address. The IP address tells us which city, county or country you are
              accessing the Sites from but does not give us detailed information about
              your location.
            </td>
          </tr>
          <tr>
            <td>Device and other technical information</td>
            <td>
              Such as the unique device identifier and other information about the
              device’s hardware and software.
            </td>
          </tr>
          <tr>
            <td>Advertising and direct marketing preferences and responses</td>
            <td>
              Such as your interaction with offers and competitions, including any
              requests to stop receiving marketing communications. This information may be
              collected through cookies or similar technologies. For more information,
              please see our Cookie Policy [link to be inserted].
            </td>
          </tr>
          <tr>
            <td>Sensitive Information </td>
            <td>
              We may also Process Data about you that is sensitive in order to meet our
              legal and regulatory obligations and to protect our business. This includes
              Data as required by the Gibraltar Regulation Authority (GRA), the AntiMoney
              Laundering Regulations and the Proceeds of Crime Act and otherwise any other
              information to fulfil our legal and regulatory obligations as a regulated
              financial services business. This also includes biometric data (such as
              through photograph and CCTV) and information about suspected fraud, theft or
              offences. If you come to an event that we host, this also includes
              information you provide (such as dietary preferences or disability
              information).
            </td>
          </tr>
        </tbody>
      </table>
      <Typography.P1>4. How we use your Data?</Typography.P1>
      <Typography.P1>
        We use your Data in the following ways, and for the following reasons:{' '}
      </Typography.P1>
      <table>
        <thead>
          <tr>
            <td>What we use your Data for </td>
            <td>The basis on which we can use your Data</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a new user on the Sites.</td>
            <td>
              We need to Process this information to meet our contractual obligations.
            </td>
          </tr>
          <tr>
            <td>To allow you to use our services (including managing payments)</td>
            <td>
              We need to Process this information to meet our contractual obligations and
              to comply with our legal and regulatory obligations.
            </td>
          </tr>
          <tr>
            <td>
              To communicate with you about updates to the Sites, our services, and any
              changes to our terms and conditions or Privacy Notice.
            </td>
            <td>
              We need to Process this Data to meet our contractual obligations; to comply
              with our legal and regulatory obligations; and it is in our legitimate
              business interests to keep accurate records.
            </td>
          </tr>
          <tr>
            <td>To receive feedback from you on our products and services</td>
            <td>
              We need to Process this Data to meet our contractual obligations and it is
              in our legitimate business interests to understand how we can improve our
              products and services. You do not have to provide us with this information.
            </td>
          </tr>
          <tr>
            <td>
              To run our promotional events such as competitions and offers which may be
              of interest to you. We may send you newsletters or other marketing material
              about our offers and events via email or text. You may opt out of direct
              marketing at any time (see below).{' '}
            </td>
            <td>
              We need to Process this Data as it is in our legitimate business interests
              to provide you with a personalized experience when you use our services.
              Where necessary, we Process this Data based on your consent.
            </td>
          </tr>
          <tr>
            <td>To provide customer support services. </td>
            <td>
              It is in our legitimate business interests to respond to any communications
              we receive from you. If you do not wish to provide us with this information,
              we may not be able to respond fully to your queries.
            </td>
          </tr>
          <tr>
            <td>To train our staff (for example our call staff).</td>
            <td>
              It is in our legitimate business interests to provide you with a helpful
              service.
            </td>
          </tr>
          <tr>
            <td>To maintain and administer our Sites and Apps.</td>
            <td>
              It is in our legitimate business interests to maintain our IT services,
              network security, to maintain our system; and we need to Process this
              information to comply with our legal and regulatory obligations.
            </td>
          </tr>
          <tr>
            <td>
              To improve our Sites, products and services, and experiences, such as by
              understanding analytics.
            </td>
            <td>
              It is in our legitimate business interests to better understand your
              preferences, update our Sites and develop our business strategy.
            </td>
          </tr>
          <tr>
            <td>
              To comply with our legal obligations. This may involve verifying your
              identity and age.
            </td>
            <td>
              We need to Process this information to comply with our legal and regulatory
              obligations and it is in our legitimate business interests to prevent fraud
              and illegal activities on our Sites.
            </td>
          </tr>
          <tr>
            <td>
              To protect our business from money laundering, terrorist financing and other
              illegal activities. We may identify you electronically using technology such
              as cookies.
            </td>
            <td>
              We need to Process this information to comply with our legal and regulatory
              obligations under financial services, anti-money laundering, anti-fraud, and
              anti-terrorism laws. We also Process this information in the public
              interest, and it is in our legitimate business interests to protect our
              business from any illegal or abusive use of our Sites.
            </td>
          </tr>
          <tr>
            <td>
              To provide you with advertising, which is relevant to you, and to understand
              your advertising preferences.
            </td>
            <td>
              It is in our legitimate business interests to understand how you use our
              Sites and how we should develop our marketing strategy.
            </td>
          </tr>
          <tr>
            <td>
              In relation with a legal action, or when acquiring or selling a business.
            </td>
            <td>
              It is in our legitimate business interest to be able to protect ourselves
              through legal action and to develop the business through acquiring or
              selling parts of our business.
            </td>
          </tr>
        </tbody>
      </table>
      <Typography.P1>
        We will not share any of your Data with any other organisation or third parties
        for any purposes other than storage on an email and / or web hosting server
        without your consent.{' '}
      </Typography.P1>
      <Typography.P1>5. Who do we share your Data with?</Typography.P1>
      <Typography.P1>
        In some cases, we may share your Data with third parties in order to support your
        needs, to provide you with services, or to comply with our legal obligations. We
        may also share Data with third parties if it is in the public interest or the
        sharing is in our legitimate interest or the legitimate interest of another
        organisation.{' '}
      </Typography.P1>
      <Typography.P1>
        The other organisations we may share your Data with are typically:
      </Typography.P1>
      <Typography.P1>
        <ul>
          <li>
            Third-party suppliers and service providers for the purposes identified above
            – in particular we work with platform services (to provide financial services
            and account management functions), cloud providers (to host the Sites),
            affiliate platform services, customer support software services, data storage
            services, payment service providers, know-your-customer and anti-money
            laundering services as well as enhanced due diligence and anti-fraud services,
            financial services regulators (such as the GRA), data matching services (to
            ensure we receive accurate information if you register with us) and click
            fraud detection and protection services.
          </li>
          <li>
            Business partners and other organisations to help us meet our contractual and
            regulatory obligations, including audit, legal and compliance services.{' '}
          </li>
          <li>
            Affiliates and third parties which you have opted out of marketing
            communications with, have been barred or have self-excluded, so that we can
            ensure that you do not receive unsolicited promotional material.{' '}
          </li>
          <li>
            Identity verification and fraud prevention agencies such as other financial
            services businesses, banks, credit card companies and similar agencies which
            investigate and prevent underage, fraudulent, criminal, or suspicious
            activity, or any other behavior we are legally required to investigate. We
            will also pass on your information if we have reason to believe you have
            undertaken such activity.{' '}
          </li>
          <li>
            Analytics and search engine providers and other selected organisations which
            provide us with feedback about our Sites or Apps and aid us in improving their
            optimisation.
          </li>
          <li>
            Statutory authorities when we are required to comply with a request for
            information, a court order disclose your Data, a regulatory investigation from
            a relevant governmental or financial or regulatory authority, our legal
            obligations including our requirement to report suspicious behavior.{' '}
          </li>
          <li>
            Regulator, law enforcement or fraud prevention agencies as well as legal
            professionals, courts and other adjudication services to investigate any
            actual or suspected criminal activity.{' '}
          </li>
        </ul>
      </Typography.P1>
      <Typography.P1>We may also share your Data to third parties:</Typography.P1>
      <Typography.P1>
        <ul>
          <li>
            If we consider selling or acquiring businesses or assets, in which case we
            will share your Data with the counterparty.
          </li>
          <li>
            If {PRODUCT_NAME}, becomes insolvent (i.e., becomes subject to administration
            or liquidation processes).{' '}
          </li>
          <li>
            If we, or substantially all of our assets, are acquired by a non-
            {PRODUCT_NAME}
            entity.{' '}
          </li>
          <li>
            When we check your identity when you first become a customer, we may share
            information with a Credit Reference Agency and this will leave a “soft”
            footprint on your credit file.{' '}
          </li>
          <li>If we need to enforce our terms and conditions.</li>
          <li>
            To protect our safety, rights or property, or the safety, rights or property
            of our customers, staff and others by sharing information with other companies
            and organisations such as the local police.{' '}
          </li>
          <li>Where we are required by law, we may share your Data with regulators</li>
        </ul>
      </Typography.P1>
      <Typography.P1>6. How long do we retain your Data?</Typography.P1>
      <Typography.P1>
        We will not keep your Data for longer than is required for the purposes for which
        we collected it, including for the purposes of satisfying any legal requirements.
        The length of time for which we retain your Data will depend on what we are using
        it for as set out in this Notice, the nature of the Data and how sensitive it is.
        For example, we will keep you email address while dealing with your enquiries but,
        even when you unsubscribe, we are required to continue to retain your email
        address to ensure that we do not send you any email communications in the future
        and for our know-your-customer (“KYC”) purposes.
      </Typography.P1>
      <Typography.P1>
        Please note, however, that we may be subject to legal and regulatory requirements
        to keep your Data for a longer period. We may also extend the retention times
        where the Data is needed to investigate a crime, handle a claim or resolve a
        complaint. As a general rule, we keep your Data based on the criteria below:
      </Typography.P1>
      <table>
        <thead>
          <tr>
            <td>Type of Data</td>
            <td>Typical Retention Time </td>
            <td>Information</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Marketing consents </td>
            <td>Until you no longer consent</td>
            <td>
              If you withdraw consent, we will keep this information on a ‘suppression
              list’ so we don’t contact you
            </td>
          </tr>
          <tr>
            <td>Customer call recordings</td>
            <td>24 Months</td>
            <td>An extended retention may be applied on a case-by-case basis</td>
          </tr>
          <tr>
            <td>Customer Data</td>
            <td>Seven years from the date your account is closed</td>
            <td>
              We will only continue to retain this Data where this is:
              <br />
              <br />
              (i) Legally required under financial services regulations or tax legislation
              or other regulations; or <br />
              <br />
              (ii) Required to exercise or defend our legal rights
            </td>
          </tr>
        </tbody>
      </table>
      <Typography.P1>
        Where it is no longer necessary to Process your Data, we will delete it or
        anonymise or aggregate it by removing all details that identify you in accordance
        with Data Protection Legislation.
      </Typography.P1>
      <Typography.P1>7. Where do we transfer your Data?</Typography.P1>
      <Typography.P1>
        We primarily store and Process our Account data within the European Economic Area
        (“EEA”). However, if we transfer your Data outside of the EEA, we ensure a similar
        degree of protection is afforded to it to safeguard your Data in accordance with
        Data Protection Legislation, which can include by: (i) ensuring that your Data is
        only processed in countries which provide adequate data protection laws (in
        accordance with the Data Protection Legislation); (ii) requiring recipients to
        sign up to strong contractual commitments that ensure the protection of your
        Personal Data (such as the EU Model Clauses); (iii) taking any other measures that
        comply with Data Protection Legislation.
      </Typography.P1>
      <Typography.P1>8. Keeping your Data safe</Typography.P1>
      <Typography.P1>
        While the nature of the internet means that the transmission of information may
        not be totally secure, we have implemented security measures to prevent your Data
        from being accidentally lost, used or accessed in an unauthorised way, altered or
        disclosed. Where you use a password to access certain services or features of our
        Sites, please keep this confidential and do not share with anyone. Unfortunately,
        we cannot guarantee the complete security of the information transmitted via the
        internet. While we have implemented security measures, any transmission is at your
        own risk.
      </Typography.P1>
      <Typography.P1>9. Cookies</Typography.P1>
      <Typography.P1>
        We use Cookies on our Sites and Apps for the purposes above. For more information,
        please see our Cookies Policy [link to be inserted].
      </Typography.P1>
      <Typography.P1>10. Marketing</Typography.P1>
      <Typography.P1>
        You can tell us whether or not you wish to be contacted for marketing purposes
        and, if so, how we can contact you. We will obtain this information from you when
        we first collect your Data and we may ask you what kind of communication you would
        like to receive from us.
      </Typography.P1>
      <Typography.P1>
        You can opt-out from receiving marketing communications from us at any time by
        following the instructions below:
      </Typography.P1>
      <Typography.P1>
        <ul>
          <li>
            Marketing: to opt-out from emails, SMS and calls by use the unsubscribe link
            provided within any correspondence or call you receive or manage your
            preferences by emailing: {HELP_EMAIL}
          </li>
          <li>
            Push notifications: to opt-out from receiving push notifications, you can
            disable push notifications on your device or browser settings.
          </li>
        </ul>
      </Typography.P1>
      <Typography.P1>
        Please be aware it may take up to 28 days for your request to take effect. Please
        note you will still receive other important information about our product and
        services.
      </Typography.P1>
      <Typography.P1>11. Your rights</Typography.P1>
      <Typography.P1>
        Under Data Protection Legislation, you have a number of rights in relation to your
        Data. We have listed these below, but please note that some only apply in certain
        specific circumstances (detailed in the Data Protection Legislation):
      </Typography.P1>
      <table>
        <thead>
          <tr>
            <td>Your rights </td>
            <td>How to exercise your rights</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Right to access and receive a copy of the Data we hold about you. </td>
            <td>
              It is generally free for you to request access to your Data by contacting
              our data protection representative by emailing: {HELP_EMAIL} If your request
              is repetitive or excessive, we may refuse to comply or we may charge you a
              fee.{' '}
            </td>
          </tr>
          <tr>
            <td>Right to correct any inaccurate Data we hold about you.</td>
            <td>
              You can amend, correct, delete or edit your Data if you wish to, please
              contact our customer support team by emailing: {HELP_EMAIL}
            </td>
          </tr>
          <tr>
            <td>
              Right to require us to erase your Data if (for example): (i) we no longer
              need the Data for the purpose we originally collected it for; (ii) we only
              collected it with your consent, and you now withdraw your consent; or (iii)
              you object to how we are Processing your Data.
            </td>
            <td>
              You can request erasure of your Data by contacting our customer support team
              by emailing: {HELP_EMAIL} This process is not reversible.
            </td>
          </tr>
          <tr>
            <td>
              Right to request that we restrict the Processing of your Data if (for
              example): (i) you believe that the Data we hold on you is inaccurate; (ii)
              you have the right to request that we erase your Data but would prefer us to
              restrict our Processing instead; or (iii) we no longer need the Data for the
              purpose we originally collected it for but you require the Data for legal
              actions.
            </td>
            <td>
              You can request the restriction of Processing of your Data by contacting our
              customer support team by emailing: {HELP_EMAIL} Once you have requested this
              you can change your mind at any time by contacting us again. Your account
              will not be accessible while the restriction is in place.
            </td>
          </tr>
          <tr>
            <td>
              Right to request a copy of the Data we hold on you in a structured, commonly
              used and machine readable format. You can also request that we transfer this
              to a third party on your request. Please note that this right may not apply
              to all of your Data.
            </td>
            <td>
              In some circumstances, you can request the transfer of your Data to a third
              party by emailing: {HELP_EMAIL} The request must include which Data you
              would like to be transferred, to whom it should be transferred and by which
              method.
            </td>
          </tr>
          <tr>
            <td>
              Right to object to our Processing of your Data, including for marketing
              purposes. Please note that in some cases, we may demonstrate that we have
              legitimate grounds to Process your information which overrides this right.
            </td>
            <td>
              You can object to Processing of your Data by contacting our customer support
              team by emailing {HELP_EMAIL} Once you have objected you can change your
              mind at any time by contacting us again. Your account will not be accessible
              while the restriction is in place.
            </td>
          </tr>
          <tr>
            <td>
              Right to not be subject to a decision based solely on an automated process,
              such as profiling, which results in you being significantly affected or
              produces legal effects concerning you.
            </td>
            <td>
              You can exercise this right by contacting our customer support team by
              emailing: {HELP_EMAIL}
            </td>
          </tr>
          <tr>
            <td>
              Right to withdraw your consent where we only Process your Data based on your
              consent. You can withdraw your consent to receive marketing communications
              from us at any time and for free.
            </td>
            <td>
              To exercise your right to withdraw your consent to receive marketing
              communications please see section 10 above.
            </td>
          </tr>
        </tbody>
      </table>
      <Typography.P1>12. Privacy Notices of other websites</Typography.P1>
      <Typography.P1>
        Our Sites or Apps may contain links to other websites. This Notice only applies to
        our Sites and Apps. If you click on a link to another websites, you should read
        their privacy notices and cookie policies to understand how they Process your
        information.{' '}
      </Typography.P1>
      <Typography.P1>13. Changes to this Privacy Notice</Typography.P1>
      <Typography.P1>
        We may, from time to time, change or update this Privacy Notice in line with legal
        requirements or if our business changes. All changes to this Privacy Notice will
        be published on this page of the Sites. Each change will become effective on
        publication. We recommend that you revisit and read this Privacy Notice regularly
        to ensure that you are up to date with the current terms.
      </Typography.P1>
      <Typography.P1>
        This Notice was last reviewed and updated in January 2023.
      </Typography.P1>
      <Typography.P1>14. Questions?</Typography.P1>
      <Typography.P1>
        If you have any questions or comments about this Notice, want to know more about
        how we use your Data, or want more information on your rights, please contact our
        Data Protection representative by emailing: {HELP_EMAIL}
      </Typography.P1>
      <Typography.P1>15. Complaints</Typography.P1>
      <Typography.P1>
        If you have a complaint about how we Process your Data, please contact us at info@
        {HELP_EMAIL} and will try to resolve this. However, if you feel that we haven’t
        addressed your concern in a satisfactory manner, you have the right to complain to
        the Gibraltar Regulatory Authority (“GRA”) (www.gra.gi).
      </Typography.P1>
      <Typography.P1>16. Glossary</Typography.P1>
      <table>
        <thead>
          <tr>
            <td>Term </td>
            <td>What this means</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Data</td>
            <td>
              Information relating to an identifiable person, who can be directly or
              indirectly identified in particular by reference to an identifier, or which
              is otherwise defined as ‘Personal Data’ under Data Protection Legislation.
            </td>
          </tr>
          <tr>
            <td>Data Protection Legislation</td>
            <td>
              Data Protection Act 2018 (UK GDPR), EU General Data Protection Regulation
              2018 (GDPR), the Privacy and Electronic Communications (EC Directive)
              Regulations 2003, and any other applicable laws relating to the protection
              of Data.{' '}
            </td>
          </tr>
          <tr>
            <td>Process, Processing or Processed</td>
            <td>
              Accessing, collecting, obtaining, recording, holding, disclosing, using,
              altering, deleting, erasing or destroying Data, or carrying out any
              operation(s) on the Data or as otherwise defined under applicable Data
              Protection Legislation.
            </td>
          </tr>
        </tbody>
      </table>
    </Scrollbar>
  </div>
);

export default Gdpr;
