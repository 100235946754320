import { useEffect } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { HeaderPlate } from 'src/ui-kit';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectPriceList } from 'src/store/slices/priceSlice';
import { logError } from 'src/utils';
import { selectIsMainView } from 'src/store/slices/activeView';
import { HELP_EMAIL, MAIN_VIEW_ROUTE } from 'src/constants';
import {
  selectIsDarkTheme,
  setMyEnvironmentModalScreen,
  switchIsDarkTheme,
  switchMyEnvironmentModalIsOpen,
  switchNotepadModalIsOpen,
  switchUserProfileModalIsOpen,
} from 'src/store/slices/uiSlice';
import { selectIsLoggedIn, selectUser } from 'src/store/slices/userSlice';
import { ModalScreen } from '../ModalMyEnvironment/ModalMyEnvironment';
import {
  fetchAllAccounts,
  fetchAllCustomViews,
  fetchExchangesMeta,
  fetchStablePrices,
  fetchUserInfo,
} from 'src/thunks';
import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useAppDispatch();

  const isMainView = useAppSelector(selectIsMainView);
  const exchangeRates = useAppSelector(selectPriceList);
  const user = useAppSelector(selectUser);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    fetchStablePrices(dispatch);

    const interval = setInterval(
      () => fetchStablePrices(dispatch),
      Number(process.env.REACT_APP_BALANCE_REFRESH_RATE)
    );

    const getInitialData = async () => {
      try {
        await fetchUserInfo(dispatch);
        await fetchAllAccounts(
          dispatch,
          window.location.pathname === '/' ||
            window.location.pathname === `/${MAIN_VIEW_ROUTE}`,
          true
        );
        await fetchAllCustomViews(dispatch);
        await fetchExchangesMeta(dispatch);
      } catch (err) {
        logError(err);
      }
    };

    getInitialData();

    return () => clearInterval(interval);
  }, []);

  // const sendEmail = () => {
  //   window.location.href = `mailto:${HELP_EMAIL}`;
  // };

  const openModal = () => {
    dispatch(
      setMyEnvironmentModalScreen(
        isMainView ? ModalScreen.Accounts : ModalScreen.CustomViews
      )
    );

    dispatch(switchMyEnvironmentModalIsOpen());
  };

  const openUserProfileModal = () => {
    dispatch(switchUserProfileModalIsOpen());
  };

  const openNotepadModal = () => {
    dispatch(switchNotepadModalIsOpen());
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <HeaderPlate
      exchangeRates={exchangeRates}
      firstName={user.firstName}
      lastName={user.lastName}
      userPicture={user.userPicture}
      isDarkTheme={isDarkTheme}
      switchTheme={() => {
        dispatch(switchIsDarkTheme());
      }}
      openModal={openModal}
      openUserProfileModal={openUserProfileModal}
      openNotepadModal={openNotepadModal}
      className={styles.header}
    />
  );
};

export default Header;
