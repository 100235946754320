import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { ExchangeService } from 'src/services/Exchange.service';
import { setExchangesMeta } from 'src/store/slices/exchangeSlice';
import { ExchangeMeta } from 'src/types/exchange';

export const fetchExchangesMeta = async (dispatch: Dispatch<AnyAction>) => {
  const exchangesMeta: { data: ExchangeMeta[] } = await ExchangeService.getMeta();

  dispatch(setExchangesMeta(exchangesMeta.data));
};
