import React from 'react';
import cl from 'clsx';
import { ButtonPrimary, Typography } from 'src/ui-kit';
import { ReactComponent as DeactivateAccount } from 'src/assets/images/deactivate.svg';
import { ReactComponent as User } from 'src/assets/images/user.svg';
import styles from './ModalSidebar.module.scss';

type ModalSidebarProps = {
  sections: ModalSidebarSection[];
  bottomButton?: {
    onClick: () => void;
  };
  isInvertedBg?: boolean;
};

type ModalSidebarSection = {
  name: string;
  isActive: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

const ModalSidebar = ({ sections, isInvertedBg, bottomButton }: ModalSidebarProps) => {
  return (
    <div className={cl(styles.modalSidebar, isInvertedBg && styles.invertedBackground)}>
      <div>
        {sections.map(({ name, isActive, isDisabled, onClick }) => (
          <ButtonPrimary
            isFullWidth
            text={name}
            onClick={onClick}
            isDisabled={isDisabled}
            className={cl(
              styles.modalSidebarButton,
              !isActive && styles.modalSidebarButtonInactive,
              isDisabled && styles.modalSidebarButtonDisabled
            )}
          />
        ))}
      </div>

      {bottomButton && (
        <button onClick={bottomButton.onClick} className={styles.bottomButton}>
          <User className={styles.user} />
          <Typography.Small className={styles.deactivateText}>
            Deactivate account
          </Typography.Small>
        </button>
      )}
    </div>
  );
};

export default ModalSidebar;
