import React, { useEffect, useState } from 'react';
import cl from 'clsx';
import { ModalScreen } from '../../ModalMyEnvironment';
import { ExchangeService } from 'src/services/Exchange.service';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectExchangesMeta,
  selectExchangesMetaByExchangeType,
} from 'src/store/slices/exchangeSlice';
import {
  ButtonPrimary,
  Dropdown,
  DropdownOption,
  Hint,
  TextInput,
  Typography,
} from 'src/ui-kit';
import { ReactComponent as SuccessIcon } from 'src/assets/images/completeExc.svg';

import styles from './ApiKeysScreen.module.scss';
import { logError } from 'src/utils';
import { selectIsMainView } from 'src/store/slices/activeView';
import { selectActiveAccountId } from 'src/store/slices/accountSlice';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectActiveViewId } from 'src/store/slices/customViewsSlice';
import { selectIsProView, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import {
  fetchAccountBalance,
  fetchAllAccounts,
  fetchCustomViewBalance,
  fetchExchangesMeta,
} from 'src/thunks';

type ApiKeysScreenProps = {
  accountSection: any;
  onClose: () => void;
};

const ApiKeysScreen = ({ accountSection, onClose }: ApiKeysScreenProps) => {
  const [exchangeType, setExchangeType] = useState<string | null>(null);
  const [isFilledForm, setIsFilledForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [metaDropdown, setMetaDropdown] = useState<DropdownOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const isMainScreen = useAppSelector(selectIsMainView);
  const isProView = useAppSelector(selectIsProView);
  const activeViewId = useAppSelector(selectActiveViewId);
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const exchangesMeta = useAppSelector(selectExchangesMeta);
  const exchangeMeta = useAppSelector(selectExchangesMetaByExchangeType(exchangeType));
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [form, setForm] = useState<Record<string, { label: string; value: string }>>({
    userDefinedName: {
      label: 'Custom provider name',
      value: '',
    },
  });

  useEffect(() => {
    fetchExchangesMeta(dispatch);
  }, []);

  useEffect(() => {
    setMetaDropdown(() => {
      return exchangesMeta.map((item) => {
        return {
          label: item.exchangeType,
          value: item.exchangeType,
        };
      });
    });
  }, [exchangesMeta]);

  const resetForm = () => {
    setError('');
    setForm({});
    setIsFilledForm(false);
    setIsSubmitting(false);
    setIsSuccesful(false);
  };

  useEffect(() => {
    resetForm();
    setForm(() => {
      const form = {
        userDefinedName: {
          label: 'Custom provider name',
          value: '',
        },
      };

      if (exchangeMeta) {
        exchangeMeta!.accessParams.forEach((param) => {
          Object.keys(param).forEach((paramKey) => {
            // @ts-ignore
            form[paramKey] = {
              label: param[paramKey],
              value: '',
            };
          });
        });
      }

      return form;
    });
  }, [exchangeType]);

  useEffect(() => {
    setForm(() => {
      const form = {
        userDefinedName: {
          label: 'Custom provider name',
          value: '',
        },
      };

      if (exchangeMeta) {
        exchangeMeta!.accessParams.forEach((param) => {
          Object.keys(param).forEach((paramKey) => {
            // @ts-ignore
            form[paramKey] = {
              label: param[paramKey],
              value: '',
            };
          });
        });
      }

      return form;
    });
  }, [exchangeMeta]);

  useEffect(() => {
    setIsFilledForm(() => {
      return Object.values(form).every((field) => {
        return field.value.length > 0;
      });
    });
  }, [form]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => {
      const newState = { ...prevState };
      if (event.target.name === 'userDefinedName' && event.target.value.length > 12) {
        return prevState;
      }

      newState[event.target.name].value = event.target.value;

      return newState;
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const formCopy = { ...form };
      delete formCopy.userDefinedName;

      const normalizedForm = Object.keys(formCopy).reduce((acc: any, value) => {
        acc[value] = formCopy[value].value;

        return acc;
      }, {});

      const payload = {
        accountId: accountSection.accountId,
        exchangeType,
        description: '',
        userDefinedName: form.userDefinedName.value,
        accessKey: JSON.stringify(normalizedForm),
      };

      // @ts-ignore
      await ExchangeService.createExchange(payload);

      await fetchAllAccounts(dispatch, isMainScreen);

      if (isMainScreen) {
        await fetchAccountBalance(dispatch, activeAccountId, isProView);
      } else {
        await fetchCustomViewBalance(dispatch, activeViewId, isProView);
      }

      setIsSuccesful(true);
    } catch (error: any) {
      logError(error);
      if (error.response) {
        setError(error.response.data.error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.apiKeysScreen}>
      {!isSuccesful && (
        <>
          <div className={styles.topBar}>
            <div>
              <Typography.P1 color="purple" className={styles.accountName}>
                {accountSection.accountName}
              </Typography.P1>
              <Typography.P1 color="secondary" className={styles.accountLabel}>
                Account
              </Typography.P1>
            </div>
            {exchangeType && (
              <div>
                <Typography.P1 color="purple" className={styles.accountName}>
                  {exchangeType}
                </Typography.P1>
                <Typography.P1 color="secondary" className={styles.accountLabel}>
                  Provider
                </Typography.P1>
              </div>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.contentTop}>
              <div className={styles.chooseExchangeWrapper}>
                <Typography.H3
                  isUppercase
                  className={styles.chooseExchangeWrapperHeading}
                >
                  Choose provider
                </Typography.H3>
                <Dropdown
                  label="Select provider"
                  options={metaDropdown}
                  onChange={(item) => {
                    setSelectedOption(item.value);
                    setExchangeType(item.value);
                  }}
                />
              </div>
              {exchangeMeta && (
                <div className={styles.contentHeadingWrapper}>
                  <Typography.P1 className={styles.contentHeading}>
                    {exchangeType === 'CUSTOM'
                      ? 'This account will contain custom positions'
                      : 'Fill in read-only exchange key(s)'}
                  </Typography.P1>
                  {exchangeType !== 'CUSTOM' && (
                    <Hint
                      width={288}
                      hint={
                        <>
                          Your read-only API keys can be located on the corresponding
                          exchange. Insert them below to connect to that exchange. For
                          help contact <a href="mailto:help@exopro.io">help@exopro.io</a>.
                        </>
                      }
                    />
                  )}
                </div>
              )}
              {exchangeMeta && (
                <form
                  className={cl(
                    styles.formWrapper,
                    exchangeMeta.accessParams.length === 3 && styles.wideFormWrapper
                  )}
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  {exchangeMeta.accessParams.length === 3 ? (
                    <div className={styles.okxWrapper}>
                      <>
                        {Object.keys(form).map((paramKey, index) => {
                          return (
                            <div
                              className={styles.inputWrapper}
                              key={`${paramKey}-${index}`}
                            >
                              <div className={styles.inputLabel}>
                                {form[paramKey].label}
                              </div>
                              <TextInput
                                name={paramKey}
                                onChange={handleInputChange}
                                value={form[paramKey].value}
                                isPassword={paramKey !== 'userDefinedName'}
                                placeholder={
                                  paramKey === 'userDefinedName'
                                    ? `${accountSection.accountName}_${exchangeType}`
                                    : undefined
                                }
                                inputClassName={styles.input}
                                autoComplete="off"
                                autoFocus={index === 0}
                              />
                            </div>
                          );
                        })}
                      </>
                    </div>
                  ) : (
                    <>
                      {Object.keys(form).map((paramKey, index) => {
                        return (
                          <div
                            className={styles.inputWrapper}
                            key={`${paramKey}-${index}`}
                          >
                            <div className={styles.inputLabel}>
                              {form[paramKey].label}
                            </div>
                            <TextInput
                              name={paramKey}
                              onChange={handleInputChange}
                              value={form[paramKey].value}
                              isPassword={paramKey !== 'userDefinedName'}
                              placeholder={
                                paramKey === 'userDefinedName'
                                  ? `${accountSection.accountName}_${exchangeType}`
                                  : undefined
                              }
                              inputClassName={styles.input}
                              autoComplete="off"
                              autoFocus={index === 0}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </form>
              )}
            </div>
            <div>
              {error && (
                <div
                  className={cl(
                    styles.error,
                    selectedOption === 'OKX' && styles.okxError
                  )}
                >
                  <Typography.Additional>{error}</Typography.Additional>
                </div>
              )}
              <div className={styles.buttonsWrapper}>
                <ButtonPrimary
                  text="Submit"
                  isDisabled={!isFilledForm || isSubmitting}
                  onClick={handleSubmit}
                  className={styles.createButton}
                ></ButtonPrimary>
                <div className={styles.cancelButtonsWrapper}>
                  <button
                    onClick={() =>
                      dispatch(setMyEnvironmentModalScreen(ModalScreen.Exchanges))
                    }
                    className={styles.cancelButton}
                  >
                    Continue later
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isSuccesful && (
        <div className={styles.successWrapper}>
          <div className={styles.successTopWrapper}>
            <SuccessIcon className={styles.successIcon} />
            <Typography.P1 className={styles.successNote}>
              Setup finished successfully
            </Typography.P1>
          </div>
          <div className={styles.buttonsWrapper}>
            <ButtonPrimary
              text="Finish setup"
              onClick={onClose}
              className={styles.createButton}
            ></ButtonPrimary>
            <div className={styles.cancelButtonsWrapper}>
              <button
                onClick={() => {
                  resetForm();
                  setExchangeType(null);
                }}
                className={styles.cancelButton}
              >
                Add provider
              </button>
              <button
                onClick={() =>
                  dispatch(setMyEnvironmentModalScreen(ModalScreen.NewAccount))
                }
                className={styles.cancelButton}
              >
                Add new account
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeysScreen;
