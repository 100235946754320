import { Typography } from 'src/ui-kit';

import styles from './ApiKeysScreenHeader.module.scss';

type ApiKeysScreenHeaderProps = {};

const ApiKeysScreenHeader = ({}: ApiKeysScreenHeaderProps) => {
  return (
    <div className={styles.newAccountScreenHeader}>
      <div className={styles.setupWrapper}>
        <Typography.H1 className={styles.setup}>
          Let's set up your environment
        </Typography.H1>
      </div>
      <div className={styles.stepWrapper}>
        <Typography.P1 className={styles.step1}>step 2</Typography.P1>
        <Typography.P1 className={styles.accountCreation}>provider</Typography.P1>
      </div>
    </div>
  );
};

export default ApiKeysScreenHeader;
