import { Route, Routes, useLocation } from 'react-router-dom';
import { IndexRoute } from 'src/components/IndexRoute';
import { Dashboard } from 'src/components/Dashboard';
import { Authorization } from 'src/components/Authorization';

export const AppRouter = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/" element={<IndexRoute />} />
      <Route path="/sign-in" element={<Authorization />} />
      <Route path="/join-us" element={<Authorization />} />
      <Route path="/update_password" element={<Authorization />} />
      <Route path="/forgot_password" element={<Authorization />} />
      <Route path=":viewId" element={<Dashboard />} />
    </Routes>
  );
};
