import React from 'react';
import { Typography } from 'src/ui-kit';

import styles from './EmptyMyEnvironmentScreen.module.scss';

type EmptyMyEnvironmentScreenProps = {
  line1: string;
  line2: string;
};

const EmptyMyEnvironmentScreen = ({ line1, line2 }: EmptyMyEnvironmentScreenProps) => {
  return (
    <div className={styles.emptyMyEnvironmentScreen}>
      <Typography.Additional className={styles.text}>
        {line1}
        <br />
        {line2}
      </Typography.Additional>
    </div>
  );
};

export default EmptyMyEnvironmentScreen;
