import React, { useEffect, useState } from 'react';
import cl from 'clsx';
import styles from './AccountInfo.module.scss';
import { Avatar, ButtonPrimary, ButtonSecondary, Typography } from 'src/ui-kit';
import { ReactComponent as LeaveIcon } from 'src/assets/images/leave2.svg';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectUser, setUser } from 'src/store/slices/userSlice';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';
import { UserService } from 'src/services/User.service';
import { logError } from 'src/utils';
import { useDispatch } from 'react-redux';

const AccountInfo = ({ isOpenAvatar, setIsOpenAvatar }: any) => {
  const [isActiveAvatar, setIsActiveAvatar] = useState<0 | 1 | 2 | 3 | 4>(0);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsActiveAvatar(user.userPicture);
  }, [user]);

  const logout = async () => {
    try {
      await UserService.signout();
      localStorage.removeItem('access_token');
      window.location.href = '/';
    } catch (error) {
      logError(error);
    }
  };

  const reset = () => {
    setIsActiveAvatar(user.userPicture);
  };

  return (
    <>
      <div className={styles.accountInfo}>
        <div className={styles.accountInfoLeftSide}>
          <button
            onClick={() => {
              setIsOpenAvatar(!isOpenAvatar);
            }}
            className={styles.avatar}
          >
            <Avatar
              variant={user.userPicture}
              size="large"
              firstName={user.firstName}
              lastName={user.lastName}
            />
          </button>

          <div className={styles.accountDetails}>
            <div className={styles.accountDetailsTop}>
              <Typography.H3 color="dark" weight={500} className={styles.email}>
                {user.email}
              </Typography.H3>
              <div className={styles.status}>
                <Typography.Additional
                  weight={500}
                  color="purple"
                  className={styles.statusPro}
                >
                  Pro
                </Typography.Additional>
              </div>
              {/* <div className={styles.statusUpgradeBtn}>
                <Typography.Additional weight={400} className={styles.statusUpgrade}>
                  Upgrade
                </Typography.Additional>
              </div> */}
            </div>
            <div>
              <Typography.P1>{`${user.firstName} ${user.lastName}`}</Typography.P1>
            </div>
          </div>
        </div>
        <div className={styles.accountInfoRightSide}>
          <ButtonSecondary type="large" onClick={logout}>
            <Typography.P1>Sign out</Typography.P1>
            <LeaveIcon className={cl(!isDarkTheme && styles.darkLeaveIcon)} />
          </ButtonSecondary>
        </div>
        {isOpenAvatar && (
          <div className={styles.chooseAvatar}>
            <Typography.H3 isUppercase weight={600}>
              choose avatar
            </Typography.H3>
            <div className={styles.avatars}>
              <button
                onClick={() => {
                  setIsActiveAvatar(0);
                }}
              >
                <Avatar
                  variant={0}
                  size="large"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  isCurrentAvatar={isActiveAvatar === 0}
                />
              </button>
              <button
                onClick={() => {
                  setIsActiveAvatar(1);
                }}
              >
                <Avatar
                  variant={1}
                  size="large"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  isCurrentAvatar={isActiveAvatar === 1}
                />
              </button>

              <button
                onClick={() => {
                  setIsActiveAvatar(2);
                }}
              >
                <Avatar
                  variant={2}
                  size="large"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  isCurrentAvatar={isActiveAvatar === 2}
                />
              </button>

              <button
                onClick={() => {
                  setIsActiveAvatar(3);
                }}
              >
                <Avatar
                  variant={3}
                  size="large"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  isCurrentAvatar={isActiveAvatar === 3}
                />
              </button>

              <button
                onClick={() => {
                  setIsActiveAvatar(4);
                }}
              >
                <Avatar
                  variant={4}
                  size="large"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  isCurrentAvatar={isActiveAvatar === 4}
                />
              </button>
            </div>
            <div className={styles.buttons}>
              <ButtonSecondary
                text="Cancel"
                type="small"
                onClick={() => {
                  setIsActiveAvatar(user.userPicture);
                  setIsOpenAvatar(false);
                }}
                className={styles.actionButton}
              />
              <ButtonPrimary
                text="Save"
                size="small"
                onClick={async () => {
                  try {
                    await UserService.userUpdate({
                      firstName: user.firstName,
                      lastName: user.lastName,
                      companyName: user.companyName,
                      userPicture: isActiveAvatar,
                      userTheme: user.userTheme,
                      userTimezone: user.userTimezone,
                    });

                    dispatch(
                      setUser({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        companyName: user.companyName,
                        email: user.email,
                        userPicture: isActiveAvatar,
                        userTheme: user.userTheme,
                        userTimezone: user.userTimezone,
                      })
                    );

                    setIsOpenAvatar(false);
                  } catch (error) {
                    logError(error);
                  }
                }}
                className={styles.actionButton}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountInfo;
