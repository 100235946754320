import React from 'react';
import cl from 'clsx';
import { Typography } from '../Typography';

import { ReactComponent as MainViewIcon } from 'src/assets/images/mainViewNotActive.svg';
import { ReactComponent as CustomViewIcon } from 'src/assets/images/custom-view.svg';

import styles from './SidebarButton.module.scss';

export type SidebarButtonProps = {
  isMainView?: boolean;
  text: string;
  isActive: boolean;
  isCollapsed: boolean;
  index?: number;
  onClick: () => void;
  className?: string;
};

const SidebarButton = ({
  text,
  isActive,
  isMainView,
  isCollapsed,
  index,
  onClick,
  className,
}: SidebarButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cl(
        styles.sidebarButton,
        isActive && styles.isActive,
        isMainView && styles.isMainView,
        !isMainView && styles.isNotMainView,
        isCollapsed && styles.isCollapsed,
        className && className
      )}
    >
      {isMainView && (
        <MainViewIcon width={24} height={24} className={styles.mainViewIcon} />
      )}
      {!isMainView && isCollapsed && (
        <CustomViewIcon width={24} height={24} className={styles.customViewIcon} />
      )}
      {!isMainView && isCollapsed && (
        <Typography.H3 as="span" className={cl(styles.text, styles.altText)}>
          {index}
        </Typography.H3>
      )}
      {(isMainView || (!isMainView && !isCollapsed)) && (
        <Typography.H3 as="span" className={styles.text}>
          {text}
        </Typography.H3>
      )}
    </button>
  );
};

export default SidebarButton;
