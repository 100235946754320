import React from 'react';
import cl from 'clsx';
import { ButtonPrimary, Typography } from 'src/ui-kit';
import { ReactComponent as GreenTickIcon } from 'src/assets/images/green_tick.svg';
import styles from './UpdatePasswordSuccess.module.scss';

const UpdatePasswordSuccess = ({ goBack }: { goBack: () => void }) => (
  <div className={cl(styles.signInForm, styles.confirmationLink)}>
    <>
      <GreenTickIcon />
      <div className={styles.confirmationWrapper}>
        <div>
          <Typography.H1
            isUppercase
            weight={700}
            align="center"
            className={styles.linkHeading}
          >
            Success
          </Typography.H1>
        </div>
        <div>
          <ButtonPrimary text="Login" isFullWidth onClick={goBack}></ButtonPrimary>
        </div>
      </div>
    </>
  </div>
);

export default UpdatePasswordSuccess;
