import React, { useEffect, useState } from 'react';
import cl from 'clsx';
import {
  ButtonPrimary,
  Checkbox,
  EmailConfirmationError,
  EmailConfirmed,
  Gdpr,
  LoadingIndicator,
  Scrollbar,
  TermsAndConditions,
  TextInput,
  Typography,
} from 'src/ui-kit';

import UpdatePasswordSuccess from 'src/ui-kit/UpdatePasswordSuccess/UpdatePasswordSuccess';

import styles from './Authorization.module.scss';
import { UserService } from 'src/services/User.service';

import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';
import { ReactComponent as GreenTickIcon } from 'src/assets/images/green_tick.svg';
import { isNumber } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import usePrevious from 'src/hooks/usePrevious';
import { useDidMount } from 'src/hooks/useDidMount';
import { logError } from 'src/utils';
import { logout } from 'src/store/slices/userSlice';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_TITLE,
  JOIN_US_ROUTE,
  PAGES_CONFIG,
  SIGN_IN_ROUTE,
} from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as LogoIcon } from 'src/assets/images/whiteLogo.svg';
import { ReactComponent as ArrowDownRight } from 'src/assets/images/arrowDownRight.svg';
import { ReactComponent as OreomeLogo} from 'src/assets/images/logo-oreome.svg';
import { ReactComponent as OreomePattern} from 'src/assets/images/pattern-oreome.svg';


enum AuthScreen {
  Signin = 'signin',
  Signup = 'signup',
  ForgotPassword = 'forgot_password',
  ConfirmationLink = 'confirmation_link',
  TermsAndConditions = 'terms_and_conditions',
  Gdpr = 'gdpr',
  EmailWaiting = 'email_waiting',
  EmailConfirmed = 'email_confirmed',
  EmailConfirmationError = 'email_confimation_error',
  ConfirmationLinkPassword = 'confirmation_link_password',
  UpdatePassword = 'update_password',
  UpdatePasswordSuccess = 'update_password_success',
}

const Authorization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState(DEFAULT_PAGE_TITLE);
  const [pageDescription, setPageDescription] = useState(DEFAULT_PAGE_DESCRIPTION);
  const [authScreen, setAuthScreen] = useState(() => {
    if (window.location.pathname.includes('email_verified')) {
      return AuthScreen.EmailWaiting;
    } else if (window.location.pathname.includes('update_password')) {
      return AuthScreen.UpdatePassword;
    } else if (window.location.pathname === JOIN_US_ROUTE) {
      return AuthScreen.Signup;
    } else {
      return AuthScreen.Signin;
    }
  });
  const prevAuthScreen = usePrevious(authScreen);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [updatePasswordToken, setUpdatePasswordToken] = useState(() => {
    let token = '';

    if (window.location.pathname.includes('update_password')) {
      token = window.location.search.split('=')[1];
    }

    return token;
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [signUpEmail, setUpSignUpEmail] = useState('');
  const [signUpPassword, setUpSignUpPassword] = useState('');
  const [signUpPassword2, setUpSignUpPassword2] = useState('');
  const [signInError, setSignInError] = useState<
    null | 'wrong_credentials' | 'not_verified'
  >(null);
  const [signUpError, setSignUpError] = useState<null | 0 | 1 | 2>(null);
  const [gdpr, setGdpr] = useState(false);
  const [tac, setTac] = useState(false);

  const [captchaToken, setCaptchaToken] = useState<null | string>(null);
  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const [hasSentSendChangePasswordEmail, setHasSentSendChangePasswordEmail] =
    useState(false);
  const [errorSentSendChangePasswordEmail, setErrorSentSendChangePasswordEmail] =
    useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updatePasswordError, setUpdatePasswordError] = useState('');

  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setSignInError(null);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setSignInError(null);
  };

  const handleSubmit = async () => {
    try {
      const result = await UserService.signin({
        email,
        password,
      });

      localStorage.setItem('access_token', result.data.accessToken);
      window.location.href = '/';
    } catch (error: any) {
      if (error?.response?.data?.key === 'EMAIL_VERIFIED_ERROR') {
        setSignInError('not_verified');
        setToken(error?.response?.data?.message);
      } else {
        setSignInError('wrong_credentials');
      }

      logError(error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await UserService.sendChangePasswordEmail(passwordResetEmail);
      setHasSentSendChangePasswordEmail(true);
    } catch (error: any) {
      setErrorSentSendChangePasswordEmail('Incorrect credentials');

      logError(error);
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setUpdatePasswordError('Passwords are not the same. Try again');

      return;
    }

    try {
      await UserService.passwordChangeByToken({
        changePasswordActionToken: updatePasswordToken,
        newPassword: newPassword,
      });
      setAuthScreen(AuthScreen.UpdatePasswordSuccess);
    } catch (error: any) {
      setUpdatePasswordError(error.response.data.error);

      logError(error);
    }
  };

  const recaptchaRef: any = React.createRef();

  useEffect(() => {
    dispatch(logout(false));
  }, []);

  useEffect(() => {
    if (authScreen === AuthScreen.Signin) {
      setPageTitle(PAGES_CONFIG.signIn.title);
      setPageDescription(PAGES_CONFIG.signIn.description);
      navigate(SIGN_IN_ROUTE);
    } else {
      setPageTitle(DEFAULT_PAGE_TITLE);
      setPageDescription(DEFAULT_PAGE_DESCRIPTION);
    }
  }, [authScreen]);

  useEffect(() => {
    if (
      (prevAuthScreen === AuthScreen.Signin && authScreen === AuthScreen.Signup) ||
      (prevAuthScreen === AuthScreen.Signup && authScreen === AuthScreen.Signin)
    ) {
      setEmail('');
      setPassword('');
      setSignInError(null);
      setFirstName('');
      setLastName('');
      setCompanyName('');
      setUpSignUpEmail('');
      setUpSignUpPassword('');
      setUpSignUpPassword2('');
      setGdpr(false);
      setTac(false);
      setCaptchaToken(null);
    }

    if (authScreen === AuthScreen.EmailWaiting) {
      const verifyEmail = async () => {
        try {
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);
          const emailVerifyToken = params.get('emailVerifyToken')?.replace(/ /g, '+');

          if (emailVerifyToken) {
            await UserService.emailVerify(emailVerifyToken);
            setAuthScreen(AuthScreen.EmailConfirmed);
          } else {
            throw Error();
          }
        } catch {
          setAuthScreen(AuthScreen.EmailConfirmationError);
        }
      };

      verifyEmail();
    }
  }, [prevAuthScreen, authScreen]);

  useDidMount(() => {
    setPasswordResetEmail('');
    setErrorSentSendChangePasswordEmail('');
    setHasSentSendChangePasswordEmail(false);
    setUpdatePasswordToken('');
    setNewPassword('');
    setConfirmPassword('');
  }, [authScreen]);

  useEffect(() => {
    if (hasSentSendChangePasswordEmail) {
      setAuthScreen(AuthScreen.ConfirmationLinkPassword);
    }
  }, [hasSentSendChangePasswordEmail]);

  const goToSignInScreen = () => {
    setAuthScreen(AuthScreen.Signin);
  };

  const goToSignUpScreen = () => {
    setAuthScreen(AuthScreen.Signup);
  };

  return (
    <div className={styles.authorization}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <div className={styles.lSide}>
        <div>
          <OreomeLogo className={styles.logo} />
          <div className={styles.slogan}>
          <div className={styles.title}>
          <b>Expert solutions </b><br />
          for crypto derivatives
          </div>
          <div className={styles.description}>Top-tier data, analytics and risk management solutions for professional clients aiming to gain a competitive edge in the crypto derivatives market. </div>
        </div>
        </div>
        
         
          
        <OreomePattern />
    </div>
      <div className={styles.rSide}>
        {authScreen === AuthScreen.Signin && (
          <div
            className={cl(styles.signInForm, signInError && styles.signInFormWithError)}
          >
            <>
              <div>
                <Typography.H1
                  color="black"
                  isUppercase
                  weight={700}
                  className={styles.signInFormTitle}
                >
                  Sign in to oreome
                </Typography.H1>
              </div>
              <form
                onSubmit={(event: any) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={styles.controlsWrapper}>
                  <div className={styles.inputsGroup}>
                  <div className={styles.inputWrapper}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Email
                    </Typography.P1>
                    <TextInput
                      value={email}
                      placeholder="Enter your email"
                      onChange={handleEmailChange}
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Password
                    </Typography.P1>
                    <TextInput
                      value={password}
                      placeholder="Enter your password"
                      isPassword
                      onChange={handlePasswordChange}
                      className={cl(styles.input, styles.signInPassword)}
                    />
                  </div>
                  
                  </div>
                  {signInError === 'wrong_credentials' && (
                    <div className={styles.signInError}>
                      <Typography.Additional>
                        Incorrect email or password. Please check and try again.
                      </Typography.Additional>
                    </div>
                  )}
                  {signInError === 'not_verified' && (
                    <div className={styles.signInError}>
                      <Typography.Additional>
                        Your email is not verified. Please{' '}
                        <button
                          onClick={async () => {
                            await UserService.resendEmailVerify(token);
                            setAuthScreen(AuthScreen.ConfirmationLink);
                          }}
                        >
                          click here
                        </button>{' '}
                        to send an email again
                      </Typography.Additional>
                    </div>
                  )}

                  <ButtonPrimary
                    type="submit"
                    text="Sign In"
                    isFullWidth
                    onClick={handleSubmit}
                    isDisabled={email.length === 0 || password.length === 0}
                    className={styles.buttonSubmit}
                  ></ButtonPrimary>
                </div>
              </form>
            </>
          </div>
        )}

        {authScreen === AuthScreen.Signup && (
          <div
            className={cl(
              styles.signInForm,
              authScreen === AuthScreen.Signup && styles.signUpForm
            )}
          >
            <Scrollbar
              width="100%"
              height="calc(100vh - 54px)"
              trackYRight={4}
              trackYTop={40}
              trackYHeight="calc(100% - 8px)"
              isDarkTheme={isDarkTheme}
            >
              <div className={styles.signUpFormInner}>
                <div>
                  <Typography.H1
                    color="black"
                    isUppercase
                    weight={700}
                    className={styles.signInFormTitle}
                  >
                    Create an environment
                  </Typography.H1>
                  <div
                    className={cl(styles.signInFormSubtitle, styles.signUpFormSubtitle)}
                  >
                    <Typography.H2
                      color="black"
                      className={styles.signInFormSubtitleText}
                    >
                      Already have an environment?
                    </Typography.H2>
                    <button
                      onClick={() => {
                        setAuthScreen(AuthScreen.Signin);
                      }}
                    >
                      <Typography.H2
                        color="purple"
                        weight={600}
                        className={styles.signUp}
                      >
                        Sign in
                      </Typography.H2>
                    </button>
                  </div>
                  <div className={styles.nameInputs}>
                    <div className={styles.inputWrapper}>
                      <Typography.P1 className={styles.inputLabel} weight={500}>
                        First name
                      </Typography.P1>
                      <TextInput
                        value={firstName}
                        placeholder="Enter your name"
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                        className={styles.input}
                      />
                    </div>
                    <div className={styles.inputWrapper}>
                      <Typography.P1 className={styles.inputLabel} weight={500}>
                        Last name
                      </Typography.P1>
                      <TextInput
                        value={lastName}
                        placeholder="Enter your last name"
                        onChange={(event) => {
                          setLastName(event.target.value);
                        }}
                        className={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.inputWrapper}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Company name (optional)
                    </Typography.P1>
                    <TextInput
                      value={companyName}
                      placeholder="Enter your company name"
                      onChange={(event) => {
                        setCompanyName(event.target.value);
                      }}
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Email
                    </Typography.P1>
                    <TextInput
                      value={signUpEmail}
                      placeholder="Enter your email"
                      onChange={(event) => {
                        setUpSignUpEmail(event.target.value);
                        if (
                          !event.target.value.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          )
                        ) {
                          setSignUpError(1);
                        } else {
                          setSignUpError(null);
                        }
                      }}
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Create your password
                    </Typography.P1>
                    <TextInput
                      value={signUpPassword}
                      placeholder="Enter your password"
                      isPassword
                      onChange={(event) => {
                        setUpSignUpPassword(event.target.value);

                        if (event.target.value !== signUpPassword2) {
                          setSignUpError(0);
                        } else {
                          setSignUpError(null);
                        }
                      }}
                      className={styles.input}
                    />
                  </div>
                  <div className={cl(styles.inputWrapper, styles.passwordWrapper)}>
                    <Typography.P1 className={styles.inputLabel} weight={500}>
                      Confirm your password
                    </Typography.P1>
                    <TextInput
                      value={signUpPassword2}
                      placeholder="Duplicate your password"
                      isPassword
                      onChange={(event) => {
                        setUpSignUpPassword2(event.target.value);

                        if (event.target.value !== signUpPassword) {
                          setSignUpError(0);
                        } else {
                          setSignUpError(null);
                        }
                      }}
                      className={styles.input}
                    />
                  </div>
                  {isNumber(signUpError) && (
                    <div className={styles.signInError}>
                      {signUpError === 0 && (
                        <Typography.Additional>
                          Passwords don't match
                        </Typography.Additional>
                      )}
                      {signUpError === 1 && (
                        <Typography.Additional>
                          Incorrect email adress. Please enter adress in format{' '}
                          <b>text@text.text</b>
                        </Typography.Additional>
                      )}
                      {signUpError === 2 && (
                        <Typography.Additional>
                          Authentication error
                        </Typography.Additional>
                      )}
                    </div>
                  )}
                  <div className={styles.checkboxesWrapper}>
                    <Checkbox
                      name="gdpr"
                      label={
                        <Typography.P1 as="span" className={styles.labelText}>
                          I agree to{' '}
                          <button
                            onClick={() => {
                              setAuthScreen(AuthScreen.Gdpr);
                            }}
                          >
                            Privacy notice
                          </button>
                        </Typography.P1>
                      }
                      checked={gdpr}
                      onChange={() => {
                        setGdpr(!gdpr);
                      }}
                    />
                    <Checkbox
                      name="tac"
                      label={
                        <Typography.P1 as="span" className={styles.labelText}>
                          I agree to{' '}
                          <button
                            onClick={() => {
                              setAuthScreen(AuthScreen.TermsAndConditions);
                            }}
                          >
                            Terms and conditions
                          </button>
                        </Typography.P1>
                      }
                      checked={tac}
                      onChange={() => {
                        setTac(!tac);
                      }}
                    />
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6Lfnfv4mAAAAAKKnwf3NjxT3aUZklR3EYrUf5Qzj"
                    style={{ marginBottom: 32 }}
                    onChange={(token) => {
                      setCaptchaToken(token);
                    }}
                  />
                  <ButtonPrimary
                    text="Create an environment"
                    isFullWidth
                    isDisabled={
                      firstName.length === 0 ||
                      lastName.length === 0 ||
                      signUpEmail.length === 0 ||
                      signUpPassword.length === 0 ||
                      signUpPassword2.length === 0 ||
                      signUpPassword !== signUpPassword2 ||
                      !gdpr ||
                      !tac ||
                      // signUpError !== null ||
                      captchaToken === null ||
                      isLoadingSignUp
                    }
                    onClick={async () => {
                      setIsLoadingSignUp(true);
                      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                      try {
                        await UserService.signup({
                          firstName: firstName,
                          lastName,
                          companyName,
                          email: signUpEmail,
                          password: signUpPassword,
                          userPicture: 0,
                          userTheme: 2,
                          userTimezone: `${timezone}|${timezone}`,
                        });

                        setAuthScreen(AuthScreen.ConfirmationLink);
                      } catch (error) {
                        setSignUpError(2);
                        logError(error);
                      }
                      setIsLoadingSignUp(false);
                    }}
                    className={styles.buttonSubmit}
                  ></ButtonPrimary>
                </div>
              </div>
            </Scrollbar>
          </div>
        )}

        {authScreen === AuthScreen.ForgotPassword && (
          <div className={cl(styles.signInForm, styles.forgotPasswordWrapper)}>
            <>
              <div>
                <Typography.H1
                  color="black"
                  isUppercase
                  weight={700}
                  align="center"
                  className={styles.signInFormTitle}
                >
                  Forgot password
                </Typography.H1>
                <div className={styles.signInFormSubtitle}>
                  <Typography.H3
                    color="black"
                    align="center"
                    className={styles.signInFormSubtitleText}
                  >
                    Enter your email or username and we will send you instructions on how
                    to create a new password.
                  </Typography.H3>
                </div>
              </div>
              <div className={styles.controlsWrapper}>
                <div
                  className={cl(
                    styles.inputWrapper,
                    errorSentSendChangePasswordEmail && styles.inputWrapperError
                  )}
                >
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Email
                  </Typography.P1>
                  <TextInput
                    value={passwordResetEmail}
                    placeholder="Enter your email"
                    onChange={(event) => {
                      setPasswordResetEmail(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                {errorSentSendChangePasswordEmail && (
                  <div className={styles.signInError}>
                    <Typography.Additional>
                      {errorSentSendChangePasswordEmail}
                    </Typography.Additional>
                  </div>
                )}
                <ButtonPrimary
                  text="Submit"
                  isFullWidth
                  onClick={handlePasswordReset}
                  isDisabled={passwordResetEmail.length === 0}
                  className={styles.buttonSubmit}
                ></ButtonPrimary>
                <button
                  className={styles.backToLogin}
                  onClick={() => {
                    setAuthScreen(AuthScreen.Signin);
                  }}
                >
                  Back to login
                </button>
              </div>
            </>
          </div>
        )}

        {authScreen === AuthScreen.UpdatePassword && (
          <div className={cl(styles.signInForm, styles.updatePasswordWrapper)}>
            <>
              <div>
                <Typography.H1
                  color="black"
                  isUppercase
                  weight={700}
                  align="center"
                  className={styles.signInFormTitle}
                >
                  Update password
                </Typography.H1>
                <div className={styles.signInFormSubtitle}>
                  <Typography.H3
                    color="black"
                    align="center"
                    className={styles.signInFormSubtitleText}
                  >
                    Change your password to activate your account
                  </Typography.H3>
                </div>
              </div>
              <div className={styles.controlsWrapper}>
                <div className={cl(styles.inputWrapper)}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    New password
                  </Typography.P1>
                  <TextInput
                    value={newPassword}
                    placeholder="Enter new password"
                    isPassword
                    onChange={(event) => {
                      setNewPassword(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                <div
                  className={cl(
                    styles.inputWrapper,
                    updatePasswordError && styles.inputWrapperError
                  )}
                >
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Confirm password
                  </Typography.P1>
                  <TextInput
                    value={confirmPassword}
                    placeholder="Confirm new password"
                    isPassword
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                {updatePasswordError && (
                  <div className={styles.signInError}>
                    <Typography.Additional>{updatePasswordError}</Typography.Additional>
                  </div>
                )}
                <ButtonPrimary
                  text="Confirm"
                  isFullWidth
                  onClick={handleUpdatePassword}
                  isDisabled={newPassword.length === 0 || confirmPassword.length === 0}
                  className={styles.buttonSubmit}
                ></ButtonPrimary>
              </div>
            </>
          </div>
        )}

        {authScreen === AuthScreen.UpdatePasswordSuccess && (
          <UpdatePasswordSuccess goBack={goToSignInScreen} />
        )}

        {(authScreen === AuthScreen.ConfirmationLink ||
          authScreen === AuthScreen.ConfirmationLinkPassword) && (
          <div className={cl(styles.signInForm, styles.confirmationLink)}>
            <>
              <GreenTickIcon />
              <div className={styles.confirmationWrapper}>
                <div>
                  <Typography.H1
                    isUppercase
                    weight={700}
                    align="center"
                    className={styles.linkHeading}
                  >
                    we sent a link
                  </Typography.H1>
                  <Typography.H3 weight={500} align="center">
                    Check your mailbox and follow the instructions
                  </Typography.H3>
                </div>
                <div>
                  <Typography.P1
                    align="center"
                    color="secondary"
                    weight={500}
                    className={styles.resendLink}
                  >
                    {authScreen === AuthScreen.ConfirmationLinkPassword
                      ? 'For help with email reset'
                      : 'For help with verification email'}{' '}
                    <a href="mailto:help@deepgainventures.com">
                      help@deepgainventures.com
                    </a>
                  </Typography.P1>
                  <ButtonPrimary
                    text="Close"
                    isFullWidth
                    onClick={() => {
                      setAuthScreen(AuthScreen.Signin);
                    }}
                  ></ButtonPrimary>
                </div>
              </div>
            </>
          </div>
        )}
        {authScreen === AuthScreen.EmailWaiting && (
          <div
            className={cl(
              styles.signInForm,
              styles.confirmationLink,
              styles.confirmationLinkWaiting
            )}
          >
            <LoadingIndicator />
          </div>
        )}
        {authScreen === AuthScreen.EmailConfirmed && (
          <EmailConfirmed goBack={goToSignInScreen} />
        )}
        {authScreen === AuthScreen.EmailConfirmationError && (
          <EmailConfirmationError goBack={goToSignInScreen} />
        )}
        {authScreen === AuthScreen.Gdpr && (
          <Gdpr goBack={goToSignUpScreen} isDarkTheme={isDarkTheme} />
        )}
        {authScreen === AuthScreen.TermsAndConditions && (
          <TermsAndConditions goBack={goToSignUpScreen} />
        )}
      </div>
    </div>
  );
};

export default Authorization;
