import { createSlice } from '@reduxjs/toolkit';
import { isString } from 'lodash';
import type { RootState } from 'src/store/store';

type UiState = {
  isProView: boolean;
  isCollapsedSidebar: boolean;
  isDarkTheme: boolean;
  tableFilters: {
    controls: {
      Tooltip: boolean;
      'Show cents': boolean;
      'Hide empty folders': boolean;
      'Highlight ITM options': boolean;
    };
  };
  myEnvironmentModal: {
    isOpen: boolean;
    screen: ModalScreen;
    selectedAccount?: {
      accountId: number;
      accountName: string;
    };
  };
  userProfileModal: {
    isOpen: boolean;
    screen: UserProfileModalScreen;
  };
  notepadModal: {
    isOpen: boolean;
  };
};

export enum ModalScreen {
  Accounts = 'accounts',
  CustomViews = 'custom_views',
  Exchanges = 'exchanges',
  NewAccount = 'new_account',
  NewExchangeScreen = 'new_exchange_screen',
  ApiKeys = 'api_keys',
  CustomViewEdit = 'custom_view_edit',
  CustomInstruments = 'custom_instruments',
  NewCustomInstrument = 'new_custom_instrument',
  NewPosition = 'new_position',
  Positions = 'positions',
  EditPosition = 'edit_position',
  EditCustomInstrument = 'edit_custom_instrument',
}

export enum UserProfileModalScreen {
  UserInfo = 'user_info',
}

const getDarkThemePreferences = (): boolean => {
  const lsFlag = localStorage.getItem('is_dark_theme');

  if (isString(lsFlag)) {
    return lsFlag === '1';
    // @ts-ignore
  } else if (window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return true;
};

const initialState: UiState = {
  isProView: localStorage.getItem('is_pro_view') === '1',
  isCollapsedSidebar: localStorage.getItem('is_collapsed_sidebar') === '1',
  isDarkTheme: getDarkThemePreferences(),
  tableFilters: {
    controls: {
      Tooltip: localStorage.getItem('tooltip') === '1' ? true : false,
      'Show cents': localStorage.getItem('showCents') === '1' ? true : false,
      'Hide empty folders':
        localStorage.getItem('hideEmptyFolders') === '1' ? true : false,
      'Highlight ITM options':
        localStorage.getItem('highlightItmOptions') === '1' ? true : false,
    },
  },
  myEnvironmentModal: {
    isOpen: false,
    screen: ModalScreen.Accounts,
  },
  userProfileModal: {
    isOpen: false,
    screen: UserProfileModalScreen.UserInfo,
  },
  notepadModal: {
    isOpen: false,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    switchIsCollapsedSidebar: (state) => {
      state.isCollapsedSidebar = !state.isCollapsedSidebar;
      localStorage.setItem('is_collapsed_sidebar', state.isCollapsedSidebar ? '1' : '0');
    },
    switchIsDarkTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
      localStorage.setItem('is_dark_theme', state.isDarkTheme ? '1' : '0');
    },
    setIsDarkTheme: (state, action) => {
      state.isDarkTheme = action.payload;
      localStorage.setItem('is_dark_theme', state.isDarkTheme ? '1' : '0');
    },
    setIsProView: (state, action) => {
      state.isProView = action.payload;
      localStorage.setItem('is_pro_view', state.isProView ? '1' : '0');
    },
    switchIsProView: (state) => {
      state.isProView = !state.isProView;
      localStorage.setItem('is_pro_view', state.isProView ? '1' : '0');
    },
    switchHasTooltip: (state) => {
      state.tableFilters.controls.Tooltip = !state.tableFilters.controls.Tooltip;

      localStorage.setItem('tooltip', state.tableFilters.controls['Tooltip'] ? '1' : '0');
    },
    switchShowCents: (state) => {
      state.tableFilters.controls['Show cents'] =
        !state.tableFilters.controls['Show cents'];

      localStorage.setItem(
        'showCents',
        state.tableFilters.controls['Show cents'] ? '1' : '0'
      );
    },
    switchHasHiddenFolders: (state) => {
      state.tableFilters.controls['Hide empty folders'] =
        !state.tableFilters.controls['Hide empty folders'];
      localStorage.setItem(
        'hideEmptyFolders',
        state.tableFilters.controls['Hide empty folders'] ? '1' : '0'
      );
    },
    switchHasHighlight: (state) => {
      state.tableFilters.controls['Highlight ITM options'] =
        !state.tableFilters.controls['Highlight ITM options'];
      localStorage.setItem(
        'highlightItmOptions',
        state.tableFilters.controls['Highlight ITM options'] ? '1' : '0'
      );
    },
    switchMyEnvironmentModalIsOpen: (state) => {
      state.myEnvironmentModal.isOpen = !state.myEnvironmentModal.isOpen;
    },
    setMyEnvironmentModalScreen: (state, action) => {
      state.myEnvironmentModal.screen = action.payload;
    },
    switchUserProfileModalIsOpen: (state) => {
      state.userProfileModal.isOpen = !state.userProfileModal.isOpen;
    },
    setUserProfileModalScreen: (state, action) => {
      state.userProfileModal.screen = action.payload;
    },
    setSelectedAcount: (state, action) => {
      state.myEnvironmentModal.selectedAccount = action.payload;
    },
    switchNotepadModalIsOpen: (state) => {
      state.notepadModal.isOpen = !state.notepadModal.isOpen;
    },
  },
});

export const {
  switchIsCollapsedSidebar,
  setIsProView,
  switchIsProView,
  switchIsDarkTheme,
  setIsDarkTheme,
  switchHasTooltip,
  switchShowCents,
  switchHasHiddenFolders,
  switchHasHighlight,
  switchMyEnvironmentModalIsOpen,
  setMyEnvironmentModalScreen,
  switchUserProfileModalIsOpen,
  setUserProfileModalScreen,
  setSelectedAcount,
  switchNotepadModalIsOpen,
} = uiSlice.actions;

export const selectIsCollapsedSidebar = (state: RootState) => state.ui.isCollapsedSidebar;
export const selectIsProView = (state: RootState) => state.ui.isProView;
export const selectIsDarkTheme = (state: RootState) => state.ui.isDarkTheme;
export const selectTableFiltersControls = (state: RootState) =>
  state.ui.tableFilters.controls;
export const selectMyEnvironmentModalIsOpen = (state: RootState) =>
  state.ui.myEnvironmentModal.isOpen;
export const selectMyEnvironmentModalScreen = (state: RootState) =>
  state.ui.myEnvironmentModal.screen;
export const selectUserProfileModalIsOpen = (state: RootState) =>
  state.ui.userProfileModal.isOpen;
export const selectUserProfileModalScreen = (state: RootState) =>
  state.ui.userProfileModal.screen;
export const selectSelectedAccount = (state: RootState) =>
  state.ui.myEnvironmentModal.selectedAccount;
export const selectIsOpenNotepadModal = (state: RootState) =>
  state.ui.notepadModal.isOpen;

export default uiSlice.reducer;
