import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MAIN_VIEW_ROUTE } from 'src/constants';

const IndexRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${MAIN_VIEW_ROUTE}`);
  }, []);

  return null;
};

export default IndexRoute;
