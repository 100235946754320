import React, { useRef, useState } from 'react';
import cl from 'clsx';

import styles from './DownloadReport.module.scss';
import { ButtonPrimary } from '../ButtonPrimary';
import { Checkbox } from '../Checkbox';
import { ComingSoon } from '../ComingSoon';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/spinner.svg';
import { Typography } from '../Typography';

export type DownloadReportProps = {
  isVisible: boolean;
  isBlocked: boolean;
  onClose: () => void;
  onSubmit: (checkedReports: [boolean, boolean]) => void;
  className?: string;
};

const DownloadReport = ({
  isVisible,
  isBlocked,
  onClose,
  onSubmit,
  className,
}: DownloadReportProps) => {
  const [checkedReports, setCheckedReports] = useState<[boolean, boolean]>([
    false,
    false,
  ]);

  const backdropRef = useRef<HTMLDivElement>(null);
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === backdropRef.current && !isBlocked) {
      onClose();
    }
  };

  return (
    <div
      className={cl(styles.downloadReportBackdrop, isVisible && styles.visible)}
      onClick={handleBackdropClick}
      ref={backdropRef}
    >
      <div className={cl(styles.downloadReport, className && className)}>
        <button
          onClick={() => {
            if (!isBlocked) {
              onClose();
            }
          }}
          className={styles.close}
        ></button>
        <Typography.P1 weight={600} className={styles.heading}>
          DOWNLOAD REPORT
        </Typography.P1>
        <div className={cl(styles.positions, checkedReports[0] && styles.isChecked)}>
          <Checkbox
            name="download_report"
            checked={checkedReports[0]}
            label="Positions"
            onChange={() => {
              setCheckedReports([!checkedReports[0], checkedReports[1]]);
            }}
          />
        </div>
        <div className={cl(styles.positions, checkedReports[1] && styles.isChecked)}>
          <Checkbox
            name="download_risk_report"
            checked={checkedReports[1]}
            label="Risk report"
            onChange={() => {
              setCheckedReports([checkedReports[0], !checkedReports[1]]);
            }}
          />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.comingSoon}>
          <Checkbox
            name="coming_soon_2"
            checked={false}
            disabled
            label="Transactions"
            onChange={() => {}}
          />
          <ComingSoon />
        </div>
        <div className={styles.comingSoon}>
          <Checkbox
            name="coming_soon_3"
            checked={false}
            disabled
            label="P&L Report"
            onChange={() => {}}
          />
          <ComingSoon />
        </div>
        <ButtonPrimary
          size="large"
          text={isBlocked ? 'Downloading' : 'Download'}
          isDisabled={(!checkedReports[0] && !checkedReports[1]) || isBlocked}
          icon={isBlocked ? <SpinnerIcon className={styles.spinner} /> : null}
          isFullWidth
          onClick={() => onSubmit(checkedReports)}
          className={styles.downloadButton}
        />
      </div>
    </div>
  );
};

export default DownloadReport;
