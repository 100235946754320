import { instance } from './mainInstance';

export interface AddAccountPropTypes {
  accountName: string;
}

export const UserService = {
  signin: async (body: { email: string; password: string }) => {
    return await instance.post(`user/login`, body);
  },
  signout: async () => {
    return await instance.put(`user/logout`);
  },
  signup: async (body: {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    password: string;
    userPicture: 0 | 1 | 2 | 3 | 4;
    userTheme: 0 | 1 | 2;
    userTimezone: string;
  }) => {
    return await instance.post(`user`, body);
  },
  userInfo: async () => {
    return await instance.get(`user/info`);
  },
  userUpdate: async (body: {
    firstName: string;
    lastName: string;
    companyName: string;
    userPicture: 0 | 1 | 2 | 3 | 4;
    userTheme: 0 | 1 | 2;
    userTimezone: string;
  }) => {
    return await instance.put('user', body);
  },
  passwordChange: async (body: { oldPassword: string; newPassword: string }) => {
    return await instance.put(`user/password/change`, body);
  },
  emailVerify: async (body: string) => {
    return await instance.put(`user/emailVerify`, body);
  },
  resendEmailVerify: async (body: string) => {
    return await instance.put(`user/resendEmailVerify`, body);
  },
  sendChangePasswordEmail: async (body: string) => {
    return await instance.put(`/user/sendChangePasswordEmail`, body);
  },
  passwordChangeByToken: async (body: {
    changePasswordActionToken: string;
    newPassword: string;
  }) => {
    return await instance.put(`/user/password/changeByToken`, body);
  },
  requestAccess: async () => {
    return await instance.post('/user/apiClient/requestAccess');
  },
  generateKey: async () => {
    return await instance.post('/user/apiClient/generateKey');
  },
  apiKeys: async () => {
    return await instance.get('/user/apiClient/apiKeys');
  },
  deleteApiKey: async (apiKey: string) => {
    return await instance.delete(`/user/apiClient/apiKeys/${apiKey}`);
  },
};
