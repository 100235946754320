export const formatTime = (timestamp: number | undefined, timeZone?: string) => {
  return Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'medium',
    timeZone: timeZone ? getTimezone(timeZone) : undefined,
  }).format(timestamp);
};

export const formatTimeWithoutSeconds = (timestamp: number, timeZone?: string) => {
  return Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone ? getTimezone(timeZone) : undefined,
  }).format(timestamp * 1000);
};

export const formatDate = (timestamp: number | undefined, timeZone?: string) => {
  return Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeZone: timeZone ? getTimezone(timeZone) : undefined,
  }).format(timestamp);
};

const getTimezone = (timeZone: string) => {
  const [timeZoneValue] = timeZone.split('|');
  if (timeZoneValue === 'Tokyo') {
    return 'Asia/Tokyo';
  } else if (timeZoneValue === 'Moscow') {
    return 'Europe/Moscow';
  } else if (timeZoneValue === 'Berlin') {
    return 'Europe/Berlin';
  } else if (timeZoneValue === 'London') {
    return 'Europe/London';
  } else if (timeZoneValue === 'New York') {
    return 'America/New_York';
  }

  return timeZoneValue;
};

export const getMonth = (utcMonth: number) => {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  return months[utcMonth];
};

export const getShortenYear = (utcFullYear: number) => {
  const shortenYear = utcFullYear.toString().slice(2);

  return shortenYear;
};
