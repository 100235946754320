import React from 'react';
import DatePicker from 'react-datepicker';
import cl from 'clsx';
import styles from './Datepicker.module.scss';
import { Typography } from 'src/ui-kit/Typography';

const Datepicker = ({
  title,
  disabled,
  selected,
  onChange,
}: {
  title?: string;
  disabled?: boolean;
  selected: Date | null | undefined;
  onChange: (date: Date) => void;
}) => {
  return (
    <div className={cl(styles.datepicker, disabled && styles.disabled)}>
      {title && (
        <Typography.H3 className={cl(styles.title, disabled && styles.disabledTitle)}>
          {title}
        </Typography.H3>
      )}
      <DatePicker
        disabled={disabled}
        selected={selected}
        dateFormat={'dd-MMM-yyyy HH:mm'}
        timeFormat={'HH:mm'}
        showTimeSelect
        timeIntervals={15}
        onChange={onChange}
        className={cl(styles.datepickerInput)}
        shouldCloseOnSelect={true}
      />
    </div>
  );
};

export default Datepicker;
