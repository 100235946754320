import { all, fork } from 'redux-saga/effects';
import positionsSaga from 'src/sagas/positions.saga';
import customInstrumentSaga from 'src/sagas/customInstrument.saga';
import userSaga from 'src/sagas/user.saga';

const rootSaga = function* () {
  yield all([fork(positionsSaga), fork(customInstrumentSaga), fork(userSaga)]);
};

export default rootSaga;
