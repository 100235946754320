import React from 'react';
import cl from 'clsx';

import styles from './ButtonHeader.module.scss';

export type ButtonHeaderProps = {
  icon: React.ReactNode;
  className?: string;
  onClick: () => void;
};

const ButtonHeader = ({ icon, className, onClick }: ButtonHeaderProps) => {
  return (
    <button onClick={onClick} className={cl(styles.buttonHeader, className && className)}>
      {icon}
    </button>
  );
};

export default ButtonHeader;
