import {
  EmptyMyEnvironmentScreen,
  Kebab,
  LoadingIndicator,
  MyEnvironmentCardsHeader,
  MyEnvironmentCardsList,
} from 'src/ui-kit';

import styles from './Positions.module.scss';
import { useEffect } from 'react';
import { formatTimeWithoutSeconds, nf2 } from 'src/utils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  ModalScreen,
  selectIsDarkTheme,
  setMyEnvironmentModalScreen,
} from 'src/store/slices/uiSlice';
import {
  editPosition,
  fetchPositionsList,
  managePositions,
  selectCustomPositionSelectedProvider,
  selectNewPositionPortfolioId,
  selectPositionsList,
  setSelectedPosition,
} from 'src/store/slices/positionsSlice';
import { selectUser } from 'src/store/slices/userSlice';
import { fetchCustomInstrumentList } from 'src/store/slices/customInstrumentSlice';

type CustomViewsScreenProps = {
  setCustomViewEdit: React.Dispatch<any>;
  setNewViewMode: React.Dispatch<boolean>;
  onDelete: (account: any) => void;
};

const PositionsScreen = ({ onDelete }: CustomViewsScreenProps) => {
  const dispatch = useDispatch();

  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const selectedProvider = useAppSelector(selectCustomPositionSelectedProvider);
  const positionsList = useAppSelector(selectPositionsList);
  const user = useAppSelector(selectUser);

  const handleButtonClick = async () => {
    await dispatch(managePositions(selectedProvider!));
  };

  const handleBackClick = () => {
    dispatch(setMyEnvironmentModalScreen(ModalScreen.Exchanges));
  };

  useEffect(() => {
    if (selectedProvider) {
      dispatch(fetchPositionsList(selectedProvider.id));
      dispatch(fetchCustomInstrumentList());
    }
  }, []);

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="Positions"
        descriptionText="Provider: "
        highlightedDescriptionText={selectedProvider?.name}
        buttonText="New position"
        goBack={handleBackClick}
        buttonAction={handleButtonClick}
      />
      <MyEnvironmentCardsList isDarkTheme={isDarkTheme}>
        {positionsList === null ? (
          <div className={styles.loadingIndicatorWrapper}>
            <LoadingIndicator />
          </div>
        ) : positionsList.length === 0 ? (
          <EmptyMyEnvironmentScreen
            line1="Please add positions to start."
            line2="All information will be displayed here."
          />
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Trade direction</td>
                <td>Amount</td>
                <td>Price</td>
                <td>Trade date</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {positionsList.map((position: any, index: number) => {
                return (
                  <tr key={`${position.name}-${index}`}>
                    <td>{position.customInstrument.instrumentName}</td>
                    <td>{position.tradeDirection}</td>
                    <td>{position.amount ? nf2.format(position.amount) : ''}</td>
                    <td>
                      {position.price === 0 || position.price === null
                        ? ''
                        : nf2.format(position.price)}
                    </td>
                    <td>
                      {formatTimeWithoutSeconds(
                        position.tradeTimestamp,
                        user.userTimezone
                      )}
                    </td>
                    <td>
                      <Kebab
                        items={[
                          {
                            title: 'Edit',
                            onClick: () => {
                              dispatch(
                                editPosition({
                                  selectedProvider,
                                  selectedPosition: position,
                                })
                              );
                            },
                          },
                          {
                            title: 'Delete',
                            onClick: () => {
                              onDelete(position);
                            },
                          },
                        ]}
                        menuPosition="right"
                        className={styles.kebab}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </MyEnvironmentCardsList>
    </>
  );
};

export default PositionsScreen;
