import React from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectIsOpenNotepadModal,
  selectMyEnvironmentModalIsOpen,
  selectUserProfileModalIsOpen,
} from 'src/store/slices/uiSlice';
import { ModalMyEnvironment } from '../ModalMyEnvironment';
import { ModalUserProfile } from '../ModalUserProfile';
import { ModalNotepad } from '../ModalNotepad';

const Modals = () => {
  const isOpenMyEnvironmentModal = useAppSelector(selectMyEnvironmentModalIsOpen);
  const isOpenUserProfileModal = useAppSelector(selectUserProfileModalIsOpen);
  const isOpenNotepadModal = useAppSelector(selectIsOpenNotepadModal);

  return (
    <>
      {isOpenMyEnvironmentModal && (
        <ModalMyEnvironment isOpen={isOpenMyEnvironmentModal} />
      )}
      {isOpenUserProfileModal && <ModalUserProfile />}
      {isOpenNotepadModal && <ModalNotepad />}
    </>
  );
};

export default Modals;
