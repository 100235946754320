import { createAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import type { RootState } from 'src/store/store';

type ApiResponse = {
  error?: string;
  status: null | 'pending' | 'fulfilled' | 'rejected';
};

type UserState = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  userPicture: 0 | 1 | 2 | 3 | 4;
  userTheme: 0 | 1 | 2;
  userTimezone: string;
  userRoles: (
    | 'default-roles-risk-management'
    | 'dgv-api-client'
    | 'subscription-basic'
    | 'subscription-pro'
  )[];
  requests: {
    sendChangePasswordEmail: ApiResponse;
  };
};

const initialState: UserState = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  userPicture: 0,
  userTheme: 2,
  userTimezone: '',
  userRoles: [],
  requests: {
    sendChangePasswordEmail: {
      status: null,
    },
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    removeUser: (state) => {
      state = { ...initialState };
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;
export const logout = createAction<boolean>(`${userSlice.name}/logout`);

export const selectUser = (state: RootState) => state.user;
export const selectIsLoggedIn = (state: RootState) => !!state.user.email;
export const selectIsApiClient = createSelector(selectUser, (state: UserState) =>
  state.userRoles.includes('dgv-api-client')
);

export default userSlice.reducer;
