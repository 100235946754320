import { GenericAbortSignal } from 'axios';
import { instance } from './mainInstance';

export interface UpdateViewPropTypes {
  viewId: number;
  viewName: string;
  accountId: number;
  accountName: string;
  exchangeList: [
    {
      exchangeId: number;
      exchangeName: string;
      exchangeType: string;
      accountId: number;
      accountName: string;
      portfolios: [
        {
          portfolioId: number;
          portfolioName: string;
          description: string;
          lastUpdateTime: string;
        }
      ];
    }
  ];
}

export interface GetViewBalanceResponse {
  viewId: number;
  viewName: string;
  accountBalances: any;
  updateTimestamp: number;
}

export interface UpdateViewsAttach {
  viewName: string;
  portfolioList: [];
  isAttach: boolean;
}

export interface UpdateViewsResponse {
  find(arg0: (item: any) => boolean): UpdateViewsResponse[];
  viewId: number;
  viewName: string;
  accounts: [
    {
      accountId: number;
      accountName: string;
      exchanges: [
        {
          exchangeId: number;
          exchangeName: string;
          exchangeType: string;
          accountId: number;
          accountName: string;
          portfolios: [
            {
              portfolioId: number;
              portfolioName: string;
              description: string;
              lastUpdateTime: string;
            }
          ];
        }
      ];
    }
  ];
  isAttach: boolean;
}

export interface AddViewPropTypes {
  viewName: string;
  portfolioList?: any[];
  isAttach: boolean; // portfolioId
  portfolioIdWeightMap?: any;
}

export const CustomViewService = {
  getView: async (id: number) => {
    return await instance.get(`view/${id}`);
  },
  getBalanceView: async (id: number, signal?: GenericAbortSignal) => {
    return await instance.get(`view/balance/${id}`, {
      signal,
    });
  },
  updateView: async (id: number, body: AddViewPropTypes) => {
    return await instance.put(`view/${id}`, body);
  },
  deleteView: async (id: number) => {
    return await instance.delete(`view/${id}`);
  },
  createView: async (body: AddViewPropTypes) => {
    return await instance.post(`view`, body);
  },
  allViews: async () => {
    return await instance.get(`view/all`);
  },
  renameView: async (id: number, body: AddViewPropTypes) => {
    return await instance.patch(`view/rename/${id}`, {
      ...body,
      portfolioList: [],
    });
  },
  getBalanceProView: async (id: number, signal?: GenericAbortSignal) => {
    return await instance.get(`view/balance/pro/${id}`, {
      signal,
    });
  },
};
