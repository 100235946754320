import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DashboardData } from './components';
import { DashboardControls } from './components';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { setActiveViewId } from 'src/store/slices/activeView';
import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from 'src/constants';

const Dashboard = () => {
  const { viewId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveViewId(viewId));
  }, []);

  useEffect(() => {
    dispatch(setActiveViewId(viewId));
  }, [viewId]);

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
        <meta name="description" content={DEFAULT_PAGE_DESCRIPTION} />
      </Helmet>
      <DashboardControls />
      <DashboardData />
    </>
  );
};

export default Dashboard;
