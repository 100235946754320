import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';
import { MAIN_VIEW_ROUTE } from 'src/constants';

type ActiveViewState = {
  id: number;
};

const initialState: ActiveViewState = {
  id: 0,
};

export const activeViewSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setActiveViewId: (state, action) => {
      return {
        ...state,
        id: action.payload === MAIN_VIEW_ROUTE ? 0 : Number(action.payload),
      };
    },
  },
});

export const { setActiveViewId } = activeViewSlice.actions;

export const selectActiveViewId = (state: RootState) => state.activeView.id;
export const selectIsMainView = (state: RootState) => state.activeView.id === 0;

export default activeViewSlice.reducer;
