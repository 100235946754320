import React from 'react';
import cl from 'clsx';

import styles from './TextInput.module.scss';
import { isNumber } from 'src/utils';
import { Hint, Typography } from 'src/ui-kit';

export enum TextInputSize {
  Large = 'large',
  Small = 'small',
}

export type TextInputProps = {
  name?: string;
  size?: TextInputSize;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hint?: {
    width: number;
    text: string;
  };
  autoFocus?: boolean;
  isPassword?: boolean;
  number?: boolean;
  title?: string;
  autoComplete?: 'off' | 'on';
  dropdownInput?: boolean;
  flushed?: boolean;
  ref?: (inputElement: HTMLInputElement) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  inputClassName?: string;
};

const TextInput: React.FunctionComponent<TextInputProps> = ({
  name,
  size = TextInputSize.Large,
  value = '',
  error,
  disabled,
  placeholder,
  autoFocus,
  isPassword,
  hint,
  title,
  number = false,
  autoComplete,
  dropdownInput,
  flushed,
  ref,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  className,
  inputClassName,
}) => {
  const handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0 || isNumber(event.target.value)) {
      onChange(event);
    }
  };

  return (
    <>
      <div
        className={cl(
          styles.textInputWrapper,
          disabled && styles.disabled,
          error && styles.error,
          styles[size],
          dropdownInput && styles.dropdownInput,
          className && className
        )}
      >
        <div className={styles.titleWrapper}>
          {title && (
            <Typography.H3
              className={cl(
                styles.title,
                disabled && styles.disabledTitle,
                flushed && styles.flushedTitle
              )}
            >
              {title}
            </Typography.H3>
          )}
          {hint && <Hint width={hint.width} hint={<>{hint.text}</>} />}
        </div>
        <input
          name={name}
          type={isPassword ? 'password' : 'text'}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={number ? handleNumberInputChange : onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          ref={ref}
          className={cl(
            styles.textInput,
            dropdownInput && styles.dropdownInputInput,
            flushed && styles.flushed,
            inputClassName && inputClassName
          )}
        />
      </div>
    </>
  );
};

export default TextInput;
