import React from 'react';
import { ButtonPrimary, Typography } from 'src/ui-kit';
import { ReactComponent as PlusIcon } from 'src/assets/images/modalPlus.svg';

import styles from './MyEnvironmentCardsHeader.module.scss';

type MyEnvironmentCardsHeaderProps = {
  headingText: string;
  descriptionText: string;
  highlightedDescriptionText?: string;
  buttonText: string;
  hasButtonIcon?: boolean;
  isButtonDisabled?: boolean;
  goBack?: () => void;
  buttonAction: () => void;
};

const MyEnvironmentCardsHeader = ({
  headingText,
  descriptionText,
  highlightedDescriptionText,
  buttonText,
  hasButtonIcon = true,
  isButtonDisabled = false,
  goBack,
  buttonAction,
}: MyEnvironmentCardsHeaderProps) => {
  return (
    <div className={styles.myEnvironmentsCardsHeader}>
      <div>
        <div className={styles.nameWrapper}>
          {goBack && <button className={styles.back} onClick={goBack}></button>}
          <Typography.H3 weight={700} isUppercase className={styles.heading}>
            {headingText}
          </Typography.H3>
        </div>
        <Typography.P1 className={styles.description}>
          {descriptionText}
          {highlightedDescriptionText && (
            <Typography.P1
              as="span"
              weight={600}
              color="purple"
              className={styles.highlightedDescription}
            >
              {highlightedDescriptionText}
            </Typography.P1>
          )}
        </Typography.P1>
      </div>
      <div>
        <ButtonPrimary
          text={buttonText}
          icon={hasButtonIcon && <PlusIcon />}
          isDisabled={isButtonDisabled}
          onClick={buttonAction}
        />
      </div>
    </div>
  );
};

export default MyEnvironmentCardsHeader;
