import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { orderBy, sortBy } from 'lodash';
import { CHART_COLORS } from 'src/constants';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';

import styles from './Chart.module.scss';

type ChartProps = {
  stat?: Stat[];
  type?: 'exchanges exposure' | 'asset allocation';
  isPending: boolean;
};

type Stat = {
  exchangeType?: string;
  currencyTicker?: string;
  percentOfTotal: number;
};

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const Chart = ({ stat, type = 'exchanges exposure', isPending }: ChartProps) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const [colors, setColors] = useState<string[]>([]);
  const [labels, setLabels] = useState<(string | undefined)[]>([]);
  const [values, setValues] = useState<number[]>([]);

  useEffect(() => {
    if (stat) {
      const sortedStat =
        type === 'exchanges exposure'
          ? orderBy(stat, ['percentOfTotal'], ['desc'])
          : sortBy(orderBy(stat, ['percentOfTotal'], ['desc']), (item) => {
              return item.currencyTicker === 'Other';
            });
      setColors(
        sortedStat.map((item, index) => {
          return CHART_COLORS[index];
        })
      );
      setLabels(
        sortedStat.map((item) => {
          return type === 'exchanges exposure' ? item.exchangeType : item.currencyTicker;
        })
      );
      setValues(
        sortedStat.map((item) => {
          return Math.round(item.percentOfTotal * 10) / 10;
        })
      );
    }
  }, [stat]);

  const data: any = {
    labels: labels.length < 1 || isPending ? ['No data'] : labels,
    datasets: [
      {
        label: '',
        data: values.length < 1 || isPending ? [100] : values,
        backgroundColor: colors.length < 1 || isPending ? ['gray'] : colors,
        borderColor: colors.length < 1 || isPending ? ['gray'] : colors,
        borderWidth: 1,
      },
    ],
  };

  const options: any = {
    animations: false,
    maintainAspectRatio: false,
    cutout: 56,
    plugins: {
      legend: {
        position: 'left',
        align: 'center',
        width: 112,
        labels: {
          boxHeight: 12,
          boxWidth: 12,
          boxPadding: 6,
          padding: 8,
          useBorderRadius: true,
          borderRadius: 6,
          color: isDarkTheme ? '#E9E9FF' : '#363853',
          font: {
            size: 12,
            weight: '500',
            family: 'Chakra Petch',
          },
          generateLabels: (chart: any) => {
            return chart.data.datasets[0].data.map((data: any, i: any) => {
              return {
                text:
                  labels.length < 1 || isPending
                    ? 'No data'
                    : `${chart.data.labels[i]}, ${data}%`,
                fontColor: isDarkTheme ? '#E9E9FF' : '#363853',
                fillStyle: chart.data.datasets[0].backgroundColor[i],
                index: i,
              };
            });
          },
        },
      },
      tooltip: {
        callbacks: {
          label(tooltipItem: any) {
            return tooltipItem.parsed + '%';
          },
        },
      },
    },
  };

  return (
    <div className={styles.chart}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default Chart;
