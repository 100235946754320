import React from 'react';
import cl from 'clsx';
import { FilterTabButton, Typography } from 'src/ui-kit';

import styles from './FiltersTopSection.module.scss';
import { formatTime, nf2 } from 'src/utils';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectUser } from 'src/store/slices/userSlice';
import {
  selectAccountBalance,
  selectProViewBalance,
} from 'src/store/slices/accountSlice';
import { selectCustomViewBalance } from 'src/store/slices/customViewsSlice';
import { isNumber } from 'lodash';

type FiltersTopSectionProps = {
  activeFilter: 0 | 1 | 2;
  coinsNumber?: number;
  exchangesNumber?: number;
  isMainScreen: boolean;
  accountBalanceTotal: number;
  customViewBalanceTotal: number;
  className?: string;
  setActiveFilter: (value: React.SetStateAction<0 | 1 | 2>) => void;
};

const FiltersTopSection = ({
  activeFilter,
  coinsNumber = 0,
  exchangesNumber = 0,
  isMainScreen,
  accountBalanceTotal,
  customViewBalanceTotal,
  className,
  setActiveFilter,
}: FiltersTopSectionProps) => {
  const accountBalance = useAppSelector(selectProViewBalance);
  const customViewBalance = useAppSelector(selectCustomViewBalance);
  const user = useAppSelector(selectUser);

  return (
    <div className={cl(styles.filtersTopSection, className && className)}>
      <div className={styles.tabWrapper}>
        <FilterTabButton
          id={0}
          name="Coins"
          activeFilter={activeFilter}
          number={coinsNumber}
          setActiveFilter={setActiveFilter}
        />
        <FilterTabButton
          id={1}
          name="Exchanges"
          activeFilter={activeFilter}
          number={exchangesNumber}
          setActiveFilter={setActiveFilter}
        />
        <FilterTabButton
          id={2}
          name="Controls"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      </div>
      <div className={styles.usdTotal}>
        <Typography.P1 className={styles.cardTotalTimestamp}>
          {isMainScreen ? (
            <>Updated {formatTime(accountBalance?.updateTimestamp, user.userTimezone)}</>
          ) : (
            <>
              Updated {formatTime(customViewBalance?.updateTimestamp, user.userTimezone)}
            </>
          )}
        </Typography.P1>

        <Typography.P1 className={styles.cardTotalHeading}>USD total</Typography.P1>
        <Typography.P1 className={cl('number', styles.cardTotalSum)}>
          {isMainScreen ? (
            <>
              {accountBalanceTotal === 0 || typeof accountBalanceTotal === 'undefined'
                ? '---'
                : nf2.format(accountBalanceTotal)}
            </>
          ) : (
            <>
              {customViewBalanceTotal === 0 ||
              typeof customViewBalanceTotal === 'undefined'
                ? '---'
                : nf2.format(customViewBalanceTotal)}
            </>
          )}
        </Typography.P1>
      </div>
    </div>
  );
};

export default FiltersTopSection;
