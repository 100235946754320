import React, { useEffect, useRef, useState } from 'react';
import cl from 'clsx';
import { ButtonSecondary, Checkbox, FilterLine, Typography } from 'src/ui-kit';

import styles from './FiltersBtmSection.module.scss';

type FiltersBtmSectionProps = {
  isCoinsFilter?: boolean;
  items: Record<string, boolean>;
  action: (name: string) => void;
  selectAllCoins: () => void;
  deselectAllCoins: () => void;
};

const FiltersBtmSection = ({
  isCoinsFilter = false,
  items,
  selectAllCoins,
  deselectAllCoins,
  action,
}: FiltersBtmSectionProps) => {
  const [isVisibleOtherCoinsPopup, setIsVisibleOtherCoinsPopup] = useState(false);
  const handleChange = (name: string) => () => action(name);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsVisibleOtherCoinsPopup(false);
      }

      if (!isVisibleOtherCoinsPopup) {
        event.stopPropagation();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const getCoinsFilterList = () => {
    const coins = Object.keys(items);

    const result: { level1: string[]; level2: string[] } = {
      level1: [],
      level2: coins.filter((ticker) => ticker !== 'BTC' && ticker !== 'ETH'),
    };

    if (coins.includes('ETH')) {
      result.level1.unshift('ETH');
    }

    if (coins.includes('BTC')) {
      result.level1.unshift('BTC');
    }

    return result;
  };

  if (isCoinsFilter) {
    return (
      <>
        {isVisibleOtherCoinsPopup && <div className={styles.backdrop} />}
        <div className={cl(styles.filtersBtmSection, styles.coinFiltersBtmSection)}>
          <div className={styles.filterLineWrapper}>
            <FilterLine name="Select all" onClick={selectAllCoins} />
            <FilterLine name="Deselect all" onClick={deselectAllCoins} />
          </div>
          <div className={styles.filtersRailWrapper}>
            <div className={styles.filtersRail}>
              {getCoinsFilterList().level1.map((name, index) => {
                return (
                  <Checkbox
                    name={name}
                    label={name}
                    checked={items[name]}
                    onChange={handleChange(name)}
                    key={`${name}-${index}`}
                  />
                );
              })}
              {getCoinsFilterList().level2.length > 0 && (
                <ButtonSecondary
                  text="Other"
                  onClick={() => {
                    setIsVisibleOtherCoinsPopup(!isVisibleOtherCoinsPopup);
                  }}
                  className={styles.otherCoinsBtn}
                  textClassName={styles.otherCoinsBtnText}
                >
                  <div
                    className={cl(
                      styles.otherCoinsPopup,
                      isVisibleOtherCoinsPopup && styles.otherCoinsPopupOpen
                    )}
                    ref={menuRef}
                  >
                    <Typography.P1 weight={600} isUppercase className={styles.heading}>
                      Other coins
                    </Typography.P1>
                    <button
                      onClick={() => {
                        setIsVisibleOtherCoinsPopup(false);
                      }}
                      className={styles.close}
                    ></button>
                    {getCoinsFilterList().level2.map((name, index) => {
                      return (
                        <Checkbox
                          name={name}
                          label={name}
                          checked={items[name]}
                          onChange={handleChange(name)}
                          className={styles.coinsPopupCheckbox}
                          key={`${name}-${index}`}
                        />
                      );
                    })}
                  </div>
                </ButtonSecondary>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.filtersBtmSection}>
      <div className={styles.filtersRailWrapper}>
        <div className={styles.filtersRail}>
          {Object.keys(items).map((name, index) => {
            return (
              <Checkbox
                name={name}
                label={name}
                checked={items[name]}
                onChange={handleChange(name)}
                key={`${name}-${index}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FiltersBtmSection;
