import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';
import { RequestStatus } from 'src/types/requestStatus';
import { updateRequestStatus } from 'src/utils';
import { createSelector } from 'reselect';

type PositionsState = {
  list: any;
  selectedProvider: null | SelectedProvider;
  selectedPosition: any;
  meta: null | {
    instrumentTypeDirectionsMap: CustomPositionsMeta;
    instrumentTypesAvailableAvgPrice: string[];
  };
  requests: {
    createCustomPosition: RequestStatus;
  };
};

export type SelectedProvider = {
  id: number;
  name: string;
};

export type CustomPositionsMeta = Record<string, string[]>;

const initialState: PositionsState = {
  list: null,
  selectedProvider: null,
  selectedPosition: null,
  meta: null,
  requests: {
    createCustomPosition: {
      isFetching: false,
      error: null,
    },
  },
};

export const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setPositionsList: (state, action) => {
      state.list = action.payload;
    },
    resetPositionsList: (state) => {
      state.list = null;
    },
    setCustomPositionsSelectedProvider: (state, action) => {
      state.selectedProvider = action.payload;
    },
    setCustomPositionsMeta: (state, action) => {
      state.meta = action.payload;
    },
    setSelectedPosition: (state, action) => {
      state.selectedPosition = action.payload;
    },
    updateCreateCustomPositionStatus: updateRequestStatus('createCustomPosition'),
  },
});

export const {
  setCustomPositionsSelectedProvider,
  setCustomPositionsMeta,
  updateCreateCustomPositionStatus,
  setPositionsList,
  resetPositionsList,
  setSelectedPosition,
} = positionsSlice.actions;
export const fetchPositionsList = createAction<number>(
  `${positionsSlice.name}/fetchPositionsList`
);
export const managePositions = createAction<SelectedProvider>(
  `${positionsSlice.name}/managePositions`
);
export const editPosition = createAction<any>(`${positionsSlice.name}/editPosition`);
export const clearPositionsState = createAction(
  `${positionsSlice.name}/clearPositionsState`
);

export const selectSelectedPosition = (state: RootState) =>
  state.positions.selectedPosition;
export const selectPositionsList = (state: RootState) => state.positions.list;
export const selectCustomPositionSelectedProvider = (state: RootState) =>
  state.positions.selectedProvider;
export const selectCustomPositionsMeta = (state: RootState) => state.positions.meta;
export const selectCustomPositionsInstrumentTypeDirectionsMap = createSelector(
  selectCustomPositionsMeta,
  (meta) => {
    if (meta === null) {
      return null;
    }

    return meta.instrumentTypeDirectionsMap;
  }
);
export const selectCustomPositionsMetaInstrumentTypes = createSelector(
  selectCustomPositionsInstrumentTypeDirectionsMap,
  (meta) => {
    if (meta === null) {
      return [];
    }

    return Object.keys(meta);
  }
);
export const selectCustomPositionsMetaInstrumentValue = createSelector(
  [
    selectCustomPositionsInstrumentTypeDirectionsMap,
    (state: RootState, key: string | null) => key,
  ],
  (meta, key) => {
    if (meta === null || key === null) {
      return null;
    }

    return meta[key];
  }
);

export const selectCustomPositionsMetaInstrumentTypesAvailableAvgPrice = createSelector(
  selectCustomPositionsMeta,
  (meta) => {
    if (meta === null) {
      return [];
    }

    return meta.instrumentTypesAvailableAvgPrice;
  }
);

export const selectNewPositionPortfolioId = (state: RootState) => {
  return (
    state.account.list
      .find(
        (item) =>
          item.accountId === state.ui.myEnvironmentModal.selectedAccount?.accountId
      )
      ?.exchanges.find((item) => item.exchangeId === state.positions.selectedProvider?.id)
      ?.portfolios[0].portfolioId || 0
  );
};

export default positionsSlice.reducer;
