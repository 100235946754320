import React from 'react';
import cl from 'clsx';
import { Chart } from 'src/components/Chart';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectAccountBalance,
  selectAccountBalanceAssetAllocation,
  selectAccountBalanceExchangesExposure,
  selectAccountBalanceTotal,
  selectAccountList,
  selectAccountTableData,
  selectActiveAccount,
  selectIsFetchingBalances,
} from 'src/store/slices/accountSlice';
import {
  selectActiveView,
  selectCustomViewAssetAllocation,
  selectCustomViewBalance,
  selectCustomViewBalanceTotal,
  selectCustomViewTableData,
  selectCustomViewsExchangesExposure,
} from 'src/store/slices/customViewsSlice';
import {
  ModalScreen,
  selectIsDarkTheme,
  setMyEnvironmentModalScreen,
  switchMyEnvironmentModalIsOpen,
} from 'src/store/slices/uiSlice';
import {
  ButtonPrimary,
  Card,
  LoadingIndicator,
  Table,
  Scrollbar,
  Typography,
} from 'src/ui-kit';
import { formatTime, nf2, time } from 'src/utils';

import styles from './DashboardDataBasic.module.scss';
import { selectIsMainView } from 'src/store/slices/activeView';
import { isNil } from 'lodash';
import { selectUser } from 'src/store/slices/userSlice';

const DashboardDataBasic = () => {
  const dispatch = useAppDispatch();

  const accountList = useAppSelector(selectAccountList);
  const isMainScreen = useAppSelector(selectIsMainView);
  const activeAccount = useAppSelector(selectActiveAccount);
  const activeView = useAppSelector(selectActiveView);
  const accountBalance = useAppSelector(selectAccountBalance);
  const customViewBalance = useAppSelector(selectCustomViewBalance);
  const tableData = useAppSelector(selectAccountTableData);
  const customViewTableData = useAppSelector(selectCustomViewTableData);
  const accountBalanceTotal = useAppSelector(selectAccountBalanceTotal);
  const customViewBalanceTotal = useAppSelector(selectCustomViewBalanceTotal);
  const accountBalanceExchangeExposure = useAppSelector(
    selectAccountBalanceExchangesExposure
  );
  const accountBalanceAssetAllocation = useAppSelector(
    selectAccountBalanceAssetAllocation
  );
  const customViewExchangeExposure = useAppSelector(selectCustomViewsExchangesExposure);
  const customViewAssetAllocation = useAppSelector(selectCustomViewAssetAllocation);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const isFetchingBalances = useAppSelector(selectIsFetchingBalances);
  const user = useAppSelector(selectUser);

  const getAccountTotal = (tableData: any) => {
    return [...tableData].reduce((acc, currentValue) => {
      if (currentValue[1].type === 'exchange') {
        acc += currentValue[1].cells[3].fullNumber;
      }

      return acc;
    }, 0);
  };

  return (
    <div className={styles.columnWrapper}>
      <div className={styles.column}>
        {isFetchingBalances ? (
          <div className={styles.addAccountsWrapper}>
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {isMainScreen && (
              <>
                {accountBalance && accountBalance.exchangeBalances?.length > 0 && (
                  <Scrollbar height="calc(100vh - 208px)" isDarkTheme={isDarkTheme}>
                    <Table
                      data={{
                        columns: [
                          { name: 'Name' },
                          { name: 'Amount' },
                          { name: 'USD Price' },
                          { name: 'USD Value' },
                        ],
                        rows: tableData,
                      }}
                      isFixed={false}
                      hasTooltip={false}
                      hasCents={true}
                      className={styles.mainViewTable}
                    />
                  </Scrollbar>
                )}
                {accountList.length === 0 && (
                  <div className={styles.addAccountsWrapper}>
                    <Typography.P1 className={styles.addAccounts}>
                      Please add accounts to your environment.
                    </Typography.P1>
                    <ButtonPrimary
                      text="Start setup"
                      onClick={() => {
                        dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts));
                        dispatch(switchMyEnvironmentModalIsOpen());
                      }}
                    />
                  </div>
                )}
                {accountList.length > 0 &&
                  (isNil(accountBalance) ||
                    accountBalance.exchangeBalances?.length === 0) && (
                    <div className={styles.addAccountsWrapper}>
                      <Typography.P1 className={styles.addAccounts}>
                        Please add providers to your environment.
                      </Typography.P1>
                      <ButtonPrimary
                        text="Start setup"
                        onClick={() => {
                          dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts));
                          dispatch(switchMyEnvironmentModalIsOpen());
                        }}
                      />
                    </div>
                  )}
              </>
            )}
            {!isMainScreen && (
              <>
                {customViewBalance && customViewBalance.accountBalances?.length > 0 && (
                  <Scrollbar height="calc(100vh - 208px)" isDarkTheme={isDarkTheme}>
                    {customViewBalance.accountBalances.map((item, index) => {
                      return (
                        <React.Fragment key={`${item.accountName}-${index}`}>
                          <div className={styles.account}>
                            <Typography.P1 className={styles.accountName}>
                              {item.accountName}
                            </Typography.P1>
                            <Typography.Additional
                              color="white"
                              weight={600}
                              className={styles.total}
                            >
                              {nf2.format(getAccountTotal(customViewTableData[index]))}
                            </Typography.Additional>
                          </div>
                          <Table
                            data={{
                              columns: [
                                { name: 'Name' },
                                { name: 'Amount' },
                                { name: 'USD Price' },
                                { name: 'USD value' },
                              ],
                              rows: customViewTableData[index],
                            }}
                            className={styles.customViewTable}
                            isFixed={false}
                            hasTooltip={false}
                            hasCents={true}
                          />
                        </React.Fragment>
                      );
                    })}
                  </Scrollbar>
                )}
                {(customViewBalance === null ||
                  customViewBalance.accountBalances?.length === 0) && (
                  <div className={styles.addAccountsWrapper}>
                    <Typography.P1 className={styles.addAccounts}>
                      Please add portfolios to your {activeView?.viewName}
                    </Typography.P1>
                    <ButtonPrimary
                      text="Start setup"
                      onClick={() => {
                        dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViews));
                        dispatch(switchMyEnvironmentModalIsOpen());
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.column}>
        <Card className={styles.cardTotal}>
          <div className={styles.cardTotalLeft}>
            <Typography.P1 className={styles.cardTotalHeading}>USD total</Typography.P1>
            <Typography.P1 className={styles.cardTotalAccount}>
              {isMainScreen ? activeAccount?.accountName : activeView?.viewName}
            </Typography.P1>
          </div>
          <div className={styles.cardTotalRight}>
            <Typography.P1 className={cl('number', styles.cardTotalSum)}>
              {isMainScreen && (
                <>{accountBalanceTotal === 0 ? '---' : nf2.format(accountBalanceTotal)}</>
              )}
              {!isMainScreen && (
                <>
                  {customViewBalanceTotal === 0
                    ? '---'
                    : nf2.format(customViewBalanceTotal)}
                </>
              )}
            </Typography.P1>
            <Typography.P1 className={styles.cardTotalTimestamp}>
              {isMainScreen ? (
                <>
                  Updated {formatTime(accountBalance?.updateTimestamp, user.userTimezone)}
                </>
              ) : (
                <>
                  Updated{' '}
                  {formatTime(customViewBalance?.updateTimestamp, user.userTimezone)}
                </>
              )}
            </Typography.P1>
          </div>
        </Card>
        <Card className={styles.cardExchanges} heading="Exchanges exposure">
          <Chart
            type="exchanges exposure"
            isPending={isFetchingBalances}
            stat={
              isMainScreen ? accountBalanceExchangeExposure : customViewExchangeExposure
            }
          />
        </Card>
        <Card className={styles.cardAssetAllocation} heading="Asset allocation">
          <Chart
            type="asset allocation"
            isPending={isFetchingBalances}
            stat={
              isMainScreen ? accountBalanceAssetAllocation : customViewAssetAllocation
            }
          />
        </Card>
      </div>
    </div>
  );
};

export default DashboardDataBasic;
