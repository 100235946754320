import React from 'react';
import cl from 'clsx';
import { Typography, TypographyProps } from '../../Typography';

import styles from './Additional.module.scss';

const Additional = ({
  as = 'span',
  color = 'dark',
  align = 'left',
  weight = 400,
  isUppercase = false,
  children,
  onClick,
  className,
}: TypographyProps) => {
  return (
    <Typography
      as={as}
      color={color}
      align={align}
      weight={weight}
      isUppercase={isUppercase}
      className={cl(styles.additional, className && className)}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default Additional;
