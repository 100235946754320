import React, { useState } from 'react';
import cl from 'clsx';
import {
  Checkbox,
  LoadingIndicator,
  Table,
  Typography,
  Scrollbar,
  FiltersTopSection,
  FiltersBtmSection,
  ButtonSecondary,
} from 'src/ui-kit';
import {
  changeProViewColumnsFilter,
  deselectAllCoins,
  selectAccountProBalanceTotal,
  selectAllCoins,
  selectIsFetchingBalances,
  selectProViewBalance,
  selectProViewCoinsFilter,
  selectProViewColumnsFilter,
  selectProViewExchangesFilter,
  setColumnsToDefault,
  switchCoinFilter,
  switchExchangeFilter,
} from 'src/store/slices/accountSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { ReactComponent as FilterIcon } from 'src/assets/images/filter.svg';

import styles from './ProViewDashboard.module.scss';
import { selectIsMainView } from 'src/store/slices/activeView';
import {
  deselectAllCustomViewCoins,
  selectAllCustomViewCoins,
  selectCustomViewCoinsFilter,
  selectCustomViewExchangesFilter,
  selectCustomViewProBalance,
  selectCustomViewProBalanceTotal,
  switchCustomViewCoinFilter,
  switchCustomViewExchangeFilter,
} from 'src/store/slices/customViewsSlice';
import {
  selectIsDarkTheme,
  selectTableFiltersControls,
  switchHasHiddenFolders,
  switchHasHighlight,
  switchHasTooltip,
  switchShowCents,
} from 'src/store/slices/uiSlice';

const ProViewDashboard = () => {
  const [activeFilter, setActiveFilter] = useState<0 | 1 | 2>(0);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const dispatch = useDispatch();
  const isMainView = useAppSelector(selectIsMainView);
  const proViewBalance = useAppSelector(selectProViewBalance);
  const columns = useAppSelector(selectProViewColumnsFilter);
  const coinsFilter = useAppSelector(selectProViewCoinsFilter);
  const exchangesFilter = useAppSelector(selectProViewExchangesFilter);
  const customViewProBalance = useAppSelector(selectCustomViewProBalance);
  const customViewCoinsFilter = useAppSelector(selectCustomViewCoinsFilter);
  const customViewExchangeFilter = useAppSelector(selectCustomViewExchangesFilter);
  const tableFiltersControls = useAppSelector(selectTableFiltersControls);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const isFetchingBalances = useAppSelector(selectIsFetchingBalances);
  const accountProBalanceTotal = useAppSelector(selectAccountProBalanceTotal);
  const customViewBalanceTotal = useAppSelector(selectCustomViewProBalanceTotal);

  const getFiltersBtmItems = (filter: 0 | 1 | 2) => {
    if (filter === 0) {
      if (isMainView) {
        return coinsFilter;
      }

      return customViewCoinsFilter;
    } else if (filter === 1) {
      if (isMainView) {
        return exchangesFilter;
      }

      return customViewExchangeFilter;
    }

    return tableFiltersControls;
  };

  const getFilterBtmAction = () => {
    if (activeFilter === 0) {
      if (isMainView) {
        return (ticker: string) => {
          dispatch(switchCoinFilter(ticker));
        };
      }

      return (ticker: string) => {
        dispatch(switchCustomViewCoinFilter(ticker));
      };
    } else if (activeFilter === 1) {
      if (isMainView) {
        return (name: string) => {
          dispatch(switchExchangeFilter(name));
        };
      }

      return (name: string) => {
        dispatch(switchCustomViewExchangeFilter(name));
      };
    }

    return (name: string) => {
      if (name === 'Tooltip') {
        dispatch(switchHasTooltip());
      } else if (name === 'Show cents') {
        dispatch(switchShowCents());
      } else if (name === 'Hide empty folders') {
        dispatch(switchHasHiddenFolders());
      } else {
        dispatch(switchHasHighlight());
      }
    };
  };

  return (
    <>
      {isFetchingBalances || !proViewBalance ? (
        <div className={styles.addAccountsWrapper}>
          <LoadingIndicator />
        </div>
      ) : (
        <div className={styles.btmWrapper}>
          <div className={styles.filters}>
            <FiltersTopSection
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              coinsNumber={Object.keys(getFiltersBtmItems(0)).length}
              exchangesNumber={Object.keys(getFiltersBtmItems(1)).length}
              isMainScreen={isMainView}
              accountBalanceTotal={accountProBalanceTotal}
              customViewBalanceTotal={customViewBalanceTotal}
            />
            <FiltersBtmSection
              isCoinsFilter={activeFilter === 0}
              items={getFiltersBtmItems(activeFilter)}
              action={getFilterBtmAction()}
              selectAllCoins={
                isMainView
                  ? () => dispatch(selectAllCoins())
                  : () => dispatch(selectAllCustomViewCoins())
              }
              deselectAllCoins={
                isMainView
                  ? () => dispatch(deselectAllCoins())
                  : () => dispatch(deselectAllCustomViewCoins())
              }
            />
          </div>
          {isMainView && (
            <Scrollbar
              height="calc(100vh - 273px)"
              trackYRight={10}
              trackXBottom={-17}
              hasHorizontalScroll
              isDarkTheme={isDarkTheme}
            >
              <Table
                data={proViewBalance}
                hasBorderRadius={false}
                isFixed={true}
                isStandardTable={false}
                hasTooltip={tableFiltersControls.Tooltip}
                hasCents={tableFiltersControls['Show cents']}
              />
            </Scrollbar>
          )}
          {!isMainView && customViewProBalance && customViewProBalance.length > 0 && (
            <Scrollbar
              height="calc(100vh - 273px)"
              trackYRight={10}
              trackXBottom={-17}
              hasHorizontalScroll
              isDarkTheme={isDarkTheme}
            >
              {customViewProBalance.map((item: any, index: number) => {
                return (
                  <React.Fragment key={`${item.accountName}-${index}`}>
                    <Table
                      data={item}
                      className={styles.customViewTable}
                      hasBorderRadius={index !== 0}
                      isFixed={true}
                      isStandardTable={false}
                      title={item.accountName}
                      hasTooltip={tableFiltersControls.Tooltip}
                      hasCents={tableFiltersControls['Show cents']}
                    />
                  </React.Fragment>
                );
              })}
            </Scrollbar>
          )}
          <div className={styles.columnFilter}>
            <div
              className={cl(
                styles.columnCheckboxes,
                isOpenFilter && styles.columnCheckboxesOpen
              )}
            >
              <Scrollbar
                height="calc(100vh - 273px)"
                trackYRight={-10}
                trackYTop={10}
                hasHorizontalScroll
                isDarkTheme={isDarkTheme}
              >
                <div className={styles.checkboxesWrapper}>
                  {columns.map((column: any) => {
                    if (column.isAttached) {
                      return null;
                    }

                    return (
                      <Checkbox
                        name={column.key}
                        label={column.name}
                        checked={column.isChecked}
                        onChange={(name: string, value: boolean) => {
                          dispatch(
                            changeProViewColumnsFilter({
                              name: name,
                              isChecked: value,
                            })
                          );
                        }}
                        key={`${column.name}-${column.key}`}
                      />
                    );
                  })}
                </div>
                <ButtonSecondary
                  type="small"
                  text="Reset to default state"
                  isFullWidth
                  className={styles.defaultsBtn}
                  onClick={() => {
                    dispatch(setColumnsToDefault());
                  }}
                />
              </Scrollbar>
            </div>
            <div
              className={cl(styles.columnBar, isOpenFilter && styles.isEnabledColumnBar)}
              onClick={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
            >
              <div className={styles.columnBarContent}>
                <Typography.Additional className={styles.columnBarContentText}>
                  Columns
                </Typography.Additional>
                <FilterIcon className={styles.columnBarContentIcon} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProViewDashboard;
