import { CustomInstrument } from 'src/types/customInstrument';
import { instance } from './mainInstance';

export type GetAllCustomInstrumentsResponse = {
  data: CustomInstrument[];
};

export const CustomInstrumentService = {
  getMeta: async () => {
    return await instance.get(`custom_instrument/meta`);
  },
  getTemplate: async (body: any) => {
    return await instance.post(`custom_instrument/template`, body);
  },
  getAll: async () => {
    return await instance.get(`custom_instrument/all`);
  },
  createCustomInstrument: async (body: any) => {
    return await instance.post('custom_instrument', body);
  },
  patch: async (id: string, body: any) => {
    return await instance.patch(`custom_instrument/${id}`, body);
  },
  delete: async (id: string) => {
    return await instance.delete(`custom_instrument/${id}`);
  },
};
