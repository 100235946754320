import cl from 'clsx';
import { Avatar, ButtonHeader, CoinRate, ThemeSwitcher } from 'src/ui-kit';
import { Price } from 'src/types/price';
import { ReactComponent as SettingsIcon } from 'src/assets/images/settings_2.svg';
import { ReactComponent as NotepadIcon } from 'src/assets/images/notepad.svg';

import styles from './HeaderPlate.module.scss';

type HeaderPlateProps = {
  exchangeRates: Price[];
  className?: string;
  firstName: string;
  lastName: string;
  userPicture: 0 | 1 | 2 | 3 | 4;
  isDarkTheme: boolean;
  switchTheme: () => void;
  openModal: () => void;
  openUserProfileModal: () => void;
  openNotepadModal: () => void;
};

const HeaderPlate = ({
  exchangeRates = [],
  firstName,
  lastName,
  userPicture,
  isDarkTheme,
  className,
  openModal,
  switchTheme,
  openUserProfileModal,
  openNotepadModal,
}: HeaderPlateProps) => {
  return (
    <header className={cl(styles.headerPlate, className && className)}>
      <div className={styles.coinRates}>
        {exchangeRates.map(({ currency, usdPrice, changePercent }) => {
          if (currency.ticker === ('USDT' || 'USDC')) {
            return null;
          }

          return (
            <CoinRate
              currency={currency.ticker}
              id={currency.id}
              price={usdPrice}
              change={changePercent}
              key={currency.id}
            />
          );
        })}
      </div>
      <div className={styles.btns}>
        <ButtonHeader icon={<SettingsIcon />} onClick={openModal} />
        <ButtonHeader
          icon={<NotepadIcon />}
          className={styles.notepadButton}
          onClick={openNotepadModal}
        />
        <button onClick={openUserProfileModal} className={styles.userProfileButton}>
          <Avatar
            size="small"
            variant={userPicture}
            firstName={firstName}
            lastName={lastName}
          />
        </button>
        <ThemeSwitcher
          isDarkTheme={isDarkTheme}
          onClick={switchTheme}
          className={styles.themeSwitcher}
        />
      </div>
    </header>
  );
};

export default HeaderPlate;
