import { useState } from 'react';
import cl from 'clsx';
import {
  ButtonPrimary,
  ButtonSecondary,
  Card,
  Kebab,
  TextInput,
  TextInputSize,
  Typography,
} from 'src/ui-kit';
import { ExchangeService } from 'src/services/Exchange.service';
import { useAppDispatch } from 'src/hooks/useAppDispatch';

import styles from './ExchangesScreenItem.module.scss';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectExchangePictureLinkByExchangeType } from 'src/store/slices/exchangeSlice';
import { ReactComponent as ArrowIcon } from 'src/assets/images/custom-view-open.svg';
import {
  ModalScreen,
  selectIsProView,
  setMyEnvironmentModalScreen,
} from 'src/store/slices/uiSlice';
import { selectActiveViewId, selectIsMainView } from 'src/store/slices/activeView';
import {
  fetchAccountBalance,
  fetchAllAccounts,
  fetchCustomViewBalance,
} from 'src/thunks';
import { selectActiveAccountId } from 'src/store/slices/accountSlice';
import { ReactComponent as TickIcon } from 'src/assets/images/buttonTick.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/buttonCross.svg';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/renameSpinner.svg';
import {
  resetPositionsList,
  setCustomPositionsSelectedProvider,
} from 'src/store/slices/positionsSlice';
import { CustomPosition } from 'src/types/customPosition';

type ExchangesScreenItemProps = {
  positions?: CustomPosition[];
  id: number;
  name: string;
  type: string;
  accountId: number;
  index: number;
  onDelete: (account: any) => void;
};

const ExchangesScreenItem = ({
  positions = [],
  id,
  name,
  type,
  accountId,
  index,
  onDelete,
}: ExchangesScreenItemProps) => {
  const dispatch = useAppDispatch();
  const pictureLink = useAppSelector(selectExchangePictureLinkByExchangeType(type));

  const [inputText, setInputText] = useState<string>(name);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSubmittingRename, setIsSubmittingRename] = useState<boolean>(false);

  const isProView = useAppSelector(selectIsProView);
  const isMainView = useAppSelector(selectIsMainView);
  const activeViewId = useAppSelector(selectActiveViewId);
  const activeAccountId = useAppSelector(selectActiveAccountId);

  const handleRenameBtnClick = () => {
    setIsEditMode(true);
  };

  const handleRename = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditMode(false);
  };

  const handleRenameSubmit = async () => {
    setIsSubmittingRename(true);

    await ExchangeService.renameExchange(id, {
      accountId,
      exchangeType: type,
      userDefinedName: inputText,
    });

    await fetchAllAccounts(dispatch, isMainView);

    if (isMainView) {
      await fetchAccountBalance(dispatch, activeAccountId, isProView);
    } else {
      await fetchCustomViewBalance(dispatch, activeViewId, isProView);
    }

    setIsEditMode(false);
    setIsSubmittingRename(false);
  };

  const handleManagePositions = async () => {
    await dispatch(
      setCustomPositionsSelectedProvider({
        id,
        name,
      })
    );
    await dispatch(resetPositionsList());
    await dispatch(setMyEnvironmentModalScreen(ModalScreen.Positions));
  };

  let kebabItems = [
    { title: 'Rename', onClick: handleRenameBtnClick },
    {
      title: 'Delete',
      onClick: () => {
        onDelete({ id, name });
      },
    },
  ];

  return (
    <Card className={styles.card}>
      <div className={styles.cardNameLine}>
        {!isEditMode && (
          <div className={styles.customViewName}>
            <img src={pictureLink} alt={`${type} icon`} className={styles.icon} />
            <div>
              <Typography.P1 className={styles.cardName}>{name}</Typography.P1>
              <Typography.P1 color="secondary" className={styles.cardType}>
                {type}
              </Typography.P1>
            </div>
          </div>
        )}
        {isEditMode && (
          <div className={styles.renameWrapper}>
            <TextInput
              value={inputText}
              size={TextInputSize.Small}
              autoFocus
              disabled={isSubmittingRename}
              onChange={handleRename}
              className={styles.input}
            />
            <div className={styles.renameBtnWrapper}>
              {isSubmittingRename ? (
                <SpinnerIcon className={styles.spinner} />
              ) : (
                <>
                  <ButtonSecondary
                    text=""
                    onClick={handleBlur}
                    className={styles.renameBtn}
                  >
                    <CrossIcon className={styles.crossIcon} />
                  </ButtonSecondary>
                  <ButtonPrimary
                    text=""
                    size="small"
                    icon={<TickIcon />}
                    onClick={handleRenameSubmit}
                    className={styles.renameBtn}
                  />
                </>
              )}
            </div>
          </div>
        )}
        <Kebab
          items={kebabItems}
          menuPosition={(index + 1) % 3 === 0 ? 'right' : 'left'}
        />
      </div>
      <div>
        <div className={styles.bottomLine}>
          {type === 'CUSTOM' && (
            <>
              <div>
                <div className={styles.statusWrapper}>
                  <div className={styles.status}>
                    {/* {positions.length === 0 ? (
                      <Typography.P1 as="span" className={styles.statusText}>
                        No custom positions connected
                      </Typography.P1>
                    ) : (
                      <>
                        <div className={cl(styles.statusColor, styles.statusGreen)}>
                          <Typography.P1 as="span" className={styles.statusText}>
                            {positions.length}
                          </Typography.P1>
                        </div>
                        <Typography.P1 as="span" className={styles.statusText}>
                          {positions.length === 1
                            ? 'Custom position'
                            : 'Custom positions'}{' '}
                          connected
                        </Typography.P1>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
              <div>
                <button className={styles.manage} onClick={handleManagePositions}>
                  <Typography.Small>Manage</Typography.Small>
                  <ArrowIcon />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ExchangesScreenItem;
