import React from 'react';
import cl from 'clsx';
import { TableCell } from '../TableCell';

import styles from './TableRow.module.scss';

type TableRowProps = {
  level: number;
  id: string;
  parentId?: string;
  type: 'balance' | 'balance_section' | 'portfolio' | 'exchange';
  isVisible: boolean;
  isOpen: boolean;
  cells: TTableCell[];
  index: number;
  isStandardTable: boolean;
  hasTooltip: boolean;
  hasCents: boolean;
  isBold: boolean;
  onClick: (id: string) => () => void;
  className?: string;
};

type TTableCell = {
  text: string;
  platform?: string;
  pictureLink?: string;
  isColorized: boolean;
  fullNumber?: number;
  isWide: boolean;
  fiat: boolean;
  percent?: boolean;
  isHighlighted?: boolean;
  initialMargin?: number;
  maintenanceMargin?: number;
};

const TableRow = ({
  level,
  id,
  parentId,
  isVisible,
  isOpen,
  type,
  cells,
  index,
  isStandardTable,
  hasTooltip,
  hasCents,
  isBold = false,
  onClick,
  className,
}: TableRowProps) => {
  return (
    <div
      onClick={!id.includes('empty') ? onClick(id) : undefined}
      className={cl(
        styles.tableRow,
        !isVisible && styles.isCollapsed,
        type === 'balance' && styles.defaultCursor,
        type === 'exchange' && styles.withSpacing,
        !isStandardTable && styles.fitContent,
        id.includes('empty') && styles.empty,
        className && className
      )}
    >
      {cells.map((cell, cellIndex) => {
        return (
          <TableCell
            level={level}
            text={cell.text}
            platform={cell.platform}
            pictureLink={cell.pictureLink}
            isColorized={cell.isColorized}
            type={type}
            key={`${cell.text}-${cellIndex}`}
            hasSpacing={type === 'exchange' && index !== 0}
            isNameCell={cellIndex === 0}
            isStandardTable={isStandardTable}
            isEmpty={id.includes('empty')}
            isWide={cell.isWide}
            fullNumber={cell.fullNumber}
            hasTooltip={hasTooltip}
            hasCents={hasCents}
            fiat={cell.fiat}
            isBold={isBold}
            isOpen={isOpen}
            percent={cell.percent}
            highlighted={cell.isHighlighted}
            initialMargin={cell.initialMargin}
            maintenanceMargin={cell.maintenanceMargin}
          />
        );
      })}
    </div>
  );
};

export default TableRow;
