import { useEffect, useState } from 'react';
import { Sidebar, Main } from 'src/components';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';
import { Authorization } from 'src/components';
import { Modals } from 'src/components';
import { MobileWarning } from 'src/ui-kit';

const ContentWrapper = () => {
  const [isMobileViewAccepted, setIsMobileViewAccepted] = useState(
    localStorage.getItem('mobile_view_accepted') === '1'
  );
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  useEffect(() => {
    const root = document.documentElement;

    if (isDarkTheme) {
      document.body.classList.add('dark');

      root.style.setProperty('--dark-purple', '#443DFF');
      root.style.setProperty('--middle-purple', '#6964FF');
      root.style.setProperty('--purple', '#3E3C74');
      root.style.setProperty('--light-purple', '#7297FF');
      root.style.setProperty('--blue-text', '#7d90c4');
      root.style.setProperty('--light-blue-text', '#abb8db');
      root.style.setProperty('--exchange-row', '#2F2F33');
      root.style.setProperty('--portfolio-row', '#2B2B2E');
      root.style.setProperty('--orange', '#ff9344');
      root.style.setProperty('--light-orange', '#1F1E1D');
      root.style.setProperty('--red', '#f00');
      root.style.setProperty('--middle-red', '#803333');
      root.style.setProperty('--light-red', '#261F1F');
      root.style.setProperty('--green', '#00b57e');
      root.style.setProperty('--light-green', '#414D49');
      root.style.setProperty('--black', '#000');
      root.style.setProperty('--dark-text', '#363853');
      root.style.setProperty('--grey', '#4b4b4b');
      root.style.setProperty('--secondary-text', '#757680');
      root.style.setProperty('--white', '#fff');
    } else {
      document.body.classList.remove('dark');

      root.style.setProperty('--dark-purple', '#0600b2');
      root.style.setProperty('--middle-purple', '#2219ff');
      root.style.setProperty('--purple', '#443dff');
      root.style.setProperty('--light-purple', '#7297ff');
      root.style.setProperty('--blue-text', '#7d90c4');
      root.style.setProperty('--light-blue-text', '#abb8db');
      root.style.setProperty('--exchange-row', '#e7edff');
      root.style.setProperty('--portfolio-row', '#f6f8ff');
      root.style.setProperty('--orange', '#ff9344');
      root.style.setProperty('--light-orange', '#faf6ee');
      root.style.setProperty('--red', '#f00');
      root.style.setProperty('--middle-red', '#ff9c9c');
      root.style.setProperty('--light-red', '#fefafa');
      root.style.setProperty('--green', '#00b57e');
      root.style.setProperty('--light-green', '#ccf0e5');
      root.style.setProperty('--black', '#000');
      root.style.setProperty('--dark-text', '#363853');
      root.style.setProperty('--grey', '#4b4b4b');
      root.style.setProperty('--secondary-text', '#9b9ca9');
      root.style.setProperty('--white', '#fff');
    }
  }, [isDarkTheme]);

  if (window.innerWidth <= 760 && !isMobileViewAccepted) {
    return (
      <MobileWarning
        onAccept={() => {
          localStorage.setItem('mobile_view_accepted', '1');
          setIsMobileViewAccepted(true);
        }}
      />
    );
  }

  if (!localStorage.getItem('access_token')) {
    return <Authorization />;
  }

  return (
    <>
      <Sidebar />
      <Main />
      <Modals />
    </>
  );
};

export default ContentWrapper;
