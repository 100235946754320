import React from 'react';
import cl from 'clsx';
import { Typography } from '../Typography';

import styles from './ButtonSecondary.module.scss';

export type ButtonSecondaryProps = {
  text?: string;
  type?: 'small' | 'medium' | 'large';
  isFullWidth?: boolean;
  isDisabled?: boolean;
  className?: string;
  textClassName?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick: () => void;
};

const ButtonSecondary = ({
  text,
  type = 'medium',
  isFullWidth,
  isDisabled,
  className,
  textClassName,
  children,
  onClick,
}: ButtonSecondaryProps) => {
  return (
    <button
      className={cl(
        styles.buttonSecondary,
        styles[type],
        isFullWidth && styles.fullWidth,
        isDisabled && styles.disabled,
        className && className
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      {text && text.length > 0 && (
        <Typography.P1
          as="span"
          weight={500}
          className={cl(styles.text, textClassName && textClassName)}
        >
          {text}
        </Typography.P1>
      )}
      {children}
    </button>
  );
};

export default ButtonSecondary;
