import React from 'react';
import cl from 'clsx';
import { Additional, H1, H2, H3, P1, Small } from './components';

import styles from './Typography.module.scss';

export type TypographyProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  color?: 'dark' | 'secondary' | 'white' | 'purple' | 'black';
  align?: 'center' | 'left' | 'right';
  weight?: 400 | 500 | 600 | 700;
  isUppercase?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

export const Typography: React.FunctionComponent<TypographyProps> = ({
  as = 'p',
  color = 'dark',
  align = 'left',
  weight = 400,
  isUppercase = false,
  children,
  className,
  onClick,
}) =>
  React.createElement(
    as,
    {
      className: cl(
        styles.typography,
        styles[color],
        styles[align],
        styles[`w${weight}`],
        isUppercase && styles.isUppercase,
        className && className
      ),
      onClick,
    },
    children
  );

const variants = {
  H1: H1,
  H2: H2,
  H3: H3,
  P1: P1,
  Additional: Additional,
  Small: Small,
};

export default variants;
