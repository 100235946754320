import React from 'react';
import { Scrollbar } from 'src/ui-kit';

import styles from './MyEnvironmentCardsList.module.scss';

type MyEnvironmentCardsListProps = {
  isDarkTheme: boolean;
  children: React.ReactNode;
};

const MyEnvironmentCardsList = ({
  isDarkTheme,
  children,
}: MyEnvironmentCardsListProps) => {
  return (
    <Scrollbar height="530px" trackYRight={10} isDarkTheme={isDarkTheme}>
      <div className={styles.myEnvironmentCards}>{children}</div>
    </Scrollbar>
  );
};

export default MyEnvironmentCardsList;
