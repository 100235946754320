import React from 'react';
import cl from 'clsx';
import { Typography, TypographyProps } from '../../Typography';

import styles from './H3.module.scss';

const H3 = ({
  as = 'h3',
  color = 'dark',
  align = 'left',
  weight = 500,
  isUppercase = false,
  children,
  className,
}: TypographyProps) => {
  return (
    <Typography
      as={as}
      color={color}
      align={align}
      weight={weight}
      isUppercase={isUppercase}
      className={cl(styles.h3, className && className)}
    >
      {children}
    </Typography>
  );
};

export default H3;
