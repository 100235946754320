import { CustomPosition } from 'src/types/customPosition';
import { instance } from 'src/services/mainInstance';
import { CustomPositionsMeta } from 'src/store/slices/positionsSlice';

export type CreateCustomPositionPayload = Omit<
  CustomPosition,
  'id' | 'customInstrument' | 'tradeDirection' | 'tradeDate' | 'creationDate'
> & {
  portfolioId: number;
  customInstrumentId: string;
  tradeDirection: string;
  amount: number;
  price: number;
  tradeTimestamp: number;
};

export type GetCustomPositionMetaResponse = {
  data: { instrumentTypeDirectionsMap: CustomPositionsMeta };
};

export type GetCustomPositionResponse = {
  data: CustomPosition[];
};

export const CustomPositionService = {
  createCustomPosition: async (body: CreateCustomPositionPayload) => {
    return await instance.post(`custom_position`, body);
  },
  getCustomPositions: async (id: number) => {
    return await instance.get(`custom_position/exchange/${id}`);
  },
  getMeta: async () => {
    return await instance.get(`custom_position/meta`);
  },
  deleteCustomPosition: async (id: number) => {
    return await instance.delete(`custom_position/${id}`);
  },
  patch: async (id: string, body: any) => {
    return await instance.patch(`custom_position/${id}`, body);
  },
};
