import React, { useState } from 'react';
import cl from 'clsx';
import { ReactComponent as CustomViewIcon } from 'src/assets/images/custom-view.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/images/custom-view-open.svg';
import styles from './CustomViewsButton.module.scss';
import { Typography } from '../Typography';

export type CustomViewsButtonProps = {
  isCollapsed: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
};

const CustomViewsButton = ({
  isCollapsed,
  isDisabled,
  children,
  className,
}: CustomViewsButtonProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleButtonClick = () => setIsExpanded(!isExpanded);

  return (
    <>
      <button
        className={cl(
          styles.customViewsButton,
          isCollapsed && styles.isCollapsed,
          isDisabled && styles.isDisabled,
          className && className
        )}
        disabled={isDisabled}
        onClick={handleButtonClick}
      >
        <CustomViewIcon className={styles.customViewIcon} />
        <Typography.P1 as="span" className={styles.text}>
          Custom Views
        </Typography.P1>
        {!isDisabled && (
          <ArrowIcon className={cl(styles.arrow, isExpanded && styles.rotatedArrow)} />
        )}
      </button>
      <div
        className={cl(
          styles.childrenWrapper,
          (isExpanded || isCollapsed) && styles.isExpandedWrapper
        )}
      >
        {children}
      </div>
    </>
  );
};

export default CustomViewsButton;
