import React, { useEffect, useState } from 'react';
import cl from 'clsx';
import { Typography } from 'src/ui-kit';
import { ReactComponent as CloseIcon } from 'src/assets/images/close.svg';
import styles from './Modal.module.scss';

export type ModalProps = {
  isOpen: boolean;
  customHeader?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
};

const toggleBodyLimit = (body: HTMLBodyElement, limit: boolean) => {
  if (limit) {
    body.classList.add(styles.noScroll);
  } else {
    body.classList.remove(styles.noScroll);
  }
};

const Modal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  customHeader,
  title,
  children,
  onClose,
  className,
}) => {
  const [internalOpenFlag, setInternalOpenFlag] = useState(false);
  const body = document.getElementsByTagName('body')[0];

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        toggleBodyLimit(body, true);
        setInternalOpenFlag(true);
      }, 100);
    } else {
      setTimeout(() => {
        toggleBodyLimit(body, false);
        setInternalOpenFlag(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      toggleBodyLimit(body, false);
    };
  }, []);

  if (isOpen || (!isOpen && internalOpenFlag)) {
    return (
      <div className={cl(styles.backdrop, isOpen && internalOpenFlag && styles.open)}>
        <div className={cl(styles.modal, className && className)}>
          {customHeader ? (
            customHeader
          ) : (
            <div className={styles.modalHeader}>
              <Typography.H2 className={styles.modalTitle}>{title}</Typography.H2>
              <button onClick={onClose} className={styles.close}>
                <CloseIcon className={styles.closeIcon} />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
