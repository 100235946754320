import { GenericAbortSignal } from 'axios';
import { instance } from './mainInstance';
import { AccountBalance } from 'src/types/balance';

export interface AddAccountPropTypes {
  accountName: string;
}

export type FetchBalanceByIdResponse = {
  data: AccountBalance;
};

export const AccountService = {
  getAccount: async (id: number) => {
    return await instance.get(`account/${id}`);
  },
  accountBalance: async (signal?: GenericAbortSignal) => {
    return await instance.get(`account/balance`, {
      signal,
    });
  },
  updateAccount: async (id: number, body: AddAccountPropTypes) => {
    return await instance.put(`account/${id}`, body);
  },
  deleteAccount: async (id: number) => {
    return await instance.delete(`account/${id}`);
  },
  createAccount: async (body: AddAccountPropTypes) => {
    return await instance.post(`account`, body);
  },
  allAccounts: async () => {
    return await instance.get(`account/all`);
  },
  withExchanges: async () => {
    return await instance.get(`account/all/withExchanges`);
  },
  renameView: async (id: number, body: AddAccountPropTypes) => {
    return await instance.put(`account/${id}`, {
      ...body,
    });
  },
  getProViewBalance: async (signal?: GenericAbortSignal) => {
    return await instance.get(`account/balance/pro`, {
      signal,
    });
  },
  getBalanceById: async (accountId: number, signal?: GenericAbortSignal) => {
    return await instance.get(`account/balance/${accountId}`, {
      signal,
    });
  },
  getProViewBalanceById: async (accountId: number, signal?: GenericAbortSignal) => {
    return await instance.get(`account/balance/pro/${accountId}`, {
      signal,
    });
  },
};
