import React, { useState } from 'react';
import RSC from 'react-scrollbars-custom';

type ScrollbarProps = {
  width?: string;
  height: string;
  trackYRight?: number;
  trackYTop?: number;
  trackYHeight?: string | number;
  trackXBottom?: number;
  hasHorizontalScroll?: boolean;
  isDarkTheme: boolean;
  children: React.ReactNode;
};

const Scrollbar = ({
  width,
  height,
  trackYRight = -12,
  trackYTop,
  trackYHeight,
  trackXBottom = 10,
  hasHorizontalScroll = false,
  isDarkTheme,
  children,
}: ScrollbarProps) => {
  const [isVisibleScrollbar, setIsVisibleScrollbar] = useState(false);

  const handleScrollStart = () => {
    setIsVisibleScrollbar(true);
  };

  const handleScrollStop = () => {
    setIsVisibleScrollbar(false);
  };

  return (
    <RSC
      noDefaultStyles
      scrollDetectionThreshold={300}
      style={{ width, height }}
      wrapperProps={{
        style: { inset: '0px 1px 0px 0px' },
      }}
      trackYProps={{
        style: {
          width: 8,
          height: trackYHeight
            ? trackYHeight
            : hasHorizontalScroll
            ? 'calc(100% - 48px)'
            : '100%',
          borderRadius: 10,
          position: 'absolute',
          top: trackYTop ? trackYTop : hasHorizontalScroll ? 41 : 0,
          right: trackYRight,
          backgroundColor: isDarkTheme ? 'var(--accent)' : 'var(--light-accent)',
          opacity: isVisibleScrollbar ? '.4' : '0',
          transition: '.1s opacity',
          zIndex: 20,
        },
      }}
      trackXProps={
        hasHorizontalScroll
          ? {
              style: {
                width: 'calc(100% - 10px)',
                height: 8,
                borderRadius: 10,
                position: 'absolute',
                bottom: trackXBottom,
                left: 0,
                backgroundColor: isDarkTheme ? 'var(--accent)' : 'var(--light-accent)',
                opacity: 1,
                transition: '.1s opacity',
                zIndex: 20,
              },
            }
          : undefined
      }
      thumbYProps={{
        style: {
          backgroundColor: isDarkTheme ? 'var(--purple)' : 'var(--light-purple)',
          borderRadius: 10,
        },
      }}
      thumbXProps={
        hasHorizontalScroll
          ? {
              style: {
                height: 8,
                backgroundColor: isDarkTheme ? 'var(--purple)' : 'var(--light-purple)',
                borderRadius: 10,
              },
            }
          : undefined
      }
      contentProps={{
        style: {
          padding: 0,
          paddingRight: 1,
        },
      }}
      scrollerProps={{
        style: {
          marginRight: 5,
        },
      }}
      onScrollStart={handleScrollStart}
      onScrollStop={handleScrollStop}
    >
      {children}
    </RSC>
  );
};

export default Scrollbar;
