import { createSlice } from '@reduxjs/toolkit';
import { Exchange, ExchangeMeta } from 'src/types/exchange';
import type { RootState } from '../store';

interface ModalState {
  meta: ExchangeMeta[];
}

const initialState: ModalState = {
  meta: [],
};

export const accountSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setExchangesMeta: (state, action) => {
      state.meta = action.payload;
    },
  },
});

export const { setExchangesMeta } = accountSlice.actions;

export const selectExchangesMeta = (state: RootState) => state.exchange.meta;
export const selectExchangesMetaByExchangeType =
  (exchangeType: string | null) => (state: RootState) => {
    if (exchangeType) {
      return state.exchange.meta.find((metaItem) => {
        return metaItem.exchangeType === exchangeType;
      });
    }
  };
export const selectExchangePictureLinkByExchangeType =
  (exchangeType: string) => (state: RootState) => {
    const exchangeMeta = state.exchange.meta.find((metaItem) => {
      return metaItem.exchangeType === exchangeType;
    });

    if (exchangeMeta) {
      return exchangeMeta.pictureLink;
    }

    return '';
  };
export const selectAllExchangesPictureLinks = (state: RootState) => {
  return state.exchange.meta.reduce(
    (accumulator: Record<string, string>, currentValue: ExchangeMeta) => {
      accumulator[currentValue.exchangeType] = currentValue.pictureLink;
      return accumulator;
    },
    {}
  );
};

export default accountSlice.reducer;
