import React, { useEffect, useState } from 'react';
import styles from './ModalNotepad.module.scss';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectIsDarkTheme,
  selectIsOpenNotepadModal,
  switchNotepadModalIsOpen,
} from 'src/store/slices/uiSlice';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  ButtonPrimary,
  ButtonSecondary,
  Kebab,
  Modal,
  MyEnvironmentCardsHeader,
  Scrollbar,
  TextArea,
  Typography,
} from 'src/ui-kit';
import { formatTimeWithoutSeconds, logError } from 'src/utils';
import { NoteService } from 'src/services/Note.service';

const ModalNotepad = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsOpenNotepadModal);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const [isNewNoteMode, setIsNewMode] = useState(false);
  const [isEditNoteMode, setIsEditNoteMode] = useState(false);
  const [notesList, setNotesList] = useState<any>([]);
  const [note, setNote] = useState('');
  const [editNote, setEditNote] = useState<any>(null);
  const [noteForDelete, setNoteForDelete] = useState<any>(null);

  const getList = async () => {
    try {
      const result = await NoteService.getList();
      setNotesList(result.data);
    } catch (err) {
      logError(err);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleClose = () => {
    dispatch(switchNotepadModalIsOpen());
  };

  const handleButtonClick = () => {
    setIsNewMode(true);
  };

  useEffect(() => {
    if (!isNewNoteMode && !isEditNoteMode) {
      getList();
      setNote('');
    }
  }, [isNewNoteMode, isEditNoteMode]);

  const submitNewNote = async () => {
    try {
      if (isNewNoteMode) {
        await NoteService.newNote({ userNoteData: note });
      } else {
        await NoteService.update(editNote.id, { userNoteData: note });
      }

      setIsNewMode(false);
      setIsEditNoteMode(false);
      setEditNote(false);
    } catch (err) {
      logError(err);
    }
  };

  const handleNoteDelete = async () => {
    try {
      await NoteService.delete(noteForDelete.id);
      getList();
      setNoteForDelete(null);
    } catch (err) {
      logError(err);
    }
  };

  return (
    <Modal
      title="Notepad"
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.modalNotepad}
    >
      {isNewNoteMode || isEditNoteMode ? (
        <div className={styles.editWrapper}>
          <div className={styles.inputsWrapper}>
            {/* <TextInput
              placeholder="Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            /> */}
            <TextArea
              placeholder="Note"
              value={note}
              inputClassName={styles.textarea}
              autoFocus
              onChange={(event) => {
                if (event.target.value.length <= 2047) {
                  setNote(event.target.value);
                }
              }}
            />
            <Typography.P1 align="right">{note.length}/2047</Typography.P1>
          </div>
          <div className={styles.buttons}>
            <ButtonSecondary
              text="Cancel"
              onClick={() => {
                setIsNewMode(false);
                setIsEditNoteMode(false);
              }}
              className={styles.actionButton}
            />
            <ButtonPrimary
              isFullWidth
              text="Save"
              isDisabled={!note}
              onClick={submitNewNote}
              className={styles.actionButton}
            />
          </div>
        </div>
      ) : (
        <>
          <MyEnvironmentCardsHeader
            headingText="My notes"
            descriptionText="Use this space to take notes"
            buttonText="New note"
            buttonAction={handleButtonClick}
          />
          {notesList.length > 0 && (
            <Scrollbar
              height="calc(100vh - 454px)"
              trackYRight={8}
              trackYHeight="calc(100% - 10px)"
              isDarkTheme={isDarkTheme}
            >
              <div className={styles.tableWrapper}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <td>Creation date</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {notesList.map((noteItem: any, index: number) => {
                      return (
                        <tr key={noteItem.id}>
                          <td>
                            {formatTimeWithoutSeconds(noteItem.creationDate)},&nbsp;
                            {noteItem.noteData.slice(0, 5)}...
                          </td>
                          <td>
                            <Kebab
                              items={[
                                {
                                  title: 'Edit',
                                  onClick: async () => {
                                    try {
                                      setEditNote(noteItem);
                                      setNote(noteItem.noteData);
                                      setIsEditNoteMode(true);
                                    } catch (err) {
                                      logError(err);
                                    }
                                  },
                                },
                                {
                                  title: 'Delete',
                                  onClick: () => {
                                    setNoteForDelete(noteItem);
                                  },
                                },
                              ]}
                              menuPosition={
                                notesList.length > 13 && index > notesList.length - 3
                                  ? 'right-bottom'
                                  : 'right'
                              }
                              className={styles.kebab}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Scrollbar>
          )}
        </>
      )}
      {noteForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete this note?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  text="Yes"
                  type="small"
                  onClick={handleNoteDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  text="No"
                  size="small"
                  onClick={() => {
                    setNoteForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalNotepad;
