import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CustomInstrumentService,
  GetAllCustomInstrumentsResponse,
} from 'src/services/CustomInstrument.service';
import {
  fetchCustomInstrumentList,
  setCustomInstrumentList,
} from 'src/store/slices/customInstrumentSlice';
import { logError } from 'src/utils';

function* fetchCustomInstrumentListAsync(): Generator {
  try {
    const response = yield call(CustomInstrumentService.getAll);
    yield put(
      setCustomInstrumentList((response as GetAllCustomInstrumentsResponse).data)
    );
  } catch (error) {
    logError(error);
  }
}

function* watcher() {
  yield takeLatest(fetchCustomInstrumentList, fetchCustomInstrumentListAsync);
}

export default watcher;
