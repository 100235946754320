import { useEffect, useRef } from 'react';

export const useDidMount = (callback: () => void, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, deps);
};
