import { useEffect, useState } from 'react';
import cl from 'clsx';
import { ReactComponent as SunIcon } from '../../assets/images/sun.svg';
import { ReactComponent as MoonIcon } from '../../assets/images/moon.svg';

import styles from './ThemeSwitcher.module.scss';

type ThemeSwitcherProps = {
  isDarkTheme: boolean;
  className?: string;
  onClick: () => void;
};

const ThemeSwitcher = ({ isDarkTheme, className, onClick }: ThemeSwitcherProps) => {
  const [isAnimated, setIsAnimated] = useState(false);

  return (
    <button
      className={cl(
        styles.themeSwitcher,
        isDarkTheme && styles.darkTheme,
        isAnimated && styles.animated,
        className && className
      )}
      onClick={() => {
        setIsAnimated(true);
        onClick();
      }}
    >
      <span
        className={cl(styles.iconWrapper, styles.moonWrapper)}
        onAnimationEnd={() => {
          setIsAnimated(false);
        }}
      >
        <MoonIcon className={styles.moon} />
      </span>
      <span className={cl(styles.iconWrapper, styles.sunWrapper)}>
        <SunIcon className={styles.sun} />
      </span>
    </button>
  );
};

export default ThemeSwitcher;
