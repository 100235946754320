import React from 'react';
import cl from 'clsx';
import { ReactComponent as NecklineIcon } from 'src/assets/images/necknine.svg';

import styles from './CollapseSidebarButton.module.scss';

export type CollapseSidebarButtonProps = {
  isCollapsed: boolean;
  className?: string;
  onClick: () => void;
};

const CollapseSidebarButton = ({
  isCollapsed,
  className,
  onClick,
}: CollapseSidebarButtonProps) => (
  <button
    className={cl(
      styles.collapseSidebarButton,
      isCollapsed && styles.isCollapsed,
      className && className
    )}
    onClick={onClick}
  >
    <NecklineIcon className={styles.neckline} />
    <div className={styles.circle} />
  </button>
);

export default CollapseSidebarButton;
