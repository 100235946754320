import { instance } from './mainInstance';

export interface ViewChartsResponse {
  viewId: number;
  viewName: string;
  exchangeExposure: [
    {
      exchangeType: string;
      percentOfTotal: number;
    }
  ];
  assetAllocation: [
    {
      currencyTicker: string;
      percentOfTotal: number;
    }
  ];
}

export interface AllChartsResponse {
  accountId: number;
  accountName: string;
  exchangeExposure: [
    {
      exchangeType: string;
      percentOfTotal: number;
    }
  ];
  assetAllocation: [
    {
      currencyTicker: string;
      percentOfTotal: number;
    }
  ];
}

export const StateService = {
  all: async () => {
    return await instance.get(`statistic/all`);
  },
  view: async (id: number) => {
    return await instance.get(`statistic/view/${id}`);
  },
  reportAccount: async (id: number) => {
    return await instance.get(`statistic/report/account/${id}`, {
      responseType: 'blob',
    });
  },
  reportView: async (id: number) => {
    return await instance.get(`statistic/report/view/${id}`, {
      responseType: 'blob',
    });
  },
  riskReportAccount: async (id: number) => {
    return await instance.get(`statistic/report/risk/account/${id}`, {
      responseType: 'blob',
    });
  },
  riskReportView: async (id: number) => {
    return await instance.get(`statistic/report/risk/view/${id}`, {
      responseType: 'blob',
    });
  },
};
