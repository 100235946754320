import {
  Checkbox,
  Hint,
  MyEnvironmentCardsHeader,
  Scrollbar,
  TextInput,
  TextInputSize,
  Typography,
} from 'src/ui-kit';
import cl from 'clsx';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { ModalScreen } from '../../ModalMyEnvironment';

import styles from './CustomViewScreenEdit.module.scss';
import { useEffect, useState } from 'react';
import { logError } from 'src/utils';
import { CustomViewService } from 'src/services/CustomView.service';
import { AccountService } from 'src/services/Account.service';
import { truncate } from 'src/utils';
import {
  selectCustomViewById,
  setCustomViewBalance,
  setCustomViewList,
  setCustomViewProBalance,
  updateCustomViewFilters,
} from 'src/store/slices/customViewsSlice';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { selectActiveViewId } from 'src/store/slices/activeView';
import { CustomView } from 'src/types/customView';
import {
  selectIsDarkTheme,
  selectIsProView,
  setMyEnvironmentModalScreen,
} from 'src/store/slices/uiSlice';
import { fetchAllCustomViews } from 'src/thunks';

type CustomViewScreenEditProps = {
  customViewEdit: any;
  setCustomViewEdit: React.Dispatch<any>;
};

const CustomViewScreenEdit = ({
  customViewEdit,
  setCustomViewEdit,
}: CustomViewScreenEditProps) => {
  const dispatch = useDispatch();
  const activeViewId = useAppSelector(selectActiveViewId);
  const [withExchanges, setWithExchanges] = useState<any>([]);
  const [state, setState] = useState<any>({});
  const [normalizedWithExchanges, setNormalizedWithExchanges] = useState<any>({});
  const [normalizedWithExchangesRevert, setNormalizedWithExchangesRevert] = useState<any>(
    {}
  );
  const [weights, setWeights] = useState<any>({});

  const selectedCustomView = useAppSelector(selectCustomViewById(customViewEdit.viewId));
  const [isLoading, setIsLoading] = useState(false);
  const [weight, setWeight] = useState('');
  const [activeEditWeight, setActiveEditWeight] = useState<null | number>(null);
  const isProView = useAppSelector(selectIsProView);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  useEffect(() => {
    const fetchView = async () => {
      try {
        const defaultWeights: any = {};
        const result = await AccountService.allAccounts();
        setWithExchanges(result.data);

        const normalizedWithExchangesState: any = {};
        const normalizedWithExchangesRevertState: any = {};

        result.data.forEach((account: any) => {
          normalizedWithExchangesState[account.accountId] = {};
          normalizedWithExchangesRevertState[account.accountId] = {};

          account?.exchanges?.forEach((exchange: any) => {
            normalizedWithExchangesState[account.accountId][exchange.exchangeId] = {};
            normalizedWithExchangesRevertState[account.accountId][exchange.exchangeId] =
              {};

            exchange?.portfolios?.forEach((portfolio: any) => {
              normalizedWithExchangesState[account.accountId][exchange.exchangeId][
                portfolio.portfolioId
              ] = true;

              normalizedWithExchangesRevertState[account.accountId][exchange.exchangeId][
                portfolio.portfolioId
              ] = false;

              defaultWeights[`${portfolio.portfolioId}`] = 100;
            });
          });
        });

        setNormalizedWithExchanges(normalizedWithExchangesState);
        setNormalizedWithExchangesRevert(normalizedWithExchangesRevertState);
        setWeights(defaultWeights);

        setState(normalizedWithExchanges);
      } catch (error) {
        logError(error);
      }
    };

    fetchView();
  }, []);

  useEffect(() => {
    if (!isEmpty(normalizedWithExchangesRevert)) {
      setState(() => {
        const copy: any = cloneDeep(normalizedWithExchangesRevert);
        selectedCustomView?.accounts.forEach((account: any) => {
          account.exchanges.forEach((exchange: any) => {
            exchange.portfolios.forEach((portfolio: any) => {
              if (copy[account.accountId][exchange.exchangeId]) {
                copy[account.accountId][exchange.exchangeId][portfolio.portfolioId] =
                  true;
              }
            });
          });
        });

        return copy;
      });
      if (selectedCustomView?.viewPortfolioIdWeightMap) {
        setWeights({
          ...weights,
          ...Object.keys(selectedCustomView?.viewPortfolioIdWeightMap).reduce(
            (acc: any, val: any) => {
              acc[val] = selectedCustomView?.viewPortfolioIdWeightMap[val] * 100;

              return acc;
            },
            {}
          ),
        });
      }
    }
  }, [selectedCustomView, normalizedWithExchangesRevert]);

  const setPortfolioId = (accountId: number, exchangeId: number, portfolioId: number) => {
    setState(() => {
      const result = cloneDeep(state);

      if (!result[accountId]) {
        result[accountId] = {};
      }

      if (!result[accountId][exchangeId]) {
        result[accountId][exchangeId] = {};
      }

      if (result[accountId][exchangeId][portfolioId]) {
        result[accountId][exchangeId][portfolioId] = false;
      } else {
        result[accountId][exchangeId][portfolioId] = true;
      }

      return result;
    });
  };

  const handleExchangeChange = (accountId: number, exchangeId: number) => () => {
    const copy = cloneDeep(state);

    if (
      isEqual(
        state?.[accountId]?.[exchangeId],
        normalizedWithExchanges[accountId][exchangeId]
      )
    ) {
      setState(() => {
        copy[accountId][exchangeId] = cloneDeep(
          normalizedWithExchangesRevert[accountId][exchangeId]
        );

        return copy;
      });
    } else {
      setState(() => {
        const copy = cloneDeep(state);

        copy[accountId][exchangeId] = cloneDeep(
          normalizedWithExchanges[accountId][exchangeId]
        );

        return copy;
      });
    }
  };

  const handleAccountChange = (accountId: number) => () => {
    const copy = cloneDeep(state);

    if (isEqual(state?.[accountId], normalizedWithExchanges[accountId])) {
      setState(() => {
        copy[accountId] = cloneDeep(normalizedWithExchangesRevert[accountId]);

        return copy;
      });
    } else {
      setState(() => {
        const copy = cloneDeep(state);

        copy[accountId] = cloneDeep(normalizedWithExchanges[accountId]);

        return copy;
      });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    const portfolioList: any = [];
    Object.values(state).forEach((exchange: any) => {
      Object.values(exchange).forEach((portfolios: any) => {
        Object.keys(portfolios).forEach((portfolioId) => {
          if (portfolios[portfolioId]) {
            portfolioList.push(Number(portfolioId));
          }
        });
      });
    });

    try {
      if (customViewEdit.viewId) {
        await CustomViewService.updateView(customViewEdit.viewId, {
          isAttach: customViewEdit.isAttach,
          viewName: customViewEdit.viewName,
          portfolioIdWeightMap: portfolioList.reduce((acc: any, val: any) => {
            acc[`${val}`] = weights[val] / 100;

            return acc;
          }, {}),
        });

        if (customViewEdit.viewId === activeViewId) {
          if (isProView) {
            const result = await CustomViewService.getBalanceProView(activeViewId);
            dispatch(setCustomViewProBalance(result.data));
          } else {
            const result = await CustomViewService.getBalanceView(activeViewId);
            dispatch(setCustomViewBalance(result.data));
          }

          const customViews: { data: CustomView[] } = await CustomViewService.allViews();

          dispatch(setCustomViewList(customViews.data));
          dispatch(updateCustomViewFilters());
        }
      } else {
        await CustomViewService.createView({
          isAttach: true,
          viewName: customViewEdit.viewName,
          portfolioIdWeightMap: portfolioList.reduce((acc: any, val: any) => {
            acc[`${val}`] = weights[val] / 100;

            return acc;
          }, {}),
        });

        const customViews: { data: CustomView[] } = await CustomViewService.allViews();

        dispatch(setCustomViewList(customViews.data));
      }
      fetchAllCustomViews(dispatch);
      dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViews));
    } catch (error) {
      logError(error);
    }

    setIsLoading(false);
  };

  const selectPortfolios = () => {
    const portfolioList: any = [];
    Object.values(state).forEach((exchange: any) => {
      Object.values(exchange).forEach((portfolios: any) => {
        Object.keys(portfolios).forEach((portfolioId) => {
          if (portfolios[portfolioId]) {
            portfolioList.push(Number(portfolioId));
          }
        });
      });
    });

    return portfolioList;
  };

  const handleBackClick = () => {
    dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViews));
    setCustomViewEdit(null);
  };

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="edit view"
        descriptionText="View: "
        highlightedDescriptionText={customViewEdit.viewName}
        buttonText="Save"
        goBack={handleBackClick}
        buttonAction={handleSave}
        hasButtonIcon={false}
        isButtonDisabled={isLoading || selectPortfolios().length === 0}
      />
      <Scrollbar height="516px" trackYRight={10} isDarkTheme={isDarkTheme}>
        <div className={styles.assets}>
          {withExchanges.map((item: any, index: number) => {
            return (
              <div
                key={`${item.accountId}-${item.accountName}-${index}`}
                className={styles.assetItem}
              >
                <div className={styles.assetAccountName}>
                  <div className={styles.assetAccountNameLeftSide}>
                    <Checkbox
                      name={`${item.accountId}-${item.accountName}`}
                      checked={isEqual(
                        state[item.accountId],
                        normalizedWithExchanges[item.accountId]
                      )}
                      onChange={handleAccountChange(item.accountId)}
                    />
                    <Typography.P1 as="span" className={styles.assetAccountNameLabel}>
                      {truncate(item.accountName, 23)}
                    </Typography.P1>
                  </div>
                  <div className={styles.assetAccountNameRightSide}>
                    <Typography.Additional
                      as="span"
                      className={styles.assetAccountNameLabel}
                    >
                      %
                    </Typography.Additional>
                    <Hint
                      width={200}
                      position="right-top"
                      hint={
                        <>
                          The fraction of sub-account total value and positions attributed
                          to a single View.
                        </>
                      }
                    />
                  </div>
                </div>
                {item.exchanges.map((exchange: any, index: number) => {
                  return (
                    <div key={`${exchange.exchangeId}-${exchange.exchangeName}-${index}`}>
                      <div className={styles.exchangeSection}>
                        <Checkbox
                          name={`${exchange.exchangeId}-${exchange.exchangeName}`}
                          checked={isEqual(
                            state?.[item.accountId]?.[exchange.exchangeId],
                            normalizedWithExchanges[item.accountId][exchange.exchangeId]
                          )}
                          onChange={handleExchangeChange(
                            item.accountId,
                            exchange.exchangeId
                          )}
                        />
                        <Typography.P1 as="span" className={styles.exchangeName}>
                          {truncate(exchange.exchangeName, 23)}
                        </Typography.P1>
                      </div>
                      {exchange.portfolios.map((portfolio: any, index: number) => {
                        return (
                          <div
                            className={cl(
                              styles.exchangeSection,
                              styles.portfolioSection
                            )}
                            key={`${portfolio.portfolioId}-${portfolio.portfolioName}-${index}`}
                          >
                            <div className={styles.portfolioLeftSide}>
                              <Checkbox
                                name={`${portfolio.portfolioId}-${portfolio.portfolioName}`}
                                checked={
                                  state?.[item.accountId]?.[exchange.exchangeId]?.[
                                    portfolio.portfolioId
                                  ]
                                }
                                onChange={() => {
                                  setPortfolioId(
                                    item.accountId,
                                    exchange.exchangeId,
                                    portfolio.portfolioId
                                  );
                                }}
                              />
                              <Typography.P1 as="span" className={styles.exchangeName}>
                                {truncate(portfolio.portfolioName, 23)}
                              </Typography.P1>
                            </div>
                            <div
                              className={cl(
                                styles.portfolioRightSide,
                                !state?.[item.accountId]?.[exchange.exchangeId]?.[
                                  portfolio.portfolioId
                                ] && styles.disabledPortfolioRightSide
                              )}
                            >
                              {activeEditWeight === portfolio.portfolioId ? (
                                <TextInput
                                  size={TextInputSize.Small}
                                  value={weight}
                                  onChange={(event) => {
                                    setWeight(event.target.value);
                                  }}
                                  onBlur={(event) => {
                                    setWeights({
                                      ...weights,
                                      [portfolio.portfolioId]: Number(event.target.value),
                                    });
                                    setActiveEditWeight(null);
                                  }}
                                  onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                      setWeights({
                                        ...weights,
                                        [portfolio.portfolioId]: Number(
                                          event.target.value
                                        ),
                                      });
                                      setActiveEditWeight(null);
                                    }
                                  }}
                                  onFocus={(event) => {
                                    event.target.select();
                                  }}
                                  autoFocus
                                  number
                                  className={styles.edit}
                                />
                              ) : (
                                <Typography.Additional
                                  as="p"
                                  className={cl(
                                    styles.weight,
                                    !state?.[item.accountId]?.[exchange.exchangeId]?.[
                                      portfolio.portfolioId
                                    ] && styles.disabledWeight
                                  )}
                                  onClick={() => {
                                    if (
                                      state?.[item.accountId]?.[exchange.exchangeId]?.[
                                        portfolio.portfolioId
                                      ]
                                    ) {
                                      setWeight(weights[portfolio.portfolioId]);
                                      setActiveEditWeight(portfolio.portfolioId);
                                    }
                                  }}
                                >
                                  {Number(weights[portfolio.portfolioId]).toFixed(2)}
                                  {state?.[item.accountId]?.[exchange.exchangeId]?.[
                                    portfolio.portfolioId
                                  ] && (
                                    <div className={styles.tooltip}>
                                      <Typography.Additional
                                        className={cl('number', styles.tooltipText)}
                                      >
                                        {weights[portfolio.portfolioId]}
                                      </Typography.Additional>
                                    </div>
                                  )}
                                </Typography.Additional>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Scrollbar>
    </>
  );
};

export default CustomViewScreenEdit;
