import React from 'react';
import { Typography } from 'src/ui-kit';
import { HELP_EMAIL, PRODUCT_NAME, PROD_RM_LINK } from 'src/constants';
import { ReactComponent as CloseIcon } from 'src/assets/images/close.svg';
import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = ({ goBack }: { goBack: () => void }) => {
  return (
    <div className={styles.termsAndConditions}>
      <div className={styles.termsAndConditionsInner}>
        <Typography.H3 className={styles.headingTC}>Terms and Conditions</Typography.H3>
        <button onClick={goBack}>
          <CloseIcon />
        </button>
      </div>
      <Typography.P1>
        Terms and Conditions for use of the platform{' '}
        <a href={PROD_RM_LINK}>{PROD_RM_LINK}</a> (Platform) provided by {PRODUCT_NAME}.
        Please note that this is a beta version of the Platform which is still undergoing
        testing before its official release. The Platform is provided on an “as is” and
        “as available” basis. {PRODUCT_NAME} will aim to provide you with secure, reliable
        information but does not guarantee this at any time. Through use of the Platform,
        you acknowledge that {PRODUCT_NAME} is not responsible for any errors, omissions
        or factual correctness of the information displayed, and it will not be liable for
        any losses or not liable for any errors, omissions, loss or damage which may be
        caused by your use of the Platform.
      </Typography.P1>
      <Typography.P1>
        We welcome your feedback at <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
      </Typography.P1>
    </div>
  );
};

export default TermsAndConditions;
