import { RequestStatus } from 'src/types/requestStatus';

export const updateRequestStatus =
  (requestName: string) =>
  (
    state: { requests: Record<string, RequestStatus> },
    action: { payload: RequestStatus }
  ) => {
    state.requests[requestName] = action.payload;
  };
