import { instance } from './mainInstance';

export interface UpdateExchangePropTypes {
  id: number;
  exchangeType: string;
  account: AccountPropTypes;
  userDefinedName: string;
  description: string;
  lastUpdateTime: string;
  accessKey: string;
}

interface AccountPropTypes {
  id: number;
  accountName: string;
  createdBy: {
    id: string;
    userName: string;
    email: string;
  };
}

export interface AddExchangePropTypes {
  accountId: number;
  exchangeType: string;
  userDefinedName: string;
}

export const ExchangeService = {
  getExchange: async (id: number) => {
    return await instance.get(`exchange/${id}`);
  },
  updateExchange: async (id: number, body: UpdateExchangePropTypes) => {
    return await instance.put(`exchange/${id}`, body);
  },
  deleteExchange: async (id: number) => {
    return await instance.delete(`exchange/${id}`);
  },
  createExchange: async (body: AddExchangePropTypes) => {
    return await instance.post(`exchange`, body);
  },
  allExchanges: async () => {
    return await instance.get(`exchange/all`);
  },
  renameExchange: async (id: number, body: AddExchangePropTypes) => {
    return await instance.patch(`exchange/rename/${id}`, body);
  },
  getMeta: async () => {
    return await instance.get(`exchange/meta`);
  },
};
