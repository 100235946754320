import { AnyAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum Api {
  DownloadBasicReport = 'downloadBasicReport',
  FetchBalances = 'fetchBalances',
}

const initialState: Record<
  Api,
  { isLoading: boolean; isSuccess: boolean; isError: boolean; errorMessage: string }
> = {
  downloadBasicReport: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  fetchBalances: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    startRequest(
      state,
      action: {
        payload: {
          key: Api;
        };
      }
    ) {
      state[action.payload.key] = {
        isLoading: true,
        isSuccess: false,
        isError: false,
        errorMessage: '',
      };
    },
    requestSuccess(
      state,
      action: {
        payload: {
          key: Api;
        };
      }
    ) {
      state[action.payload.key] = {
        isLoading: false,
        isSuccess: true,
        isError: false,
        errorMessage: '',
      };
    },
    requestError(
      state,
      action: {
        payload: {
          key: Api;
          error: string;
        };
      }
    ) {
      state[action.payload.key] = {
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: action.payload.error,
      };
    },
  },
});

export const { startRequest, requestSuccess, requestError } = requestsSlice.actions;

export const makeRequest =
  (key: Api, apiCall: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(startRequest({ key }));

      await apiCall();

      dispatch(requestSuccess({ key }));
    } catch (error: any) {
      dispatch(requestError({ key, error: error.message }));
    }
  };

// export const downloadBasicReport = () => async (dispatch: Dispatch<AnyAction>, getState) => {
//   await dispatch(makeRequest(Api.DownloadBasicReport, async () => {}));
// };

// export const getUsers = () => async (dispatch: Dispatch<AnyAction>) => {
//   const response = await dispatch(makeRequest(Api.DownloadBasicReport, () => {}));

//   if (response && response.success) {
//     dispatch(setUsers(response.data.users));
//   }
// };

export const selectRequestStatus = (request: Api) => (state: RootState) =>
  state.api[request];

export default requestsSlice.reducer;

// useEffect(() => {
//   const getStablePrices = async () => {
//     try {
//       const result: { data: Price[] } = await PriceService.getStable();

//       dispatch(setPrices(result.data));
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   getStablePrices();

//   const interval = setInterval(
//     getStablePrices,
//     Number(process.env.REACT_APP_BALANCE_REFRESH_RATE)
//   );

//   const getInitialData = async () => {
//     try {
//       const allAccounts: { data: Account[] } = await AccountService.allAccounts();

//       dispatch(setAccountList(allAccounts.data));

//       if (isMainView) {
//         dispatch(setActiveAccountId(allAccounts.data[0].id));
//       }

//       const customViews: { data: CustomView[] } = await CustomViewService.allViews();

//       dispatch(setCustomViewList(customViews.data));

//       const exchanges: { data: Exchange[] } = await ExchangeService.allExchanges();

//       dispatch(setExchangesAll(exchanges.data));

//       const exchangesMeta: { data: ExchangeMeta[] } = await ExchangeService.getMeta();

//       dispatch(setExchangesMeta(exchangesMeta.data));
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   getInitialData();

//   return () => clearInterval(interval);
// }, []);
