import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonPrimary,
  DownloadReport,
  Dropdown,
  Legenda,
  ProViewSwitcher,
  Typography,
} from 'src/ui-kit';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectIsProView, switchIsProView } from 'src/store/slices/uiSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectActiveView,
  selectActiveViewId,
  selectCustomViewBalance,
  setCustomViewBalance,
  setCustomViewProBalance,
} from 'src/store/slices/customViewsSlice';
import {
  selectAccountBalance,
  selectAccountList,
  selectActiveAccount,
  selectActiveAccountId,
  selectIsFetchingBalances,
  setAccountBalance,
  setActiveAccountId,
  setIsFetchingBalances,
  setProViewBalance,
} from 'src/store/slices/accountSlice';
import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg';
import { StateService } from 'src/services/States.service';
import { logError } from 'src/utils';

import styles from './DashboardControls.module.scss';
import { selectIsMainView } from 'src/store/slices/activeView';
import { fetchAccountBalance, fetchCustomViewBalance } from 'src/thunks';

const DashboardControls = () => {
  const dispatch = useAppDispatch();

  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [isVisibleDownloadReport, setIsVisibleDownloadReport] = useState(false);

  const isMainScreen = useAppSelector(selectIsMainView);
  const isProView = useAppSelector(selectIsProView);
  const accountList = useAppSelector(selectAccountList);
  const activeAccount = useAppSelector(selectActiveAccount);
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const activeView = useAppSelector(selectActiveView);
  const activeViewId = useAppSelector(selectActiveViewId);
  const accountBalance = useAppSelector(selectAccountBalance);
  const customViewBalance = useAppSelector(selectCustomViewBalance);
  const isFetchingBalances = useAppSelector(selectIsFetchingBalances);

  // TODO! Refactor and move to middleware

  let balanceRequestInterval: React.MutableRefObject<null | NodeJS.Timeout> =
    useRef(null);

  const clearInterval = (interval: React.MutableRefObject<null | NodeJS.Timeout>) => {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = null;
    }
  };

  const requestBalances = (
    interval: React.MutableRefObject<null | NodeJS.Timeout>,
    trigger: 'account' | 'view'
  ) => {
    let calledFromIntervalCallback = false;

    const callback = async () => {
      if (!calledFromIntervalCallback) {
        dispatch(setIsFetchingBalances(true));
      }

      if (trigger === 'account') {
        try {
          if (!calledFromIntervalCallback) {
            if (isProView) {
              dispatch(setProViewBalance(null));
            } else {
              dispatch(setAccountBalance([]));
            }
          }

          await fetchAccountBalance(
            dispatch,
            activeAccountId,
            isProView,
            calledFromIntervalCallback
          );
        } catch (err) {
          logError(err);
        }
      } else {
        try {
          if (!calledFromIntervalCallback) {
            dispatch(setCustomViewBalance([]));
            dispatch(setCustomViewProBalance([]));
          }

          await fetchCustomViewBalance(
            dispatch,
            activeViewId,
            isProView,
            calledFromIntervalCallback
          );
        } catch (err) {
          logError(err);
        }
      }

      calledFromIntervalCallback = true;
    };

    callback();

    interval.current = setInterval(
      callback,
      Number(process.env.REACT_APP_BALANCE_REFRESH_RATE)
    );
  };

  useEffect(() => {
    return () => {
      clearInterval(balanceRequestInterval);
    };
  }, []);

  useEffect(() => {
    if (activeViewId !== 0) {
      dispatch(setActiveAccountId(null));

      clearInterval(balanceRequestInterval);

      requestBalances(balanceRequestInterval, 'view');
    } else {
      if (accountList.length) {
        const persistedActiveAccountId = localStorage.getItem('activeAccountId');

        if (persistedActiveAccountId !== null) {
          const persistedActiveAccount = accountList.find((account) => {
            return account.accountId === Number(persistedActiveAccountId);
          });

          if (persistedActiveAccount) {
            dispatch(setActiveAccountId(persistedActiveAccount.accountId));
          }
        } else {
          dispatch(setActiveAccountId(accountList[0].accountId));
        }
      } else {
        dispatch(setIsFetchingBalances(false));
      }
    }
  }, [activeViewId]);

  useEffect(() => {
    if (activeAccountId !== null) {
      clearInterval(balanceRequestInterval);

      localStorage.setItem('activeAccountId', `${activeAccountId}`);

      requestBalances(balanceRequestInterval, 'account');
    }
  }, [activeAccountId]);

  useEffect(() => {
    clearInterval(balanceRequestInterval);

    if (isMainScreen) {
      requestBalances(balanceRequestInterval, 'account');
    } else {
      requestBalances(balanceRequestInterval, 'view');
    }
  }, [isProView]);

  // END TODO!

  const fetchPDF = async (checkedReports: [boolean, boolean]) => {
    setIsFetchingReport(true);
    try {
      if (checkedReports[0]) {
        const response = isMainScreen
          ? await StateService.reportAccount(activeAccountId!)
          : await StateService.reportView(activeViewId);
        if (response) {
          const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadURL;
          link.setAttribute('download', response.headers['content-disposition']!);
          document.body.appendChild(link);
          link.click();
        }
      }
      if (checkedReports[1]) {
        const response = isMainScreen
          ? await StateService.riskReportAccount(activeAccountId!)
          : await StateService.riskReportView(activeViewId);
        if (response) {
          const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadURL;
          link.setAttribute('download', response.headers['content-disposition']!);
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (err) {
      logError(err);
    } finally {
      setIsVisibleDownloadReport(false);
      setIsFetchingReport(false);
    }
  };

  const handleProViewSwitchClick = () => {
    dispatch(switchIsProView());
  };

  return (
    <div className={styles.topWrapper}>
      <div className={styles.topLeftWrapper}>
        <Typography.H2 className={styles.title}>
          {isMainScreen ? 'Account View' : 'Custom View'}
        </Typography.H2>
        {isMainScreen && accountList.length > 0 && (
          <Dropdown
            defaultValue={activeAccountId}
            options={accountList.map((account) => {
              return { label: account.accountName, value: account.accountId };
            })}
            label={activeAccount?.accountName || accountList[0].accountName}
            onChange={(account: any) => {
              dispatch(setActiveAccountId(account.value));
            }}
          />
        )}
        {!isMainScreen && activeView && (
          <Typography.H2 color="purple" weight={600} className={styles.customViewTitle}>
            {activeView.viewName}
          </Typography.H2>
        )}
      </div>
      <div className={styles.topRightWrapper}>
        <div className={styles.legendaWrapper}>
          <Legenda variant="exchanges" />
          <Legenda variant="portfolio" />
        </div>
        <ProViewSwitcher
          isChecked={isProView}
          isDisabled={accountList.length === 0}
          onChange={handleProViewSwitchClick}
        />
        <ButtonPrimary
          icon={<UploadIcon />}
          text="Report"
          isDisabled={
            isFetchingBalances ||
            (isMainScreen && accountList.length === 0) ||
            (isMainScreen && accountBalance?.exchangeBalances?.length === 0) ||
            (!isMainScreen && customViewBalance?.accountBalances?.length === 0)
          }
          onClick={() => setIsVisibleDownloadReport(true)}
        />
        <DownloadReport
          isVisible={isVisibleDownloadReport}
          onClose={() => setIsVisibleDownloadReport(false)}
          onSubmit={fetchPDF}
          isBlocked={isFetchingReport}
          className={styles.report}
        />
      </div>
    </div>
  );
};

export default DashboardControls;
