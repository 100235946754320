import { instance } from './mainInstance';

export const NoteService = {
  getList: async () => {
    return await instance.get(`/userNote`);
  },
  newNote: async (body: { userNoteData: string }) => {
    return await instance.post(`/userNote`, body);
  },
  update: async (id: string, body: { userNoteData: string }) => {
    return await instance.patch(`/userNote/${id}`, body);
  },
  delete: async (id: string) => {
    return await instance.delete(`/userNote/${id}`);
  },
};
