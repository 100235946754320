import {
  EmptyMyEnvironmentScreen,
  MyEnvironmentCardsHeader,
  MyEnvironmentCardsList,
} from 'src/ui-kit';

import { ExchangesScreenItem } from 'src/components/ModalMyEnvironment/components/ExchangesScreenItem';
import { ModalScreen } from 'src/components/ModalMyEnvironment/ModalMyEnvironment';

import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';

import { selectIsDarkTheme, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { selectExchangesByAccountId } from 'src/store/slices/accountSlice';

import { hasElements } from 'src/types/data';

type ExchangesScreenProps = {
  accountSection: any;
  setAccountSection: React.Dispatch<any>;
  onDelete: (account: any) => void;
};

const ExchangesScreen = ({
  accountSection,
  setAccountSection,
  onDelete,
}: ExchangesScreenProps) => {
  const dispatch = useAppDispatch();
  const exchangesByAccountId = useAppSelector(
    selectExchangesByAccountId(accountSection?.accountId)
  );
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const handleBackClick = () => {
    dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts));
    setAccountSection(null);
  };

  const handleButtonClick = () => {
    dispatch(setMyEnvironmentModalScreen(ModalScreen.ApiKeys));
  };

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="provider management"
        descriptionText="Account: "
        highlightedDescriptionText={accountSection?.accountName}
        buttonText="New provider"
        goBack={handleBackClick}
        buttonAction={handleButtonClick}
      />
      {hasElements(exchangesByAccountId) ? (
        <MyEnvironmentCardsList isDarkTheme={isDarkTheme}>
          {exchangesByAccountId.map((exchange, index) => {
            return (
              <ExchangesScreenItem
                positions={[]}
                id={exchange.exchangeId}
                name={exchange.exchangeName}
                type={exchange.exchangeType}
                accountId={exchange.accountId}
                key={`${exchange.exchangeId}-${index}`}
                index={index}
                onDelete={onDelete}
              />
            );
          })}
        </MyEnvironmentCardsList>
      ) : (
        <EmptyMyEnvironmentScreen
          line1="Please connect to a provider to start."
          line2="All information will be displayed here."
        />
      )}
    </>
  );
};

export default ExchangesScreen;
