import React from 'react';
import cl from 'clsx';
import { ButtonPrimary, Typography } from 'src/ui-kit';
import { ReactComponent as GreenTickIcon } from 'src/assets/images/green_tick.svg';
import styles from './EmailConfirmationError.module.scss';
import { HELP_EMAIL } from 'src/constants';

const EmailConfirmationError = ({ goBack }: { goBack: () => void }) => {
  return (
    <div className={cl(styles.signInForm, styles.confirmationLink)}>
      <>
        <GreenTickIcon />
        <div className={styles.confirmationWrapper}>
          <div>
            <Typography.H1
              isUppercase
              weight={700}
              align="center"
              className={styles.linkHeading}
            >
              invalid request
            </Typography.H1>
          </div>
          <div>
            <Typography.P1
              align="center"
              color="secondary"
              weight={500}
              className={styles.resendLink}
            >
              The link has either expired or invalid please{' '}
              <a href={`mailto:${HELP_EMAIL}`}>click here to resend it</a>
            </Typography.P1>
            <ButtonPrimary
              text="Close"
              isFullWidth
              size="large"
              onClick={goBack}
            ></ButtonPrimary>
          </div>
        </div>
      </>
    </div>
  );
};

export default EmailConfirmationError;
