import React from 'react';
import cl from 'clsx';

import styles from './Card.module.scss';
import { Typography } from '../Typography';

export type CardProps = {
  heading?: string;
  className?: string;
  children: React.ReactNode;
};

const Card = ({ heading, className, children }: CardProps) => {
  return (
    <div className={cl(styles.card, className && className)}>
      {heading && <Typography.P1 className={styles.heading}>{heading}</Typography.P1>}
      {children}
    </div>
  );
};

export default Card;
