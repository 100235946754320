import React from 'react';
import cl from 'clsx';
import { Typography } from 'src/ui-kit';

import styles from './TableCell.module.scss';
import { isNumber } from 'lodash';
import { formatMargin, nf } from 'src/utils';

export type TableCellProps = {
  level: number;
  text: string;
  platform?: string;
  type: 'balance' | 'balance_section' | 'portfolio' | 'exchange';
  pictureLink?: string;
  isOpen: boolean;
  isColorized: boolean;
  isWide: boolean;
  hasSpacing: boolean;
  isEmpty: boolean;
  isNameCell: boolean;
  isStandardTable: boolean;
  fullNumber?: number;
  hasTooltip: boolean;
  hasCents: boolean;
  fiat: boolean;
  isBold: boolean;
  percent?: boolean;
  highlighted?: boolean;
  initialMargin?: number;
  maintenanceMargin?: number;
  className?: string;
};

const TableCell = ({
  level,
  text,
  platform,
  pictureLink,
  type,
  isOpen,
  hasSpacing,
  isNameCell,
  isColorized,
  isWide,
  isEmpty,
  isStandardTable,
  fullNumber,
  hasTooltip,
  hasCents,
  fiat,
  isBold,
  percent,
  highlighted,
  initialMargin,
  maintenanceMargin,
  className,
}: TableCellProps) => {
  const hasMargins = isNumber(initialMargin) && isNumber(maintenanceMargin);

  return (
    <div
      className={cl(
        styles.tableCell,
        isOpen && styles.isOpen,
        styles[type],
        styles[`level${level}`],
        hasSpacing && styles.hasSpacing,
        isNameCell && styles.nameCell,
        !isNameCell && isStandardTable && styles.expand,
        isEmpty && styles.empty,
        (isWide || fiat) && styles.wide,
        highlighted && styles.highlighted,
        className && className
      )}
    >
      <div>
        <div
          className={cl(
            styles.textWrapper,
            (platform || hasMargins) && styles.withPlatform
          )}
        >
          {pictureLink && (
            <img src={pictureLink} alt={`${text} logo`} className={styles.picture} />
          )}
          <Typography.P1
            as="span"
            weight={isBold && isNumber(fullNumber) ? 600 : 400}
            className={cl(
              styles.text,
              isNumber(fullNumber) && 'number',
              isColorized && isNumber(fullNumber) && fullNumber > 0 && styles.green,
              isColorized && isNumber(fullNumber) && fullNumber < 0 && styles.red
            )}
          >
            {!hasCents && fiat && isNumber(fullNumber)
              ? nf(0).format(Math.round(fullNumber))
              : text}
          </Typography.P1>
          {platform && (
            <Typography.P1
              as="span"
              weight={400}
              color="secondary"
              className={styles.platform}
            >
              {platform}
            </Typography.P1>
          )}
          {hasMargins && (
            <Typography.P1
              as="span"
              color="secondary"
              weight={400}
              className={styles.margin}
            >
              [IM:{' '}
              <Typography.P1
                as="span"
                color="secondary"
                className={cl(
                  styles.marginNumber,
                  initialMargin >= 50 && initialMargin < 80 && styles.yellowMargin,
                  initialMargin >= 80 && styles.redMargin
                )}
              >
                {formatMargin(initialMargin)}
              </Typography.P1>
              , MM:{' '}
              <Typography.P1
                as="span"
                color="secondary"
                className={cl(
                  styles.marginNumber,
                  maintenanceMargin >= 50 &&
                    maintenanceMargin < 80 &&
                    styles.yellowMargin,
                  maintenanceMargin >= 80 && styles.redMargin
                )}
              >
                {formatMargin(maintenanceMargin)}
              </Typography.P1>
              ]
            </Typography.P1>
          )}
          {hasTooltip && isNumber(fullNumber) && (
            <div className={styles.tooltip}>
              <Typography.Additional className={cl('number', styles.tooltipText)}>
                {Number(fullNumber.toFixed(8))}
              </Typography.Additional>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableCell;
