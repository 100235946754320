import { CustomViewsScreenItem } from 'src/components/ModalMyEnvironment/components/CustomViewsScreenItem';
import {
  EmptyMyEnvironmentScreen,
  MyEnvironmentCardsHeader,
  MyEnvironmentCardsList,
} from 'src/ui-kit';

import { useAppSelector } from 'src/hooks/useAppSelector';

import { selectCustomViewList } from 'src/store/slices/customViewsSlice';

import { hasElements } from 'src/types/data';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';

type CustomViewsScreenProps = {
  setCustomViewEdit: React.Dispatch<any>;
  setNewViewMode: React.Dispatch<boolean>;
  onDelete: (account: any) => void;
};

const AccountsScreen = ({
  setCustomViewEdit,
  setNewViewMode,
  onDelete,
}: CustomViewsScreenProps) => {
  const customViewsList = useAppSelector(selectCustomViewList);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const handleButtonClick = () => {
    setNewViewMode(true);
  };

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="CUSTOM VIEWs"
        descriptionText="Manage your views. Select items which you want to see in Side bar."
        buttonText="New View"
        buttonAction={handleButtonClick}
      />
      {hasElements(customViewsList) ? (
        <MyEnvironmentCardsList isDarkTheme={isDarkTheme}>
          {customViewsList.map((customView, index) => (
            <CustomViewsScreenItem
              viewId={customView.viewId}
              viewName={customView.viewName}
              portfolioIdWeightMap={customView.viewPortfolioIdWeightMap}
              isAttach={customView.isAttach}
              key={`${customView.viewId}-${index}`}
              index={index}
              setCustomViewEdit={setCustomViewEdit}
              onDelete={onDelete}
            />
          ))}
        </MyEnvironmentCardsList>
      ) : (
        <EmptyMyEnvironmentScreen
          line1="Please create a custom view to start."
          line2="Custom view information will be displayed here."
        />
      )}
    </>
  );
};

export default AccountsScreen;
