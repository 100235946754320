import { createAction, createSlice } from '@reduxjs/toolkit';
import { CustomInstrument } from 'src/types/customInstrument';
import { createSelector } from 'reselect';
import type { RootState } from 'src/store/store';

type CustomInstrumentState = {
  list: CustomInstrument[];
  selected: any;
  initialTemplate: any;
  meta: any;
};

const initialState: CustomInstrumentState = {
  list: [],
  selected: null,
  initialTemplate: null,
  meta: null,
};

export const customInstrumentSlice = createSlice({
  name: 'customInstrument',
  initialState,
  reducers: {
    setCustomInstrumentList: (state, action) => {
      state.list = action.payload;
    },
    setSelectedCustomInstrument: (state, action) => {
      state.selected = action.payload;
    },
    setInitialTemplate: (state, action) => {
      state.initialTemplate = action.payload;
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
  },
});

export const {
  setCustomInstrumentList,
  setSelectedCustomInstrument,
  setInitialTemplate,
  setMeta,
} = customInstrumentSlice.actions;
export const fetchCustomInstrumentList = createAction(
  `${customInstrumentSlice.name}/fetchCustomInstrumentList`
);

export const selectCustomInstrumentList = (state: RootState) =>
  state.customInstrument.list;
export const selectCustomInstrumentListFilteredByType = createSelector(
  [selectCustomInstrumentList, (state: RootState, type: string | null) => type],
  (list, type) => {
    if (type === null) {
      return [];
    }

    return list.filter((item) => item.instrumentType === type);
  }
);

export const selectSelectedCustomInstrument = (state: RootState) => {
  return state.customInstrument.selected;
};

export const selectInitialTemplate = (state: RootState) => {
  return state.customInstrument.initialTemplate;
};

export const selectMeta = (state: RootState) => {
  return state.customInstrument.meta;
};

export default customInstrumentSlice.reducer;
