import React, { useState } from 'react';
import { ModalScreen } from '../../ModalMyEnvironment';
import { AccountService } from 'src/services/Account.service';
import { ButtonPrimary, TextInput, Typography } from 'src/ui-kit';
import { useDispatch } from 'react-redux';
import {
  addAccount,
  selectAccountList,
  setActiveAccountId,
} from 'src/store/slices/accountSlice';
import { ReactComponent as SuccessIcon } from 'src/assets/images/completeExc.svg';

import styles from './NewAccountScreen.module.scss';
import { setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { logError } from 'src/utils';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { fetchAllAccounts } from 'src/thunks';

type NewAccountScreenProps = {
  setAccountSection: React.Dispatch<any>;
};

const NewAccountScreen = ({ setAccountSection }: NewAccountScreenProps) => {
  const [inputText, setInputText] = useState('');
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [newAccount, setNewAccount] = useState<{
    accountId: number;
    accountName: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accountList = useAppSelector(selectAccountList);

  const dispatch = useDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async () => {
    if (inputText.length > 0) {
      try {
        setIsSubmitting(true);
        let isEmptyAccountList = accountList.length === 0;
        const newAccount = await AccountService.createAccount({ accountName: inputText });
        const { accountId, accountName } = newAccount.data;
        dispatch(addAccount({ accountId, accountName }));

        if (isEmptyAccountList) {
          dispatch(setActiveAccountId(accountId));
        }

        setNewAccount({ accountId, accountName });
        setAccountSection({ accountId, accountName });
        setIsSuccesful(true);

        await fetchAllAccounts(dispatch);
      } catch (error) {
        logError(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className={styles.newAccountScreen}>
      {!isSuccesful && (
        <>
          <div>
            <Typography.P1 className={styles.description}>
              Enter your custom account name.
              <br />
              Each account can be associated with one or multiple providers
            </Typography.P1>
            <div>
              <Typography.P1 className={styles.inputLabel}>Account name</Typography.P1>
              <TextInput
                placeholder="Account_N"
                value={inputText}
                onChange={handleInputChange}
                inputClassName={styles.input}
                autoFocus
              />
              <Typography.P1 className={styles.inputDescription}>
                Keep it simple
              </Typography.P1>
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <ButtonPrimary
              text="Create"
              isDisabled={inputText.length === 0}
              onClick={handleSubmit}
              className={styles.createButton}
            ></ButtonPrimary>
            <button
              onClick={() => dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts))}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {isSuccesful && (
        <>
          <div className={styles.successTopWrapper}>
            <SuccessIcon className={styles.successIcon} />
            <Typography.P1 className={styles.newAccountName}>
              {newAccount?.accountName}
            </Typography.P1>
            <Typography.P1 className={styles.successNote}>
              Account created successfully
            </Typography.P1>
          </div>
          <div className={styles.buttonsWrapper}>
            <ButtonPrimary
              text="Add Provider"
              onClick={() => dispatch(setMyEnvironmentModalScreen(ModalScreen.ApiKeys))}
              isDisabled={isSubmitting}
              className={styles.createButton}
            ></ButtonPrimary>
            <button
              onClick={() => dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts))}
              className={styles.cancelButton}
            >
              Continue later
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default NewAccountScreen;
