import React from 'react';
import cl from 'clsx';

import styles from './ProViewSwitcher.module.scss';
import { Typography } from '../Typography';

export type ProViewSwitcherProps = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  className?: string;
};

const ProViewSwitcher = ({
  isChecked,
  isDisabled = false,
  onChange,
  className,
}: ProViewSwitcherProps) => {
  return (
    <button
      className={cl(
        styles.proViewSwitcher,
        isDisabled && styles.disabled,
        className && className
      )}
      disabled={isDisabled}
      onClick={onChange}
    >
      <div
        className={cl(
          styles.background,
          !isChecked && styles.standartActive,
          isChecked && styles.proActive
        )}
      ></div>
      <div className={cl(styles.modeWrapper, styles.standart)}>
        <Typography.P1
          as="span"
          className={cl(styles.mode, !isChecked && styles.modeActive)}
        >
          Basic
        </Typography.P1>
      </div>
      <div className={cl(styles.modeWrapper, styles.pro)}>
        <Typography.P1
          as="span"
          className={cl(styles.mode, isChecked && styles.modeActive)}
        >
          Pro
        </Typography.P1>
      </div>
    </button>
  );
};

export default ProViewSwitcher;
