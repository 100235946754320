import { Typography } from 'src/ui-kit';

import styles from './NewAccountScreenHeader.module.scss';

type NewAccountScreenHeaderProps = {};

const NewAccountScreenHeader = ({}: NewAccountScreenHeaderProps) => {
  return (
    <div className={styles.newAccountScreenHeader}>
      <div className={styles.setupWrapper}>
        <Typography.H1 className={styles.setup}>
          Let's set up your environment
        </Typography.H1>
      </div>
      <div className={styles.stepWrapper}>
        <Typography.P1 className={styles.step1}>step 1</Typography.P1>
        <Typography.P1 className={styles.accountCreation}>account</Typography.P1>
      </div>
    </div>
  );
};

export default NewAccountScreenHeader;
