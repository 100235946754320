import { createSlice } from '@reduxjs/toolkit';
import { Price } from 'src/types/price';
import type { RootState } from '../store';

type PriceState = {
  list: Price[];
};

const initialState: PriceState = {
  list: [],
};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setPrices } = priceSlice.actions;

export const selectPriceList = (state: RootState) => state.price.list;

export default priceSlice.reducer;
