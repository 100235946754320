import React from 'react';
import cl from 'clsx';
import { Typography } from '../Typography';
import styles from './ComingSoon.module.scss';

export type ComingSoonProps = {
  className?: string;
};

const ComingSoon = ({ className }: ComingSoonProps) => {
  return (
    <div className={cl(styles.comingSoon, className && className)}>
      <Typography.Additional className={styles.label}>coming soon</Typography.Additional>
    </div>
  );
};

export default ComingSoon;
