import React from 'react';
import { ReactComponent as LogoVertical } from 'src/assets/images/logoVertical.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/images/arrowFiltersLeft.svg';
import styles from './MobileWarning.module.scss';

const MobileWarning = ({ onAccept }: { onAccept: () => void }) => {
  return (
    <div className={styles.mobileWarning}>
      <div className={styles.contentWrapper}>
        <LogoVertical className={styles.logo} />
        <div className={styles.welcome}>
          welcome to
          <div className={styles.rms}>risk management system</div>
        </div>
        <div className={styles.warningText}>
          For an optimal experience, please switch to a desktop browser.
          <br />
          <br />
          Our Risk Management system is designed for a larger screen, ensuring you access
          all features and navigation with ease
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <a className={styles.goBack} href="https://deepgainventures.com/">
          <ArrowLeft className={styles.arrow} />
          <span>Back to website</span>
        </a>
        <button className={styles.goToRm} onClick={onAccept}>
          Go to Risk Management System
        </button>
      </div>
    </div>
  );
};

export default MobileWarning;
