import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isBoolean, isEmpty } from 'lodash';
import { CustomViewService } from 'src/services/CustomView.service';
import {
  setCustomViewBalance,
  setCustomViewList,
  setCustomViewProBalance,
  setInitialCustomViewFilters,
} from 'src/store/slices/customViewsSlice';
import { CustomView } from 'src/types/customView';
import { logError } from 'src/utils';
import {
  setIsFetchingBalances,
  setProViewColumnsFilter,
} from 'src/store/slices/accountSlice';

export const fetchAllCustomViews = async (dispatch: Dispatch<AnyAction>) => {
  const customViews: { data: CustomView[] } = await CustomViewService.allViews();

  dispatch(setCustomViewList(customViews.data));
};

const fetchAccountBalanceAbortControllers: Record<string, AbortController> = {};

export const fetchCustomViewBalance = async (
  dispatch: Dispatch<AnyAction>,
  customViewId: number,
  isProView: boolean,
  calledFromIntervalCallback = false
) => {
  if (!isEmpty(fetchAccountBalanceAbortControllers)) {
    Object.keys(fetchAccountBalanceAbortControllers).forEach((prevControllerId) => {
      fetchAccountBalanceAbortControllers[prevControllerId].abort();
      delete fetchAccountBalanceAbortControllers[prevControllerId];
    });
  }

  const controllerId = uuidv4();

  const abortController = new AbortController();
  fetchAccountBalanceAbortControllers[controllerId] = abortController;

  if (isProView) {
    try {
      const result = await CustomViewService.getBalanceProView(
        customViewId,
        abortController.signal
      );

      dispatch(setCustomViewProBalance(result.data));

      if (!calledFromIntervalCallback) {
        dispatch(setInitialCustomViewFilters());
      }

      const savedState = localStorage.getItem('proViewColumnsFilter');

      if (!savedState) {
        dispatch(
          setProViewColumnsFilter(
            result.data.metadata.column.map((columnData: any) => {
              return {
                key: columnData.key,
                name: columnData.name,
                isChecked: columnData.default || columnData.attach,
                isDefault: columnData.default,
                isAttached: columnData.attach,
                isColorized: columnData.colorize,
                isWide: columnData.wide,
                formatting: columnData.formatting,
                grouping: columnData.grouping,
                percent: columnData.percent,
                condNumFormatting: columnData.condNumFormatting,
                fiat: columnData.fiat,
              };
            })
          )
        );
      } else {
        const columnsFilterPayload = result.data.metadata.column.map(
          (columnData: any) => {
            const persistedColumnState = JSON.parse(savedState).find((savedCol: any) => {
              return savedCol.key === columnData.key;
            });
            return {
              key: columnData.key,
              name: columnData.name,
              isChecked: isBoolean(persistedColumnState?.isChecked)
                ? persistedColumnState?.isChecked
                : columnData.default || columnData.attach,
              isDefault: columnData.default,
              isAttached: columnData.attach,
              isColorized: columnData.colorize,
              isWide: columnData.wide,
              formatting: columnData.formatting,
              grouping: columnData.grouping,
              percent: columnData.percent,
              condNumFormatting: columnData.condNumFormatting,
              fiat: columnData.fiat,
            };
          }
        );

        dispatch(setProViewColumnsFilter(columnsFilterPayload));
      }
      dispatch(setIsFetchingBalances(false));
    } catch (error) {
      logError(error);
    }
  } else {
    try {
      const result = await CustomViewService.getBalanceView(
        customViewId,
        abortController.signal
      );

      dispatch(setCustomViewBalance(result.data));
      dispatch(setIsFetchingBalances(false));
    } catch (error) {
      logError(error);
    }
  }

  delete fetchAccountBalanceAbortControllers[controllerId];
};
