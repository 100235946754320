import React from 'react';
import cl from 'clsx';
import { isNumber } from 'lodash';
import { Typography } from 'src/ui-kit';

import styles from './FilterTabButton.module.scss';

type FilterTabButtonProps = {
  id: 0 | 1 | 2;
  name: string;
  activeFilter: 0 | 1 | 2;
  number?: number;
  setActiveFilter: (value: React.SetStateAction<0 | 1 | 2>) => void;
};

const FilterTabButton = ({
  id,
  name,
  activeFilter,
  number,
  setActiveFilter,
}: FilterTabButtonProps) => {
  const handleClick = (id: 0 | 1 | 2) => () => {
    setActiveFilter(id);
  };

  return (
    <button
      className={cl(styles.filterBtn, activeFilter === id && styles.filterBtnActive)}
      onClick={handleClick(id)}
    >
      <Typography.P1 weight={500} as="span" className={styles.filterBtnText}>
        {name}
      </Typography.P1>
      {` `}
      {isNumber(number) && (
        <Typography.Additional weight={500} className={styles.filterBtnCount}>
          ({number})
        </Typography.Additional>
      )}
    </button>
  );
};

export default FilterTabButton;
