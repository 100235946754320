import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { UserService } from 'src/services/User.service';
import { setUser } from 'src/store/slices/userSlice';
import { UserInfo } from 'src/types/user';
import { logError } from 'src/utils';

export const fetchUserInfo = async (dispatch: Dispatch<AnyAction>) => {
  try {
    const result: { data: UserInfo } = await UserService.userInfo();

    dispatch(setUser(result.data));
  } catch (err) {
    logError(err);
  }
};
