import React from 'react';
import cl from 'clsx';

import styles from './FilterLine.module.scss';
import { Typography } from '../Typography';

type FilterLineProps = {
  name: string;
  onClick: () => void;
};

const FilterLine = ({ name, onClick }: FilterLineProps) => {
  return (
    <button onClick={onClick} className={cl(styles.filterLine)}>
      <Typography.P1 as="span" className={styles.filterLineText}>
        {name}
      </Typography.P1>
    </button>
  );
};

export default FilterLine;
