import { call, put, takeLatest } from 'redux-saga/effects';
import {
  EMAIL_VERIFIED_ROUTE,
  JOIN_US_ROUTE,
  SIGN_IN_ROUTE,
  UPDATE_PASSWORD_ROUTE,
} from 'src/constants';
import { UserService } from 'src/services/User.service';
import { logout, removeUser } from 'src/store/slices/userSlice';
import { logError } from 'src/utils';

function* logoutAsync({ payload: hasRedirect }: { payload: boolean }): Generator {
  try {
    yield call(UserService.signout);
    yield put(removeUser());
    localStorage.removeItem('access_token');
  } catch (error) {
    logError(error);
  } finally {
    if (
      window.location.pathname !== SIGN_IN_ROUTE &&
      window.location.pathname !== JOIN_US_ROUTE &&
      !window.location.pathname.includes(EMAIL_VERIFIED_ROUTE) &&
      !window.location.pathname.includes(UPDATE_PASSWORD_ROUTE)
    ) {
      window.location.href = '/sign-in';
    }
  }
}

function* watcher() {
  yield takeLatest(logout, logoutAsync);
}

export default watcher;
