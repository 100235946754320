import React, { useEffect, useState } from 'react';
import cl from 'clsx';

import styles from './Table.module.scss';
import { TableHead, TableRow } from './components';
import { CustomViewAccountTitle } from '../CustomViewAccountTitle';
import { isEqual } from 'lodash';

export type TableProps = {
  data: TableData;
  hasTooltip: boolean;
  hasCents: boolean;
  hasBorderRadius?: boolean;
  isFixed?: boolean;
  className?: string;
  isStandardTable?: boolean;
  title?: string;
};

type TableData = {
  columns: any;
  rows: TTableRow;
};

type TTableRow = Map<
  string,
  {
    id: string;
    parentId?: string;
    parents: string[];
    type: 'balance' | 'balance_section' | 'portfolio' | 'exchange';
    isOpen: boolean;
    cells: TTableCell[];
  }
>;

type TTableCell = {
  text: string;
};

const Table = ({
  data,
  hasBorderRadius = true,
  isFixed = true,
  isStandardTable = true,
  title,
  hasTooltip,
  hasCents,
  className,
}: TableProps) => {
  const [tableData, setTableData] = useState(() => {
    const result = {
      columns: data.columns,
      rows: data.rows,
    };

    return result;
  });

  useEffect(() => {
    setTableData((prevState: any) => {
      const rows: any = new Map();

      // @ts-ignore
      data.rows.forEach((item) => {
        rows.set(item.id, {
          ...item,
          isOpen: prevState.rows.get(item.id)?.isOpen || false,
        });
      });

      return {
        columns: data.columns,
        rows,
      };
    });
  }, [data]);

  const expandAll = () => {
    setTableData((prevState: any) => {
      const copy: any = new Map(prevState.rows);
      copy.forEach((value: any, key: any) => {
        value.isOpen = true;
        copy.set(key, value);
      });

      return {
        columns: prevState.columns,
        rows: copy,
      };
    });
  };

  const collapseAll = () => {
    setTableData((prevState: any) => {
      const copy: any = new Map(prevState.rows);
      copy.forEach((value: any, key: any) => {
        value.isOpen = false;
        copy.set(key, value);
      });

      return {
        columns: prevState.columns,
        rows: copy,
      };
    });
  };

  const handleClick = (id: string) => () => {
    setTableData((prevState: any) => {
      const copy: any = new Map(prevState.rows);

      copy.forEach((value: any, key: any) => {
        if (key === id) {
          value.isOpen = !value.isOpen;
          copy.set(key, value);
        } else if (
          (value.isOpen && value.parents.includes(id)) ||
          (copy.get(id)?.childrensNumber === 1 && value.parentId === id)
        ) {
          value.isOpen = copy.get(id).isOpen;
          copy.set(key, value);
        }
      });

      return {
        columns: prevState.columns,
        rows: copy,
      };
    });
  };

  return (
    <div className={cl(styles.table, isFixed && styles.fixed, className && className)}>
      {title && (
        <div className={styles.titleWrapper}>
          {tableData.columns.map((column: any, index: number) => {
            if (index === 0) {
              return (
                <CustomViewAccountTitle
                  title={title}
                  hasBorderTopRadius={hasBorderRadius}
                  key={`dummy-${column.name}-${index}`}
                />
              );
            }

            return (
              <div
                className={cl(
                  styles.dummy,
                  (column.isWide || column.fiat) && styles.wideDummy
                )}
                key={`dummy-${column.name}-${index}`}
              ></div>
            );
          })}
        </div>
      )}

      <div style={{ position: 'sticky', top: 0, left: 0, zIndex: 15 }}>
        <div style={{ display: 'flex', width: isStandardTable ? 'auto' : 'fit-content' }}>
          {tableData.columns.map((column: any, index: number) => {
            return (
              <TableHead
                text={column.name}
                controls={index === 0 ? { expandAll, collapseAll } : undefined}
                hasBorderRadius={hasBorderRadius}
                isNameCell={index === 0}
                isWide={column.isWide}
                isFixed={isFixed}
                isStandardTable={isStandardTable}
                fiat={column.fiat}
                key={`${column.name}-${index}`}
              />
            );
          })}
        </div>
      </div>
      <div>
        {Array.from(tableData.rows, ([key, value]: any) => ({ key, value })).map(
          (row, index) => {
            return (
              <TableRow
                level={row.value.level}
                id={row.value.id}
                parentId={row.value.parentId}
                type={row.value.type}
                isVisible={
                  !row.value.parentId || !!tableData.rows.get(row.value.parentId)?.isOpen
                }
                isOpen={row.value.isOpen}
                cells={row.value.cells}
                onClick={handleClick}
                index={index}
                isStandardTable={isStandardTable}
                hasTooltip={hasTooltip}
                hasCents={hasCents}
                isBold={row.value.isBold}
                key={`${row.value.parentId}-${row.value.id}-${index}`}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

const comparison = (prevProps: TableProps, nextProps: TableProps) => {
  return (
    prevProps.hasTooltip === nextProps.hasTooltip &&
    prevProps.hasCents === nextProps.hasCents &&
    isEqual(prevProps.data, nextProps.data)
  );
};

const PureTable = React.memo(Table, comparison);

export default PureTable;
