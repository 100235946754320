import React from 'react';
import cl from 'clsx';
import { Typography, TypographyProps } from '../../Typography';

import styles from './P1.module.scss';

const P1 = ({
  as = 'p',
  color = 'dark',
  align = 'left',
  weight = 400,
  isUppercase = false,
  children,
  className,
}: TypographyProps) => {
  return (
    <Typography
      as={as}
      color={color}
      align={align}
      weight={weight}
      isUppercase={isUppercase}
      className={cl(styles.p1, className && className)}
    >
      {children}
    </Typography>
  );
};

export default P1;
