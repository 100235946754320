import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isBoolean, isNumber, orderBy } from 'lodash';
import { ViewBalance } from 'src/types/balance';
import { CustomView } from 'src/types/customView';
import { formatBalance, nf, nf2, nf4 } from 'src/utils';
import type { RootState } from '../store';

type CustomViewState = {
  list: CustomView[];
  activeViewId: number;
  balance: ViewBalance | null;
  proBalance: any;
  filters: any;
};

const initialState: CustomViewState = {
  list: [],
  activeViewId: 0,
  balance: null,
  proBalance: null,
  filters: {
    coins: {},
    exchanges: {},
  },
};

export const customViewSlice = createSlice({
  name: 'customView',
  initialState,
  reducers: {
    setCustomViewList: (state, action) => {
      state.list = action.payload;
    },
    setCustomViewBalance: (state, action) => {
      state.balance = action.payload;
    },
    deleteCustomView: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((item) => {
        return item.viewId === id;
      });

      state.list.splice(index, 1);
    },
    renameCustomView: (state, action) => {
      const { viewId, viewName } = action.payload;

      state.list = state.list.map((item) => {
        if (item.viewId === viewId) {
          item.viewName = viewName;
        }

        return item;
      });
    },
    setCustomViewProBalance: (state, action) => {
      state.proBalance = action.payload;
    },
    setInitialCustomViewFilters: (state) => {
      state.filters = {
        coins: {},
        exchanges: {},
      };

      if (state.proBalance) {
        state.proBalance.metadata?.coinFilters.forEach((coinName: string) => {
          state.filters.coins[coinName] = true;
        });

        state.proBalance.metadata?.exchangeFilters.forEach((exchangeName: string) => {
          state.filters.exchanges[exchangeName] = true;
        });
      }
    },
    updateCustomViewFilters: (state) => {
      const filters: {
        coins: Record<string, boolean>;
        exchanges: Record<string, boolean>;
      } = {
        coins: {},
        exchanges: {},
      };

      if (state.proBalance && state.proBalance.metadata) {
        state.proBalance.metadata.coinFilters.forEach((coinName: string) => {
          filters.coins[coinName] = true;
        });

        state.proBalance.metadata.exchangeFilters.forEach((exchangeName: string) => {
          filters.exchanges[exchangeName] = true;
        });

        Object.keys(filters.coins).forEach((coinName: string) => {
          if (isBoolean(state.filters.coins[coinName])) {
            filters.coins[coinName] = state.filters.coins[coinName];
          }
        });

        Object.keys(filters.exchanges).forEach((exchangeName: string) => {
          if (isBoolean(state.filters.exchanges[exchangeName])) {
            filters.exchanges[exchangeName] = state.filters.exchanges[exchangeName];
          }
        });
      }

      state.filters = filters;
    },
    switchCustomViewCoinFilter: (state, action) => {
      state.filters.coins[action.payload] = !state.filters.coins[action.payload];
    },
    switchCustomViewExchangeFilter: (state, action) => {
      state.filters.exchanges[action.payload] = !state.filters.exchanges[action.payload];
    },
    selectAllCustomViewCoins: (state) => {
      Object.keys(state.filters.coins).forEach((ticker) => {
        state.filters.coins[ticker] = true;
      });
    },
    deselectAllCustomViewCoins: (state) => {
      Object.keys(state.filters.coins).forEach((ticker) => {
        state.filters.coins[ticker] = false;
      });
    },
  },
});

export const {
  setCustomViewList,
  setCustomViewBalance,
  deleteCustomView,
  renameCustomView,
  setCustomViewProBalance,
  setInitialCustomViewFilters,
  switchCustomViewCoinFilter,
  switchCustomViewExchangeFilter,
  selectAllCustomViewCoins,
  deselectAllCustomViewCoins,
  updateCustomViewFilters,
} = customViewSlice.actions;

export const selectCustomViewList = (state: RootState) => state.customView.list;
export const selectOnlyAttachedCustomViews = (state: RootState) =>
  state.customView.list.filter((item) => item.isAttach);
export const selectActiveViewId = (state: RootState) => state.activeView.id;
export const selectActiveView = (state: RootState) =>
  state.customView.list.find((item) => item.viewId === state.activeView.id);
export const selectCustomViewById = (id: number) => (state: RootState) =>
  state.customView.list.find((item) => {
    return item.viewId === id;
  });
export const selectCustomViewBalance = (state: RootState) => state.customView.balance;
export const selectCustomViewBalanceTotal = (state: RootState) => {
  let total = 0;

  state.customView.balance?.accountBalances?.forEach((accountBalance) => {
    accountBalance.exchangeBalances.forEach((exchangeBalance) => {
      exchangeBalance.portfolioBalances.forEach((portfolioBalance) => {
        portfolioBalance.balanceSections.forEach((balanceSection) => {
          balanceSection.balances.forEach((balance) => {
            total += balance.amountUsdValue;
          });
        });
      });
    });
  });

  return total;
};

export const selectCustomViewProBalanceTotal = (state: RootState) => {
  const total = state.customView.proBalance?.accountBalances?.reduce(
    (accountBalanceTotal: any, accountBalance: any) => {
      return (
        accountBalanceTotal +
        accountBalance.exchangeBalances?.reduce((total: any, exchangeBalance: any) => {
          return (
            total +
            exchangeBalance.portfolioBalances.reduce(
              (portfolioTotal: any, portfolioBalance: any) => {
                return (
                  portfolioTotal +
                  portfolioBalance.balanceSections.reduce(
                    (sectionTotal: any, balanceSection: any) => {
                      let blTotal = 0;
                      if (balanceSection.balances) {
                        blTotal = balanceSection.balances?.reduce(
                          (balanceTotal: any, balance: any) => {
                            return balanceTotal + balance.usdPrice;
                          },
                          0
                        );
                      }

                      let exTotal = 0;

                      if (balanceSection.expires) {
                        exTotal = balanceSection.expires?.reduce(
                          (expireTotal: any, expire: any) => {
                            return (
                              expireTotal +
                              expire.balances?.reduce(
                                (balanceTotal: any, balance: any) => {
                                  return balanceTotal + balance.usdPrice;
                                },
                                0
                              )
                            );
                          },
                          0
                        );
                      }

                      return sectionTotal + blTotal + exTotal;
                    },
                    0
                  )
                );
              },
              0
            )
          );
        }, 0)
      );
    },
    0
  );

  return total;
};

export const selectCustomViewsExchangesExposure = (state: RootState) => {
  let result: any = [];
  const dict: any = {};
  let totalValue = 0;

  state.customView.balance?.accountBalances?.forEach((accountBalance) => {
    accountBalance.exchangeBalances.forEach((exchangeBalance) => {
      exchangeBalance.portfolioBalances.forEach((portfolioBalance) => {
        portfolioBalance.balanceSections.forEach((balanceSection) => {
          balanceSection.balances.forEach((balance) => {
            if (balance.amountUsdValue >= 0) {
              if (exchangeBalance.exchangeType === 'CUSTOM') {
                if (dict[exchangeBalance.exchangeName]) {
                  dict[exchangeBalance.exchangeName] += balance.amountUsdValue;
                } else {
                  dict[exchangeBalance.exchangeName] = balance.amountUsdValue;
                }
              } else {
                if (dict[exchangeBalance.exchangeType]) {
                  dict[exchangeBalance.exchangeType] += balance.amountUsdValue;
                } else {
                  dict[exchangeBalance.exchangeType] = balance.amountUsdValue;
                }
              }

              totalValue += balance.amountUsdValue;
            }
          });
        });
      });
    });
  });

  Object.keys(dict).forEach((key) => {
    result.push({
      exchangeType: key,
      percentOfTotal: (dict[key] / totalValue) * 100,
    });
  });

  result = orderBy(result, ['percentOfTotal'], ['desc']);

  result = result.reduce((accumulator: any, currentValue: any, index: number) => {
    if (index < 6) {
      accumulator.push(currentValue);
    } else if (index === 6) {
      currentValue.currencyTicker = 'Other';
      accumulator.push(currentValue);
    } else {
      accumulator[6].percentOfTotal += currentValue.percentOfTotal;
    }

    return accumulator;
  }, []);

  return result;
};

export const selectCustomViewAssetAllocation = (state: RootState) => {
  let result: any = [];
  const dict: any = {};

  let totalValue = 0;

  state.customView.balance?.accountBalances?.forEach((accountBalance) => {
    accountBalance.exchangeBalances.forEach((exchangeBalance) => {
      exchangeBalance.portfolioBalances.forEach((portfolioBalance) => {
        portfolioBalance.balanceSections.forEach((balanceSection) => {
          balanceSection.balances.forEach((balance) => {
            if (balance.amountUsdValue >= 0) {
              if (dict[balance.instrument.baseCurrency.ticker]) {
                dict[balance.instrument.baseCurrency.ticker] += balance.amountUsdValue;
              } else {
                dict[balance.instrument.baseCurrency.ticker] = balance.amountUsdValue;
              }

              totalValue += balance.amountUsdValue;
            }
          });
        });
      });
    });
  });

  Object.keys(dict).forEach((key) => {
    result.push({
      currencyTicker: key,
      percentOfTotal: (dict[key] / totalValue) * 100,
    });
  });

  result = orderBy(result, ['percentOfTotal'], ['desc']);

  result = result.reduce((accumulator: any, currentValue: any, index: number) => {
    if (index < 6) {
      accumulator.push(currentValue);
    } else if (index === 6) {
      currentValue.currencyTicker = 'Other';
      accumulator.push(currentValue);
    } else {
      accumulator[6].percentOfTotal += currentValue.percentOfTotal;
    }

    return accumulator;
  }, []);

  return result;
};

export const selectCustomViewTableData = (state: RootState) => {
  const result: any = [];

  if (!state.customView.balance || !state.customView.balance.accountBalances) {
    return result;
  }

  state.customView.balance.accountBalances.forEach((currentBalance) => {
    const accountBalance: any = new Map();

    currentBalance.exchangeBalances.forEach(
      ({ exchangeId, exchangeName, exchangeType, portfolioBalances }) => {
        const exchangeBalanceRow = {
          id: `exchange-${exchangeId}`,
          parents: [],
          childrensNumber: 0,
          level: 0,
          isOpen: false,
          type: 'exchange',
          cells: [
            { text: exchangeName, platform: exchangeType },
            { text: '--' },
            { text: '--' },
            { text: '--' },
          ],
        };

        accountBalance.set(exchangeBalanceRow.id, exchangeBalanceRow);

        let totalPortfoliosValue = 0;

        portfolioBalances.forEach(
          ({ portfolioId, portfolioName, balanceSections }, index) => {
            exchangeBalanceRow.childrensNumber++;
            const portfolioBalanceRow = {
              id: `portfolio-${portfolioId}`,
              parentId: `exchange-${exchangeId}`,
              parents: [`exchange-${exchangeId}`],
              childrensNumber: 0,
              level: 1,
              isOpen: false,
              type: 'portfolio',
              cells: [
                { text: portfolioName },
                { text: '--' },
                { text: '--' },
                { text: '--' },
              ],
            };

            accountBalance.set(portfolioBalanceRow.id, portfolioBalanceRow);

            let totalBalanceSectionsValue = 0;
            let balancesCount = 0;

            balanceSections.forEach(
              ({ sectionName, initialMargin, maintenanceMargin, balances }, index) => {
                portfolioBalanceRow.childrensNumber++;
                const balanceSectionRow = {
                  id: `balance-section-${portfolioId}-${sectionName}`,
                  parentId: `portfolio-${portfolioId}`,
                  parents: [`exchange-${exchangeId}`, `portfolio-${portfolioId}`],
                  childrensNumber: 0,
                  level: 2,
                  isOpen: false,
                  type: 'balance_section',
                  cells: [
                    { text: sectionName, initialMargin, maintenanceMargin },
                    { text: '--' },
                    { text: '--' },
                    { text: '--' },
                  ],
                };

                accountBalance.set(balanceSectionRow.id, balanceSectionRow);

                let totalBalancesValue = 0;

                balances.forEach(
                  ({ amount, usdPrice, amountUsdValue, instrument }, index) => {
                    balancesCount++;
                    balanceSectionRow.childrensNumber++;
                    const balanceRow = {
                      id: `balance-${portfolioId}-${sectionName}-${instrument.instrumentName}-${index}`,
                      parentId: `balance-section-${portfolioId}-${sectionName}`,
                      parents: [
                        `exchange-${exchangeId}`,
                        `portfolio-${portfolioId}`,
                        `balance-section-${portfolioId}-${sectionName}`,
                      ],
                      level: 3,
                      isOpen: false,
                      type: 'balance',
                      cells: [
                        {
                          text: instrument.instrumentName,
                          pictureLink: instrument.baseCurrency.pictureLink,
                        },
                        { text: nf4.format(amount), fullNumber: amount },
                        { text: nf4.format(usdPrice), fullNumber: usdPrice },
                        { text: nf2.format(amountUsdValue), fullNumber: amountUsdValue },
                      ],
                    };

                    accountBalance.set(balanceRow.id, balanceRow);

                    totalBalancesValue += amountUsdValue;

                    if (index === balances.length - 1) {
                      accountBalance.set(balanceSectionRow.id, {
                        ...balanceSectionRow,
                        cells: [
                          { text: sectionName, initialMargin, maintenanceMargin },
                          { text: '--' },
                          { text: '--' },
                          {
                            text: nf2.format(totalBalancesValue),
                            fullNumber: totalBalancesValue,
                          },
                        ],
                      });
                    }
                  }
                );

                totalBalanceSectionsValue += totalBalancesValue;

                if (index === balanceSections.length - 1) {
                  accountBalance.set(portfolioBalanceRow.id, {
                    ...portfolioBalanceRow,
                    cells: [
                      { text: portfolioName },
                      { text: '--' },
                      { text: '--' },
                      {
                        text: nf2.format(totalBalanceSectionsValue),
                        fullNumber: totalBalanceSectionsValue,
                      },
                    ],
                  });
                }
              }
            );

            totalPortfoliosValue += totalBalanceSectionsValue;

            if (index === portfolioBalances.length - 1) {
              accountBalance.set(exchangeBalanceRow.id, {
                ...exchangeBalanceRow,
                cells: [
                  { text: exchangeName, platform: exchangeType },
                  { text: '--' },
                  { text: '--' },
                  {
                    text: nf2.format(totalPortfoliosValue),
                    fullNumber: totalPortfoliosValue,
                  },
                ],
              });
            }

            if (balancesCount === 0) {
              if (!state.ui.tableFilters.controls['Hide empty folders']) {
                accountBalance.set(portfolioBalanceRow.id, {
                  id: `empty-portfolio-${portfolioId}`,
                  parentId: `exchange-${exchangeId}`,
                  parents: [`exchange-${exchangeId}`],
                  level: 1,
                  isOpen: false,
                  type: 'portfolio',
                  cells: [
                    { text: portfolioName },
                    { text: '--' },
                    { text: '--' },
                    { text: '--' },
                  ],
                });
              } else {
                accountBalance.delete(portfolioBalanceRow.id);
              }
            }
          }
        );
      }
    );

    result.push(accountBalance);
  });

  return result;
};

export const selectCustomViewProBalance = (state: RootState) => {
  const result: any = [];

  if (!state.customView.proBalance || !state.customView.proBalance.accountBalances) {
    return result;
  }

  if (!state.account.proViewColumnsFilter?.filter) {
    return result;
  }

  state.customView.proBalance.accountBalances.forEach((currentBalance: any) => {
    const intermediateResult: any = {
      accountName: currentBalance.accountName,
      columns: [],
      rows: new Map(),
    };

    state.account.proViewColumnsFilter
      .filter((item: any) => item.isChecked)
      .forEach((item: any) => {
        intermediateResult.columns.push({
          name: item.name,
          formatting: item.formatting,
          isWide: item.isWide,
          percent: item.percent,
          fiat: item.fiat,
        });
      });

    const cells = state.account.proViewColumnsFilter
      .filter((item: any) => item.isChecked)
      .map((item: any) => ({
        text: '--',
        isColorized: item.isColorized,
        formatting: item.formatting,
        isWide: item.isWide,
        fullNumber: null,
        percent: item.percent,
        fiat: item.fiat,
      }));

    const totalCells = cloneDeep(cells);
    totalCells[0].text = 'TOTAL';

    const totalRow = {
      id: 'total',
      parents: [],
      childrensNumber: 0,
      level: 0,
      isOpen: false,
      type: 'total',
      cells: totalCells,
    };

    intermediateResult.rows.set('total', totalRow);

    currentBalance.exchangeBalances.forEach(
      ({ exchangeId, exchangeName, exchangeType, portfolioBalances }: any) => {
        if (state.customView.filters.exchanges[exchangeName]) {
          const exchangeCells = [...cells];
          exchangeCells[0] = { text: exchangeName, platform: exchangeType };
          const exchangeBalanceRow = {
            id: `exchange-${exchangeId}`,
            parents: [],
            childrensNumber: 0,
            level: 0,
            isOpen: false,
            type: 'exchange',
            cells: exchangeCells,
          };

          intermediateResult.rows.set(exchangeBalanceRow.id, exchangeBalanceRow);

          let notFilteredExchanges = 0;

          portfolioBalances?.forEach(
            ({ portfolioId, portfolioName, balanceSections }: any, index: number) => {
              exchangeBalanceRow.childrensNumber++;
              const portfolioCells = [...cells];
              portfolioCells[0] = { text: portfolioName };
              const portfolioBalanceRow = {
                id: `portfolio-${portfolioId}`,
                parentId: `exchange-${exchangeId}`,
                parents: [`exchange-${exchangeId}`],
                childrensNumber: 0,
                level: 1,
                isOpen: false,
                type: 'portfolio',
                cells: portfolioCells,
              };

              intermediateResult.rows.set(portfolioBalanceRow.id, portfolioBalanceRow);

              let notFilteredPortfolioBalances = 0;

              balanceSections?.forEach(
                (
                  {
                    sectionName,
                    initialMargin,
                    maintenanceMargin,
                    balances,
                    expires,
                    breakEvenVol: balanceSectionBreakEvenVol,
                  }: any,
                  index: number
                ) => {
                  portfolioBalanceRow.childrensNumber++;
                  const balanceSectionsCells = [...cells];
                  balanceSectionsCells[0] = {
                    text: sectionName,
                    initialMargin,
                    maintenanceMargin,
                  };
                  const balanceSectionRow = {
                    id: `balance-section-${portfolioId}-${sectionName}`,
                    parentId: `portfolio-${portfolioId}`,
                    parents: [`exchange-${exchangeId}`, `portfolio-${portfolioId}`],
                    childrensNumber: 0,
                    level: 2,
                    isOpen: false,
                    isBold:
                      state.customView.proBalance.metadata.boldSumList.includes(
                        'balanceSections'
                      ),
                    type: 'balance_section',
                    cells: balanceSectionsCells,
                  };

                  intermediateResult.rows.set(balanceSectionRow.id, balanceSectionRow);

                  let notFilteredBalances = 0;
                  let notFilteredExpires = 0;

                  expires?.forEach(
                    (
                      { sectionName: expiresSectionName, balances, breakEvenVol }: any,
                      index: number
                    ) => {
                      balanceSectionRow.childrensNumber++;
                      const expiresCells = [...cells];
                      expiresCells[0] = { text: expiresSectionName };

                      const expiresRow = {
                        id: `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                        parentId: `balance-section-${portfolioId}-${sectionName}`,
                        parents: [
                          `exchange-${exchangeId}`,
                          `portfolio-${portfolioId}`,
                          `balance-section-${portfolioId}-${sectionName}`,
                        ],
                        childrensNumber: 0,
                        level: 3,
                        isOpen: false,
                        type: 'expiry',
                        cells: expiresCells,
                      };

                      intermediateResult.rows.set(expiresRow.id, expiresRow);

                      let notFilteredExpiresBalances = 0;

                      balances?.forEach((balance: any, index: number) => {
                        expiresRow.childrensNumber++;
                        if (
                          state.customView.filters.coins[
                            balance.instrument.baseCurrency.ticker
                          ]
                        ) {
                          notFilteredExpiresBalances++;

                          const balanceCells = [...cells];
                          balanceCells[0] = {
                            text: balance.instrument.instrumentName,
                            pictureLink: balance.instrument.baseCurrency.pictureLink,
                          };

                          state.account.proViewColumnsFilter
                            .filter((item: any) => item.isChecked)
                            .forEach((column: any, index: number) => {
                              const isHighlighted =
                                state.ui.tableFilters.controls['Highlight ITM options'] &&
                                ((balance.instrument.optionType === 'CALL' &&
                                  balance.forwardPrice > balance.instrument.strike) ||
                                  (balance.instrument.optionType === 'PUT' &&
                                    balance.forwardPrice < balance.instrument.strike));

                              if (column.name === 'Name') {
                                balanceCells[index].isHighlighted = isHighlighted;
                                return;
                              }

                              if (column.fiat) {
                                let fullNumber = totalCells[index].fullNumber;

                                if (isNumber(balance[column.key])) {
                                  fullNumber += balance[column.key];
                                }

                                totalCells[index] = {
                                  ...totalCells[index],
                                  isColorized: column.isColorized,
                                  isWide: column.isWide,
                                  text: isNumber(fullNumber)
                                    ? formatBalance(
                                        totalCells[index].fullNumber +
                                          balance[column.key],
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      )
                                    : formatBalance(
                                        balance[column.key],
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                  fullNumber: fullNumber,
                                };
                              }

                              balanceCells[index] = {
                                ...balanceCells[index],
                                text: formatBalance(
                                  balance[column.key],
                                  column.formatting,
                                  column.condNumFormatting,
                                  column.percent
                                ),
                                isColorized: column.isColorized,
                                isWide: column.isWide,
                                fullNumber: column.percent
                                  ? balance[column.key] * 100
                                  : balance[column.key],
                                isHighlighted,
                              };

                              if (
                                column.grouping.expires === 'Sum' &&
                                isNumber(balance[column.key])
                              ) {
                                if (expiresCells[index].fullNumber === null) {
                                  expiresCells[index] = {
                                    ...expiresCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    expiresCells[index].fullNumber + balance[column.key];

                                  expiresCells[index] = {
                                    ...expiresCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }

                                if (column.grouping.balanceSections === 'Sum') {
                                  if (balanceSectionsCells[index].fullNumber === null) {
                                    balanceSectionsCells[index] = {
                                      ...balanceSectionsCells[index],
                                      text: formatBalance(
                                        balance[column.key],
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: balance[column.key],
                                    };
                                  } else {
                                    const sum =
                                      balanceSectionsCells[index].fullNumber +
                                      balance[column.key];

                                    balanceSectionsCells[index] = {
                                      ...balanceSectionsCells[index],
                                      text: formatBalance(
                                        sum,
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: sum,
                                    };
                                  }
                                }

                                if (column.grouping.portfolioBalances === 'Sum') {
                                  if (portfolioCells[index].fullNumber === null) {
                                    portfolioCells[index] = {
                                      ...portfolioCells[index],
                                      text: formatBalance(
                                        balance[column.key],
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: balance[column.key],
                                    };
                                  } else {
                                    const sum =
                                      portfolioCells[index].fullNumber +
                                      balance[column.key];

                                    portfolioCells[index] = {
                                      ...portfolioCells[index],
                                      text: formatBalance(
                                        sum,
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: sum,
                                    };
                                  }
                                }

                                if (column.grouping.exchangeBalances === 'Sum') {
                                  if (exchangeCells[index].fullNumber === null) {
                                    exchangeCells[index] = {
                                      ...exchangeCells[index],
                                      text: formatBalance(
                                        balance[column.key],
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: balance[column.key],
                                    };
                                  } else {
                                    const sum =
                                      exchangeCells[index].fullNumber +
                                      balance[column.key];

                                    exchangeCells[index] = {
                                      ...exchangeCells[index],
                                      text: formatBalance(
                                        sum,
                                        column.formatting,
                                        column.condNumFormatting,
                                        column.percent
                                      ),
                                      fullNumber: sum,
                                    };
                                  }
                                }
                              } else if (column.key === 'volatility') {
                                if (breakEvenVol !== null) {
                                  expiresCells[index] = {
                                    ...expiresCells[index],
                                    text: formatBalance(
                                      breakEvenVol / 100,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: breakEvenVol,
                                  };
                                }
                                if (balanceSectionBreakEvenVol !== null) {
                                  balanceSectionsCells[index] = {
                                    ...balanceSectionsCells[index],
                                    text: formatBalance(
                                      balanceSectionBreakEvenVol / 100,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: balanceSectionBreakEvenVol,
                                  };
                                }
                              }
                            });

                          const balanceRow = {
                            id: `balance-${portfolioId}-${sectionName}-${expiresSectionName}-${balance.instrument.instrumentName}-${index}`,
                            parentId: `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                            parents: [
                              `exchange-${exchangeId}`,
                              `portfolio-${portfolioId}`,
                              `balance-section-${portfolioId}-${sectionName}`,
                              `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                            ],
                            level: 4,
                            isOpen: false,
                            type: 'balance',
                            cells: balanceCells,
                          };

                          intermediateResult.rows.set(balanceRow.id, balanceRow);
                        }
                      });

                      if (notFilteredExpiresBalances === 0) {
                        intermediateResult.rows.delete(expiresRow.id);
                      } else {
                        notFilteredExpires++;
                      }
                    }
                  );

                  balances.forEach((balance: any, index: number) => {
                    balanceSectionRow.childrensNumber++;
                    if (
                      state.customView.filters.coins[
                        balance.instrument.baseCurrency.ticker
                      ]
                    ) {
                      notFilteredBalances++;

                      const balanceCells = [...cells];

                      state.account.proViewColumnsFilter
                        .filter((item: any) => item.isChecked)
                        .forEach((column: any, index: number) => {
                          const isHighlighted =
                            state.ui.tableFilters.controls['Highlight ITM options'] &&
                            ((balance.instrument.optionType === 'CALL' &&
                              balance.forwardPrice > balance.instrument.strike) ||
                              (balance.instrument.optionType === 'PUT' &&
                                balance.forwardPrice < balance.instrument.strike));

                          if (column.key === 'instrumentName') {
                            balanceCells[index] = {
                              text: balance.instrument.instrumentName,
                              pictureLink: balance.instrument.baseCurrency.pictureLink,
                              isColorized: column.isColorized,
                              isWide: column.isWide,
                              isHighlighted,
                            };
                          } else {
                            if (column.fiat) {
                              let fullNumber = totalCells[index].fullNumber;

                              if (isNumber(balance[column.key])) {
                                fullNumber += balance[column.key];
                              }

                              totalCells[index] = {
                                ...totalCells[index],
                                isColorized: column.isColorized,
                                isWide: column.isWide,
                                text: isNumber(fullNumber)
                                  ? formatBalance(
                                      totalCells[index].fullNumber + balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    )
                                  : formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                fullNumber: fullNumber,
                              };
                            }

                            balanceCells[index] = {
                              ...balanceCells[index],
                              text: formatBalance(
                                balance[column.key],
                                column.formatting,
                                column.condNumFormatting,
                                column.percent
                              ),
                              isColorized: column.isColorized,
                              isWide: column.isWide,
                              fullNumber: column.percent
                                ? balance[column.key] * 100
                                : balance[column.key],
                              isHighlighted,
                            };

                            if (
                              column.grouping.balances === 'Sum' &&
                              isNumber(balance[column.key])
                            ) {
                              if (column.grouping.balanceSections === 'Sum') {
                                if (balanceSectionsCells[index].fullNumber === null) {
                                  balanceSectionsCells[index] = {
                                    ...balanceSectionsCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    balanceSectionsCells[index].fullNumber +
                                    balance[column.key];

                                  balanceSectionsCells[index] = {
                                    ...balanceSectionsCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }

                              if (column.grouping.portfolioBalances === 'Sum') {
                                if (portfolioCells[index].fullNumber === null) {
                                  portfolioCells[index] = {
                                    ...portfolioCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    portfolioCells[index].fullNumber +
                                    balance[column.key];

                                  portfolioCells[index] = {
                                    ...portfolioCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }

                              if (column.grouping.exchangeBalances === 'Sum') {
                                if (exchangeCells[index].fullNumber === null) {
                                  exchangeCells[index] = {
                                    ...exchangeCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    exchangeCells[index].fullNumber + balance[column.key];

                                  exchangeCells[index] = {
                                    ...exchangeCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }
                            }
                          }
                        });

                      const balanceRow = {
                        id: `balance-${portfolioId}-${sectionName}-${balance.instrument.instrumentName}-${index}`,
                        parentId: `balance-section-${portfolioId}-${sectionName}`,
                        parents: [
                          `exchange-${exchangeId}`,
                          `portfolio-${portfolioId}`,
                          `balance-section-${portfolioId}-${sectionName}`,
                        ],
                        level: 3,
                        isOpen: false,
                        type: 'balance',
                        cells: balanceCells,
                      };

                      intermediateResult.rows.set(balanceRow.id, balanceRow);
                    }
                  });

                  if (notFilteredBalances || notFilteredExpires) {
                    notFilteredPortfolioBalances++;
                  }

                  if (!balances?.length && !expires?.length) {
                    intermediateResult.rows.delete(balanceSectionRow.id);
                  } else if (notFilteredBalances === 0 && notFilteredExpires === 0) {
                    intermediateResult.rows.delete(balanceSectionRow.id);
                  }
                }
              );

              if (notFilteredPortfolioBalances === 0) {
                if (!state.ui.tableFilters.controls['Hide empty folders']) {
                  intermediateResult.rows.set(portfolioBalanceRow.id, {
                    id: `empty-${portfolioBalanceRow.id}`,
                    parentId: `exchange-${exchangeId}`,
                    parents: [`exchange-${exchangeId}`],
                    level: 1,
                    isOpen: false,
                    type: 'portfolio',
                    cells: portfolioCells,
                  });
                } else {
                  intermediateResult.rows.delete(portfolioBalanceRow.id);
                }
              } else {
                notFilteredExchanges++;
              }
            }
          );

          if (notFilteredExchanges === 0) {
            intermediateResult.rows.set(exchangeBalanceRow.id, {
              id: `empty-${exchangeId}`,
              parents: [],
              level: 0,
              isOpen: false,
              type: 'exchange',
              cells: exchangeCells,
            });
          }
        }
      }
    );

    result.push(intermediateResult);
  });

  return result;
};

export const selectCustomViewCoinsFilter = (state: RootState) => {
  return state.customView.filters.coins;
};

export const selectCustomViewExchangesFilter = (state: RootState) => {
  return state.customView.filters.exchanges;
};

export default customViewSlice.reducer;
