import React from 'react';
import cl from 'clsx';
import styles from './CustomViewAccountTitle.module.scss';
import { Typography } from '../Typography';

type CustomViewAccountTitleProps = {
  title: string;
  hasBorderTopRadius?: boolean;
  className?: string;
};

const CustomViewAccountTitle = ({
  title,
  hasBorderTopRadius = true,
  className,
}: CustomViewAccountTitleProps) => {
  return (
    <div
      className={cl(
        styles.customViewAccountTitle,
        hasBorderTopRadius && styles.hasBorderRadius,
        className && className
      )}
    >
      <Typography.H3 color="white" weight={600} isUppercase className={styles.text}>
        {title}
      </Typography.H3>
    </div>
  );
};

export default CustomViewAccountTitle;
