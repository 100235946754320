import React from 'react';
import cl from 'clsx';
import styles from './MessageBox.module.scss';
import { Typography } from '../Typography';

type MessageBoxProps = {
  type: 'error' | 'success';
  text: string;
  className?: string;
};

const MessageBox = ({ type, text, className }: MessageBoxProps) => {
  return (
    <div
      className={cl(
        styles.messageBox,
        type === 'error' ? styles.messageBoxError : styles.messageBoxSuccess,
        className && className
      )}
    >
      <Typography.P1 className={styles.text}>{text}</Typography.P1>
    </div>
  );
};

export default MessageBox;
