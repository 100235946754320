import React, { useEffect, useState } from 'react';
import { ButtonPrimary, ButtonSecondary, Modal, TextInput, Typography } from 'src/ui-kit';
import cl from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  AccountsScreen,
  CustomInstruments,
  ExchangesScreen,
  NewCustomInstrument,
  NewCustomInstrumentScreenHeader,
} from './components';

import styles from './ModalMyEnvironment.module.scss';
import { AccountService } from 'src/services/Account.service';
import { useDispatch } from 'react-redux';
import {
  selectAccountList,
  selectActiveAccountId,
  setActiveAccountId,
} from 'src/store/slices/accountSlice';
import { CustomViewsScreen } from './components/CustomViewsScreen';
import { CustomViewService } from 'src/services/CustomView.service';
import { NewAccountScreen } from './components/NewAccountScreen';
import { NewAccountScreenHeader } from './components/NewAccountScreenHeader';
import { ApiKeysScreenHeader } from './components/ApiKeysScreenHeader';
import { ApiKeysScreen } from './components/ApiKeysScreen';
import { ExchangeService } from 'src/services/Exchange.service';
import { logError } from 'src/utils';
import { CustomViewScreenEdit } from './components/CustomViewScreenEdit';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectActiveViewId, selectIsMainView } from 'src/store/slices/activeView';
import {
  selectIsProView,
  selectMyEnvironmentModalScreen,
  setIsProView,
  setMyEnvironmentModalScreen,
  switchMyEnvironmentModalIsOpen,
} from 'src/store/slices/uiSlice';
import {
  fetchAccountBalance,
  fetchAllAccounts,
  fetchAllCustomViews,
  fetchCustomViewBalance,
} from 'src/thunks';
import { MAIN_VIEW_ROUTE } from 'src/constants';
import { NewPosition } from './components/NewPosition';
import { NewPositionHeader } from './components/NewPositionHeader';
import { CustomInstrumentService } from 'src/services/CustomInstrument.service';
import { fetchCustomInstrumentList } from 'src/store/slices/customInstrumentSlice';
import { Positions } from './components/Positions';
import { CustomPositionService } from 'src/services/CustomPosition.service';
import {
  fetchPositionsList,
  selectCustomPositionSelectedProvider,
  selectNewPositionPortfolioId,
} from 'src/store/slices/positionsSlice';
import { EditPosition } from './components/EditPosition';
import { EditPositionHeader } from './components/EditPositionHeader';
import { EditCustomInstrumentHeader } from './components/EditCustomInstrumentHeader';
import { EditCustomInstrument } from './components/EditCustomInstrument';

type ModalMyEnvironmentProps = {
  isOpen: boolean;
};

export enum ModalScreen {
  Accounts = 'accounts',
  CustomViews = 'custom_views',
  Exchanges = 'exchanges',
  NewAccount = 'new_account',
  NewExchangeScreen = 'new_exchange_screen',
  ApiKeys = 'api_keys',
  CustomViewEdit = 'custom_view_edit',
  CustomInstruments = 'custom_instruments',
  NewCustomInstrument = 'new_custom_instrument',
  NewPosition = 'new_position',
  Positions = 'positions',
  EditPosition = 'edit_position',
  EditCustomInstrument = 'edit_custom_instrument',
}

const ModalMyEnvironment = ({ isOpen }: ModalMyEnvironmentProps) => {
  const dispatch = useDispatch();
  const isMainScreen = useAppSelector(selectIsMainView);
  const isProView = useAppSelector(selectIsProView);
  const accountList = useAppSelector(selectAccountList);
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const screen = useAppSelector(selectMyEnvironmentModalScreen);

  const [accountForDelete, setAccountForDelete] = useState<any>(null);
  const [customViewForDelete, setCustomViewForDelete] = useState<any>(null);
  const [exchangeForDelete, setExchangeForDelete] = useState<any>(null);
  const [customInstrumentForDelete, setCustomInstrumentForDelete] = useState<any>(null);
  const [positionForDelete, setPositionForDelete] = useState<any>(null);
  const [accountSection, setAccountSection] = useState<{
    accountId: number;
    accountName: string;
  } | null>(null);
  const [customViewEdit, setCustomViewEdit] = useState<any>(null);
  const [newViewMode, setNewViewMode] = useState(false);
  const [newViewInputText, setNewViewInputText] = useState('');
  const [hasRedirect, setRedirect] = useState(false);
  const activeViewId = useAppSelector(selectActiveViewId);
  const positionPortfolioId = useAppSelector(selectNewPositionPortfolioId);
  const selectedProvider = useAppSelector(selectCustomPositionSelectedProvider);
  const navigate = useNavigate();

  const handleAccountDelete = async () => {
    try {
      await AccountService.deleteAccount(accountForDelete.id);

      await fetchAllAccounts(dispatch);

      if (isMainScreen && accountList.length > 0) {
        dispatch(setActiveAccountId(accountList[0].accountId));
        await fetchAccountBalance(dispatch, accountList[0].accountId, isProView);
      } else if (accountList.length === 0) {
        dispatch(setIsProView(false));
      }

      setAccountForDelete(null);
    } catch (error) {
      logError(error);
    }
  };

  const handleCustomViewDelete = async () => {
    try {
      await CustomViewService.deleteView(customViewForDelete.id);
      await fetchAllCustomViews(dispatch);

      if (activeViewId === customViewForDelete.id) {
        setRedirect(true);
      }

      setCustomViewForDelete(null);
    } catch (error) {
      logError(error);
    }
  };

  const handleExchangeDelete = async () => {
    try {
      await ExchangeService.deleteExchange(exchangeForDelete.id);
      await fetchAllAccounts(dispatch);
      if (isMainScreen) {
        await fetchAccountBalance(dispatch, activeAccountId, isProView);
      } else {
        await fetchCustomViewBalance(dispatch, activeViewId, isProView);
      }

      setExchangeForDelete(null);
    } catch (error) {
      logError(error);
    }
  };

  const handleCustomInstrumentDelete = async () => {
    try {
      await CustomInstrumentService.delete(customInstrumentForDelete.id);
      await dispatch(fetchCustomInstrumentList());
      if (isMainScreen) {
        await fetchAccountBalance(dispatch, activeAccountId, isProView);
      } else {
        await fetchCustomViewBalance(dispatch, activeViewId, isProView);
      }
      setCustomInstrumentForDelete(null);
    } catch (error) {
      logError(error);
    }
  };

  const handlePositionDelete = async () => {
    try {
      await CustomPositionService.deleteCustomPosition(positionForDelete.id);
      if (selectedProvider) {
        await dispatch(fetchPositionsList(selectedProvider.id));
      }
      if (isMainScreen) {
        await fetchAccountBalance(dispatch, activeAccountId, isProView);
      } else {
        await fetchCustomViewBalance(dispatch, activeViewId, isProView);
      }
      setPositionForDelete(null);
    } catch (error) {
      logError(error);
    }
  };

  useEffect(() => {
    setNewViewInputText('');
  }, [newViewMode]);

  let customHeader = null;

  if (screen === ModalScreen.NewAccount) {
    customHeader = <NewAccountScreenHeader />;
  } else if (screen === ModalScreen.ApiKeys) {
    customHeader = <ApiKeysScreenHeader />;
  } else if (screen === ModalScreen.NewCustomInstrument) {
    customHeader = <NewCustomInstrumentScreenHeader />;
  } else if (screen === ModalScreen.NewPosition) {
    customHeader = <NewPositionHeader />;
  } else if (screen === ModalScreen.EditPosition) {
    customHeader = <EditPositionHeader />;
  } else if (screen === ModalScreen.EditCustomInstrument) {
    customHeader = <EditCustomInstrumentHeader />;
  }
  useEffect(() => {
    if (hasRedirect) {
      navigate(`/${MAIN_VIEW_ROUTE}`);
    }
  }, [hasRedirect]);

  return (
    <Modal
      title="My environment"
      customHeader={customHeader}
      isOpen={isOpen}
      onClose={() => {
        dispatch(switchMyEnvironmentModalIsOpen());
      }}
      className={cl(
        (screen === ModalScreen.NewAccount || screen === ModalScreen.ApiKeys) &&
          styles.newAccountScreenModal,
        (screen === ModalScreen.NewCustomInstrument ||
          screen === ModalScreen.NewPosition ||
          screen === ModalScreen.EditCustomInstrument) &&
          styles.newCustomInstumentModal,
        screen === ModalScreen.NewPosition && styles.newPositionModal,
        screen === ModalScreen.EditPosition && styles.newPositionModal
      )}
    >
      {(screen === ModalScreen.Accounts ||
        screen === ModalScreen.CustomViews ||
        screen === ModalScreen.Exchanges ||
        screen === ModalScreen.CustomViewEdit ||
        screen === ModalScreen.CustomInstruments ||
        screen === ModalScreen.Positions) && (
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <ButtonPrimary
              isFullWidth
              text="Accounts"
              onClick={() => dispatch(setMyEnvironmentModalScreen(ModalScreen.Accounts))}
              className={cl(
                styles.sidebarButton,
                screen !== ModalScreen.Accounts &&
                  screen !== ModalScreen.Exchanges &&
                  screen !== ModalScreen.Positions &&
                  styles.sidebarButtonInactive
              )}
            />
            <ButtonPrimary
              isFullWidth
              text="Custom views"
              onClick={() =>
                dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViews))
              }
              className={cl(
                styles.sidebarButton,
                screen !== ModalScreen.CustomViews &&
                  screen !== ModalScreen.CustomViewEdit &&
                  styles.sidebarButtonInactive
              )}
            />
            <ButtonPrimary
              isFullWidth
              text="Custom instruments"
              onClick={() =>
                dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomInstruments))
              }
              className={cl(
                styles.sidebarButton,
                screen !== ModalScreen.CustomInstruments && styles.sidebarButtonInactive
              )}
            />
          </div>
          <div className={styles.manage}>
            {screen === ModalScreen.Accounts && (
              <AccountsScreen
                onDelete={setAccountForDelete}
                setAccountSection={setAccountSection}
              />
            )}
            {screen === ModalScreen.CustomViews && (
              <CustomViewsScreen
                onDelete={setCustomViewForDelete}
                setCustomViewEdit={setCustomViewEdit}
                setNewViewMode={setNewViewMode}
              />
            )}
            {screen === ModalScreen.Exchanges && (
              <ExchangesScreen
                accountSection={accountSection}
                onDelete={setExchangeForDelete}
                setAccountSection={setAccountSection}
              />
            )}
            {screen === ModalScreen.CustomViewEdit && (
              <CustomViewScreenEdit
                customViewEdit={customViewEdit}
                setCustomViewEdit={setCustomViewEdit}
              />
            )}
            {screen === ModalScreen.CustomInstruments && (
              <CustomInstruments
                onDelete={setCustomInstrumentForDelete}
                setCustomViewEdit={setCustomViewEdit}
                setNewViewMode={() => {
                  dispatch(setMyEnvironmentModalScreen(ModalScreen.NewCustomInstrument));
                }}
              />
            )}
            {screen === ModalScreen.Positions && (
              <Positions
                onDelete={setPositionForDelete}
                setCustomViewEdit={setCustomViewEdit}
                setNewViewMode={() => {
                  dispatch(setMyEnvironmentModalScreen(ModalScreen.NewCustomInstrument));
                }}
              />
            )}
          </div>
        </div>
      )}
      {screen === ModalScreen.NewAccount && (
        <NewAccountScreen setAccountSection={setAccountSection} />
      )}
      {screen === ModalScreen.EditPosition && (
        <EditPosition setAccountSection={setAccountSection} />
      )}
      {screen === ModalScreen.ApiKeys && (
        <ApiKeysScreen
          accountSection={accountSection}
          onClose={() => dispatch(switchMyEnvironmentModalIsOpen())}
        />
      )}
      {screen === ModalScreen.NewCustomInstrument && <NewCustomInstrument />}
      {screen === ModalScreen.EditCustomInstrument && <EditCustomInstrument />}
      {screen === ModalScreen.NewPosition && (
        <NewPosition setAccountSection={setAccountSection} />
      )}

      {accountForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete <em>{accountForDelete.accountName}</em>{' '}
                account?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  text="Yes"
                  type="small"
                  onClick={handleAccountDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  text="No"
                  size="small"
                  onClick={() => {
                    setAccountForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {customViewForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete <em>{customViewForDelete.accountName}</em>{' '}
                view?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  type="small"
                  text="Yes"
                  onClick={handleCustomViewDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  size="small"
                  text="No"
                  onClick={() => {
                    setCustomViewForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {exchangeForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete <em>{exchangeForDelete.name}</em>{' '}
                exchange?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  type="small"
                  text="Yes"
                  onClick={handleExchangeDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  size="small"
                  text="No"
                  onClick={() => {
                    setExchangeForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {customInstrumentForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete{' '}
                <em>{customInstrumentForDelete.instrumentName}</em> custom instrument?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  type="small"
                  text="Yes"
                  onClick={handleCustomInstrumentDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  size="small"
                  text="No"
                  onClick={() => {
                    setCustomInstrumentForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {positionForDelete && (
        <div className={styles.deleteBackdrop}>
          <div className={styles.deleteWrapper}>
            <Typography.H2 className={styles.deleteHeading}>
              delete confirmation
            </Typography.H2>
            <div className={styles.deleteMain}>
              <Typography.P1 className={styles.confirmationText}>
                Are you sure you want to delete{' '}
                <em>{positionForDelete.customInstrument.instrumentName}</em> position?
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  type="small"
                  text="Yes"
                  onClick={handlePositionDelete}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  size="small"
                  text="No"
                  onClick={() => {
                    setPositionForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {newViewMode && (
        <div className={styles.deleteBackdrop}>
          <div className={cl(styles.deleteWrapper, styles.newView)}>
            <Typography.H2 className={styles.deleteHeading}>create view</Typography.H2>
            <div className={cl(styles.deleteMain, styles.newViewBottom)}>
              <div>
                <Typography.P1 className={styles.newViewNote}>
                  Custom view’s name
                </Typography.P1>
                <TextInput
                  value={newViewInputText}
                  placeholder="View 1"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewViewInputText(event.target.value);
                  }}
                  className={styles.newViewInput}
                  autoFocus
                />
              </div>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  type="small"
                  text="Cancel"
                  onClick={() => {
                    setNewViewMode(false);
                  }}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  size="small"
                  text="Next"
                  onClick={() => {
                    setCustomViewEdit({ viewName: newViewInputText });
                    setNewViewMode(false);
                    dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViewEdit));
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalMyEnvironment;
