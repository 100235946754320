import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'src/store/store';
import { ContentWrapper } from './components';

import 'src/assets/styles/globals.scss';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ContentWrapper />
      </Provider>
    </BrowserRouter>
  );
};

export default App;
