import React from 'react';
import cl from 'clsx';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/spinner2.svg';
import { Typography } from '../Typography';

import styles from './LoadingIndicator.module.scss';

export type LoadingIndicatorProps = {
  className?: string;
};

const LoadingIndicator = ({ className }: LoadingIndicatorProps) => {
  return (
    <div className={cl(styles.loadingIndicator, className && className)}>
      <SpinnerIcon className={styles.spinner} />
      <Typography.H3 as="p" isUppercase weight={600}>
        Loading
      </Typography.H3>
    </div>
  );
};

export default LoadingIndicator;
