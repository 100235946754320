import React from 'react';
import cl from 'clsx';
import styles from './TextArea.module.scss';

export type TextAreaProps = {
  name?: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hint?: {
    width: number;
    text: string;
  };
  autoFocus?: boolean;
  isPassword?: boolean;
  ref?: (inputElement: HTMLTextAreaElement) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
  inputClassName?: string;
};

const TextArea = ({
  value = '',
  error,
  disabled,
  placeholder,
  autoFocus,
  isPassword,

  onChange,
  className,
  inputClassName,
}: TextAreaProps) => {
  return (
    <>
      <div
        className={cl(
          styles.textInputWrapper,
          disabled && styles.disabled,
          error && styles.error,
          className && className
        )}
      >
        <textarea
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus={autoFocus}
          className={cl(
            styles.textInput,

            inputClassName && inputClassName
          )}
        />
      </div>
    </>
  );
};

export default TextArea;
