import React from 'react';
import cl from 'clsx';

import styles from './ButtonPrimary.module.scss';
import { Typography } from '../Typography';

export type ButtonPrimaryProps = {
  type?: 'submit' | 'button';
  text?: string;
  size?: 'small' | 'middle' | 'large';
  isFullWidth?: boolean;
  isInactive?: boolean;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onClick: () => void;
};

const ButtonPrimary = ({
  type = 'button',
  text,
  size = 'middle',
  isFullWidth = false,
  isInactive = false,
  isDisabled = false,
  icon,
  className,
  onClick,
}: ButtonPrimaryProps) => {
  return (
    <button
      className={cl(
        styles.buttonPrimary,
        styles[size],
        isFullWidth && styles.fullwidth,
        isInactive && styles.inactive,
        isDisabled && styles.disabled,
        icon && styles.withIcon,
        className && className
      )}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {text && text.length > 0 && (
        <Typography.P1 as="span" className={styles.text}>
          {text}
        </Typography.P1>
      )}

      {icon && icon}
    </button>
  );
};

export default ButtonPrimary;
