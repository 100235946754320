import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CustomPositionService,
  GetCustomPositionMetaResponse,
  GetCustomPositionResponse,
} from 'src/services/CustomPosition.service';
import { fetchCustomInstrumentList } from 'src/store/slices/customInstrumentSlice';
import {
  SelectedProvider,
  clearPositionsState,
  editPosition,
  fetchPositionsList,
  managePositions,
  setCustomPositionsMeta,
  setCustomPositionsSelectedProvider,
  setPositionsList,
  setSelectedPosition,
} from 'src/store/slices/positionsSlice';
import { ModalScreen, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { logError } from 'src/utils';

function* managePositionsAsync({ payload }: { payload: SelectedProvider }): Generator {
  try {
    const response = yield call(CustomPositionService.getMeta);
    yield put(setCustomPositionsMeta((response as GetCustomPositionMetaResponse).data));
  } catch (error) {
    logError(error);
  }

  yield put(fetchCustomInstrumentList());
  yield put(setCustomPositionsSelectedProvider(payload));
  yield put(setMyEnvironmentModalScreen(ModalScreen.NewPosition));
}

function* editPositionAsync({
  payload,
}: {
  payload: { selectedProvider: SelectedProvider; selectedPosition: any };
}): Generator {
  try {
    const response = yield call(CustomPositionService.getMeta);
    yield put(setCustomPositionsMeta((response as GetCustomPositionMetaResponse).data));
  } catch (error) {
    logError(error);
  }

  yield put(setCustomPositionsSelectedProvider(payload.selectedProvider));
  yield put(setSelectedPosition(payload.selectedPosition));
  yield put(setMyEnvironmentModalScreen(ModalScreen.EditPosition));
}

function* clearPositionsStateAsync() {
  yield put(setCustomPositionsSelectedProvider(null));
  yield put(setCustomPositionsMeta(null));
}

function* fetchPositionsListAsync({ payload: exchangeId }: { payload: number }) {
  try {
    const response: GetCustomPositionResponse = yield call(
      CustomPositionService.getCustomPositions,
      exchangeId
    );
    yield put(setPositionsList(response.data));
  } catch (error) {
    logError(error);
  }
}

function* watcher() {
  yield takeLatest(managePositions, managePositionsAsync);
  yield takeLatest(editPosition, editPositionAsync);
  yield takeLatest(clearPositionsState, clearPositionsStateAsync);
  yield takeLatest(fetchPositionsList, fetchPositionsListAsync);
}

export default watcher;
