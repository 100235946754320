import { ModalScreen } from 'src/components/ModalMyEnvironment/ModalMyEnvironment';
import { AccountsScreenItem } from 'src/components/ModalMyEnvironment/components/AccountsScreenItem';
import {
  MyEnvironmentCardsHeader,
  MyEnvironmentCardsList,
  EmptyMyEnvironmentScreen,
} from 'src/ui-kit';

import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';

import { selectAccountList } from 'src/store/slices/accountSlice';
import { selectIsDarkTheme, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';

import { hasElements } from 'src/types/data';

type AccounsScreenProps = {
  setAccountSection: React.Dispatch<any>;
  onDelete: (account: any) => void;
};

const AccountsScreen = ({ setAccountSection, onDelete }: AccounsScreenProps) => {
  const dispatch = useAppDispatch();
  const accountList = useAppSelector(selectAccountList);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  const handleButtonClick = () => {
    dispatch(setMyEnvironmentModalScreen(ModalScreen.NewAccount));
  };

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="Account management"
        descriptionText="Each account can be associated with one or multiple providers"
        buttonText="New Account"
        buttonAction={handleButtonClick}
      />
      {hasElements(accountList) ? (
        <MyEnvironmentCardsList isDarkTheme={isDarkTheme}>
          {accountList.map((account, index) => (
            <AccountsScreenItem
              id={account.accountId}
              accountName={account.accountName}
              exchanges={account.exchanges}
              key={`${account.accountId}-${index}`}
              index={index}
              setAccountSection={setAccountSection}
              onDelete={onDelete}
            />
          ))}
        </MyEnvironmentCardsList>
      ) : (
        <EmptyMyEnvironmentScreen
          line1="Please create an account to start."
          line2="Account information will be displayed here."
        />
      )}
    </>
  );
};

export default AccountsScreen;
