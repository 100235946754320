import React from 'react';
import cl from 'clsx';
import { Typography } from '../Typography';
import styles from './Legenda.module.scss';

export type LegendaProps = {
  variant: 'exchanges' | 'portfolio';
  className?: string;
};

const Legenda = ({ variant, className }: LegendaProps) => {
  return (
    <div className={cl(styles.legenda, styles[variant], className && className)}>
      <Typography.Additional as="span" className={styles.text}>
        {variant}
      </Typography.Additional>
    </div>
  );
};

export default Legenda;
