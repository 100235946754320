import { isNumber } from 'lodash';

export const nf2 = {
  format(number: number) {
    return number.toLocaleString('en-UK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
};

export const nf4 = {
  format(number: number) {
    return number.toLocaleString('en-UK', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  },
};

export const nf = (digits: number, isPercent: boolean = false) => ({
  format(number: number) {
    if (isPercent) {
      return (number * 100).toLocaleString('en-UK', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    }

    return number.toLocaleString('en-UK', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  },
});

export const formatBalance = (
  number: number | undefined,
  formatting: number,
  isConditional: boolean,
  isPercent: boolean
) => {
  if (!isNumber(number)) {
    return '--';
  }

  if (isConditional) {
    if (number >= 1) {
      return nf2.format(number);
    }

    return nf4.format(number);
  }

  if (isPercent) {
    return nf(formatting).format(number * 100);
  }

  return nf(formatting).format(number);
};

export const formatMargin = (num: number): string => {
  const formattedNum = num.toFixed(1);
  return `${formattedNum}%`;
};

export const time = Intl.DateTimeFormat('en-GB', {
  dateStyle: 'medium',
  timeStyle: 'medium',
});
