import {
  EmptyMyEnvironmentScreen,
  Kebab,
  MyEnvironmentCardsHeader,
  MyEnvironmentCardsList,
  TextInput,
  TextInputSize,
  ButtonPrimary,
  ButtonSecondary,
} from 'src/ui-kit';

import styles from './CustomInstruments.module.scss';
import { useEffect, useState } from 'react';
import { formatTimeWithoutSeconds, logError, nf2 } from 'src/utils';
import { useDispatch } from 'react-redux';
import {
  fetchCustomInstrumentList,
  selectCustomInstrumentList,
  selectSelectedCustomInstrument,
  setInitialTemplate,
  setMeta,
  setSelectedCustomInstrument,
} from 'src/store/slices/customInstrumentSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsDarkTheme, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { ReactComponent as TickIcon } from 'src/assets/images/buttonTick.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/buttonCross.svg';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/renameSpinner.svg';
import { CustomInstrumentService } from 'src/services/CustomInstrument.service';
import { ModalScreen } from '../../ModalMyEnvironment';

type CustomViewsScreenProps = {
  setCustomViewEdit: React.Dispatch<any>;
  setNewViewMode: React.Dispatch<boolean>;
  onDelete: (account: any) => void;
};

const AccountsScreen = ({ setNewViewMode, onDelete }: CustomViewsScreenProps) => {
  const dispatch = useDispatch();

  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const customInstruments = useAppSelector(selectCustomInstrumentList);

  const handleButtonClick = () => {
    setNewViewMode(true);
  };

  useEffect(() => {
    dispatch(fetchCustomInstrumentList());
  }, []);

  return (
    <>
      <MyEnvironmentCardsHeader
        headingText="CUSTOM instruments"
        descriptionText="Manage your custom instruments."
        buttonText="New instrument"
        buttonAction={handleButtonClick}
      />
      <MyEnvironmentCardsList isDarkTheme={isDarkTheme}>
        {customInstruments.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Type</td>
                <td>Asset</td>
                <td>Noml</td>
                <td>Stl</td>
                <td>Price source</td>
                <td>Expiry (UTC)</td>
                <td>Strike</td>
                <td>Option type</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {customInstruments.map((instrument: any, index: number) => {
                return (
                  <tr key={`${instrument.name}-${index}`}>
                    <td>{instrument.instrumentName}</td>
                    <td>{instrument.instrumentType}</td>
                    <td>
                      {instrument.baseCurrency.ticker}{' '}
                      {instrument.quoteCurrency?.ticker
                        ? `/ ${instrument.quoteCurrency?.ticker}`
                        : ''}
                    </td>
                    <td>{instrument.nominalCurrency?.ticker}</td>
                    <td>{instrument.settlementCurrency?.ticker}</td>
                    <td>
                      {instrument.priceSource === 'DOT' ? 'EXO' : instrument.priceSource}
                    </td>
                    <td>
                      {instrument.expirationDate
                        ? formatTimeWithoutSeconds(instrument.expirationDate)
                        : null}
                    </td>
                    <td>{instrument.strike ? nf2.format(instrument.strike) : ''}</td>
                    <td>{instrument.optionType}</td>
                    <td>
                      <Kebab
                        items={[
                          {
                            title: 'Edit',
                            onClick: async () => {
                              try {
                                const result = await CustomInstrumentService.getTemplate({
                                  instrumentType: instrument.instrumentType,
                                  baseCurrencyId: instrument.baseCurrency.id,
                                  quoteCurrencyId: instrument.quoteCurrency?.id,
                                  settlementCurrencyId: instrument.nominalCurrency?.id,
                                  nominalCurrencyId: instrument.quoteCurrenct?.id,
                                  strike: instrument.strike,
                                  priceSource: instrument.priceSource,
                                  optionType: instrument.optionType,
                                  expiryTimestamp: instrument.expirationDate,
                                });

                                dispatch(setInitialTemplate(result.data));
                              } catch (err) {
                                logError(err);
                              }

                              try {
                                const response: any =
                                  await CustomInstrumentService.getMeta();
                                dispatch(setMeta(response.data));
                              } catch (err) {
                                logError(err);
                              }

                              dispatch(setSelectedCustomInstrument(instrument));
                              dispatch(
                                setMyEnvironmentModalScreen(
                                  ModalScreen.EditCustomInstrument
                                )
                              );
                            },
                          },
                          {
                            title: 'Delete',
                            onClick: () => {
                              onDelete(instrument);
                            },
                          },
                        ]}
                        menuPosition="right"
                        className={styles.kebab}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyMyEnvironmentScreen
            line1="Please add custom instruments to start."
            line2="All information will be displayed here."
          />
        )}
      </MyEnvironmentCardsList>
    </>
  );
};

export default AccountsScreen;
