import React from 'react';
import cl from 'clsx';
import { Typography } from '../Typography';

import styles from './Avatar.module.scss';

type AvatarProps = {
  variant: 0 | 1 | 2 | 3 | 4;
  size: 'large' | 'small';
  firstName: string;
  lastName: string;
  className?: string;
  isCurrentAvatar?: boolean;
};

const Avatar = ({
  variant,
  size = 'large',
  firstName,
  lastName,
  className,
  isCurrentAvatar,
}: AvatarProps) => {
  return (
    <div
      className={cl(
        styles.avatar,
        styles[size],
        styles[`variant${variant}`],
        isCurrentAvatar && styles.isCurrentAvatar,
        className && className
      )}
    >
      {variant === 0 && (
        <Typography.Small className={styles.abbreviation}>
          {firstName[0]}
          {lastName[0]}
        </Typography.Small>
      )}
      {variant === 4 && (
        <Typography.Small className={styles.abbreviation}>
          {firstName[0]}
          {lastName[0]}
        </Typography.Small>
      )}
    </div>
  );
};

export default Avatar;
