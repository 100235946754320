import React from 'react';
import cl from 'clsx';
import { ReactComponent as InfoIcon } from 'src/assets/images/tip.svg';

import styles from './Hint.module.scss';

type HintProps = {
  width: number;
  hint: React.ReactNode;
  position?: 'right-bottom' | 'right-top';
  className?: string;
};

const Hint = ({ width, hint, position = 'right-bottom', className }: HintProps) => {
  return (
    <div className={cl(styles.hint, className && className)}>
      <InfoIcon />
      <div
        className={cl(styles.text, position === 'right-top' && styles.rightTop)}
        style={{ width: `${width}px` }}
      >
        {hint}
      </div>
    </div>
  );
};

export default Hint;
