import React from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsProView } from 'src/store/slices/uiSlice';
import { ProViewDashboard } from '../ProViewDashboard';
import { DashboardDataBasic } from '../DashboardDataBasic';

const DashboardData = () => {
  const isProView = useAppSelector(selectIsProView);

  if (isProView) {
    return <ProViewDashboard />;
  }

  return <DashboardDataBasic />;
};

export default DashboardData;
