import React, { useEffect, useRef, useState } from 'react';
import cl from 'clsx';
import { ReactComponent as ChevronIcon } from 'src/assets/images/chevron.svg';

import styles from './Dropdown.module.scss';
import { truncate } from 'src/utils';
import { Typography } from '../Typography';
import { Scrollbar } from '../Scrollbar';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsDarkTheme } from 'src/store/slices/uiSlice';
import { TextInput, TextInputSize } from '../TextInput';

export type DropdownProps = {
  defaultValue?: any;
  value: DropdownOption | null;
  setValue: (option: DropdownOption | null) => void;
  options: DropdownOption[];
  label: null | string;
  disabled?: boolean;
  filter?: boolean;
  title?: string;
  truncateNumber?: number;
  onChange: (selectedOption: DropdownOption) => void;
  className?: string;
};

export type DropdownOption = {
  value: any;
  label: string;
};

export const EMPTY_VALUE = {
  value: 'empty',
  label: '------',
};

const DropdownMenu: React.FC<DropdownProps> = ({
  defaultValue,
  value,
  setValue,
  options,
  label,
  title,
  disabled = false,
  filter = false,
  truncateNumber = 16,
  onChange,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const menuRef = useRef(null);
  const [textInputText, setTextInputText] = useState('');

  useEffect(() => {
    setTextInputText(value === null ? '' : value.label);
  }, [value]);

  const handleOptionSelect = (option: DropdownOption) => {
    if (option.value === 'empty') {
      return;
    }

    if (filter) {
      setTextInputText(option.label);
    }

    setValue(option);
    setIsOpen(false);
    onChange(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    if (!disabled) {
      if (value) {
        const option = options.find((option) => {
          return option.value === value.value;
        });

        if (option) {
          setValue(option);
        } else {
          setValue(null);
        }
      }

      if (options.length === 1) {
        setValue(options[0]);
      }

      setFilteredOptions(options);
    }
  }, [options]);

  return (
    <>
      {title && (
        <Typography.H3 className={cl(styles.title, disabled && styles.disabledTitle)}>
          {title}
        </Typography.H3>
      )}
      <div className={cl(styles.dropdown, className && className)} ref={menuRef}>
        <div
          className={cl(
            styles.dropdownHeader,
            isOpen && styles.dropdownHeaderOpen,
            disabled && styles.disabled
          )}
          onClick={() => {
            if (!disabled) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <div className={cl(styles.label)}>
            {filter && !disabled ? (
              <TextInput
                size={TextInputSize.Small}
                value={textInputText}
                onChange={(event) => {
                  setTextInputText(event.target.value);
                  const filteredOptions: any = options.filter((option) => {
                    return option.label
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  });

                  setFilteredOptions(filteredOptions);
                }}
                className={styles.textInput}
                dropdownInput
              />
            ) : (
              (value || label) && (
                <Typography.P1 as="span" className={styles.labelText}>
                  {truncate(value?.label || label, truncateNumber)}
                </Typography.P1>
              )
            )}
          </div>
          <ChevronIcon className={cl(styles.arrowDown, isOpen && styles.arrowUp)} />
        </div>
        {isOpen && (
          <div
            className={cl(
              styles.dropdownList,
              options.length > 5 && styles.limitedDropdownList
            )}
          >
            {filter ? (
              <>
                {filteredOptions.length === 0 ? (
                  <div className={cl(styles.dropdownListItem, styles.empty)}>
                    <Typography.P1 as="span" className={styles.optionText}>
                      No results
                    </Typography.P1>
                  </div>
                ) : filteredOptions.length > 5 ? (
                  <Scrollbar
                    height="168px"
                    isDarkTheme={isDarkTheme}
                    trackYRight={5}
                    trackYHeight="calc(100%)"
                  >
                    <div>
                      {filteredOptions.map((option: any, index) => (
                        <div
                          key={`${option.value}-${index}`}
                          className={cl(
                            styles.dropdownListItem,
                            {
                              [styles.selected]: option.value === value?.value,
                            },
                            option.value === 'empty' && styles.empty
                          )}
                          onClick={() => {
                            if (filter) {
                              setTextInputText(option.label);
                            }
                            handleOptionSelect(option);
                          }}
                        >
                          <Typography.P1 as="span" className={styles.optionText}>
                            {truncate(option.label, truncateNumber + 4)}
                          </Typography.P1>
                        </div>
                      ))}
                    </div>
                  </Scrollbar>
                ) : (
                  filteredOptions.map((option: any, index) => (
                    <div
                      key={`${option.value}-${index}`}
                      className={cl(styles.dropdownListItem, {
                        [styles.selected]: option.value === value?.value,
                      })}
                      onClick={() => handleOptionSelect(option)}
                    >
                      <Typography.P1 as="span" className={styles.optionText}>
                        {truncate(option.label, truncateNumber + 4)}
                      </Typography.P1>
                    </div>
                  ))
                )}
                {}
              </>
            ) : (
              <>
                {options.length === 0 ? (
                  <div className={cl(styles.dropdownListItem, styles.empty)}>
                    <Typography.P1 as="span" className={styles.optionText}>
                      No results
                    </Typography.P1>
                  </div>
                ) : options.length > 5 ? (
                  <Scrollbar
                    height="176px"
                    isDarkTheme={isDarkTheme}
                    trackYRight={5}
                    trackYHeight="calc(100%)"
                  >
                    <div>
                      {options.map((option, index) => (
                        <div
                          key={`${option.value}-${index}`}
                          className={cl(
                            styles.dropdownListItem,
                            {
                              [styles.selected]: option.value === value?.value,
                            },
                            option.value === 'empty' && styles.empty
                          )}
                          onClick={() => {
                            if (filter) {
                              setTextInputText(option.label);
                            }

                            handleOptionSelect(option);
                          }}
                        >
                          <Typography.P1 as="span" className={styles.optionText}>
                            {truncate(option.label, truncateNumber + 4)}
                          </Typography.P1>
                        </div>
                      ))}
                    </div>
                  </Scrollbar>
                ) : (
                  options.map((option, index) => (
                    <div
                      key={`${option.value}-${index}`}
                      className={cl(styles.dropdownListItem, {
                        [styles.selected]: option.value === value?.value,
                      })}
                      onClick={() => {
                        if (filter) {
                          setTextInputText(option.label);
                        }

                        handleOptionSelect(option);
                      }}
                    >
                      <Typography.P1 as="span" className={styles.optionText}>
                        {truncate(option.label, truncateNumber + 4)}
                      </Typography.P1>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
