import React from 'react';
import cl from 'clsx';
import { Typography } from 'src/ui-kit/Typography';

import styles from './TableHead.module.scss';

export type TableHeadProps = {
  text: string;
  hasBorderRadius: boolean;
  isNameCell: boolean;
  isFixed: boolean;
  isWide: boolean;
  controls?: {
    expandAll?: () => void;
    collapseAll?: () => void;
  };
  isStandardTable: boolean;
  fiat: boolean;
  className?: string;
};

const TableHead = ({
  text,
  hasBorderRadius,
  isNameCell,
  isFixed,
  isWide,
  controls,
  isStandardTable,
  fiat,
  className,
}: TableHeadProps) => {
  return (
    <div
      className={cl(
        styles.tableHead,
        isFixed && styles.fixed,
        isNameCell && styles.nameCell,
        hasBorderRadius && styles.withBorderRadius,
        isStandardTable && styles.standardCell,
        (isWide || fiat) && styles.wide,
        className && className
      )}
    >
      <div className={cl(styles.contentWrapper, controls && styles.controlCell)}>
        <Typography.P1 as="span" color="purple" weight={500} className={styles.text}>
          {text}
        </Typography.P1>
        {controls && (
          <div className={styles.buttonsWrapper}>
            <button onClick={controls.expandAll} className={styles.expandButton}>
              <Typography.Additional
                as="span"
                color="secondary"
                className={styles.expandButtonText}
              >
                expand all
              </Typography.Additional>
            </button>
            <button onClick={controls.collapseAll} className={styles.expandButton}>
              <Typography.Additional
                as="span"
                color="secondary"
                className={styles.expandButtonText}
              >
                collapse all
              </Typography.Additional>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableHead;
