import { createAction, createSlice } from '@reduxjs/toolkit';
import { Account } from 'src/types/account';
import { AccountBalance } from 'src/types/balance';
import { formatBalance, nf2, nf4 } from 'src/utils';
import type { RootState } from '../store';
import { cloneDeep, isBoolean, isNumber, orderBy } from 'lodash';

type AccountState = {
  list: Account[];
  activeAccountId: null | number;
  balance: AccountBalance | null;
  balance2: AccountBalance | null;
  proViewBalance: any;
  filters: any;
  proViewColumnsFilter: any;
  isFetchingBalances: boolean;
};

const initialColumnsFilter = localStorage.getItem('proViewColumnsFilter');

const initialState: AccountState = {
  list: [],
  activeAccountId: null,
  balance: null,
  balance2: null,
  proViewBalance: null,
  filters: {
    coins: {},
    exchanges: {},
  },
  proViewColumnsFilter: initialColumnsFilter ? JSON.parse(initialColumnsFilter) : [],
  isFetchingBalances: true,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountList: (state, action) => {
      state.list = action.payload;
    },
    setActiveAccountId: (state, action) => {
      state.activeAccountId = action.payload;
    },
    setAccountBalance: (state, action) => {
      state.balance = action.payload;
    },
    setAccountBalance2: (state, action) => {
      state.balance2 = action.payload;
    },
    renameAccount: (state, action) => {
      const { id, ...rest } = action.payload;

      const index = state.list.findIndex((item) => {
        return item.accountId === id;
      });

      state.list[index] = {
        ...state.list[index],
        ...rest,
      };
    },
    deleteAccount: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((item) => {
        return item.accountId === id;
      });

      state.list.splice(index, 1);
    },
    addAccount: (state, action) => {
      state.list.push(action.payload);
    },
    setProViewBalance: (state, action) => {
      return {
        ...state,
        proViewBalance: action.payload,
      };
    },
    setProViewBalance2: (state, action) => {
      return {
        ...state,
        proViewBalance: action.payload,
      };
    },
    setIsFetchingBalances: (state, action) => {
      state.isFetchingBalances = action.payload;
    },
    setProViewColumnsFilter: (state, action) => {
      state.proViewColumnsFilter = action.payload;
    },
    changeProViewColumnsFilter: (state, action) => {
      state.proViewColumnsFilter = state.proViewColumnsFilter.reduce(
        (acc: any, current: any) => {
          if (current.key === action.payload.name) {
            current.isChecked = action.payload.isChecked;
          }
          acc.push(current);

          return acc;
        },
        []
      );
      localStorage.setItem(
        'proViewColumnsFilter',
        JSON.stringify(state.proViewColumnsFilter)
      );
    },
    setInitialFilters: (state) => {
      state.filters = {
        coins: {},
        exchanges: {},
      };

      if (state.proViewBalance) {
        const index = state.proViewBalance.findIndex((accountData: any) => {
          return accountData.accountId === state.activeAccountId;
        });

        state.proViewBalance[index]?.metadata.coinFilters.forEach((coinName: string) => {
          state.filters.coins[coinName] = true;
        });

        state.proViewBalance[index]?.metadata.exchangeFilters.forEach(
          (exchangeName: string) => {
            state.filters.exchanges[exchangeName] = true;
          }
        );
      }
    },
    updateFilters: (state) => {
      const filters: {
        coins: Record<string, boolean>;
        exchanges: Record<string, boolean>;
      } = {
        coins: {},
        exchanges: {},
      };

      if (state.proViewBalance) {
        const index = state.proViewBalance.findIndex((accountData: any) => {
          return accountData.accountId === state.activeAccountId;
        });

        if (state.proViewBalance[index]) {
          state.proViewBalance[index].metadata.coinFilters.forEach((coinName: string) => {
            filters.coins[coinName] = true;
          });

          state.proViewBalance[index].metadata.exchangeFilters.forEach(
            (exchangeName: string) => {
              filters.exchanges[exchangeName] = true;
            }
          );

          Object.keys(filters.coins).forEach((coinName: string) => {
            if (isBoolean(state.filters.coins[coinName])) {
              filters.coins[coinName] = state.filters.coins[coinName];
            }
          });

          Object.keys(filters.exchanges).forEach((exchangeName: string) => {
            if (isBoolean(state.filters.exchanges[exchangeName])) {
              filters.exchanges[exchangeName] = state.filters.exchanges[exchangeName];
            }
          });
        }
      }

      state.filters = filters;
    },
    switchCoinFilter: (state, action) => {
      state.filters.coins[action.payload] = !state.filters.coins[action.payload];
    },
    switchExchangeFilter: (state, action) => {
      state.filters.exchanges[action.payload] = !state.filters.exchanges[action.payload];
    },
    setColumnsToDefault: (state) => {
      state.proViewColumnsFilter = state.proViewColumnsFilter.map((columnData: any) => {
        return {
          key: columnData.key,
          name: columnData.name,
          isChecked: columnData.isDefault || columnData.isAttached,
          isDefault: columnData.isDefault,
          isAttached: columnData.isAttached,
          isColorized: columnData.isColorized,
          isWide: columnData.isWide,
          grouping: columnData.grouping,
          percent: columnData.percent,
          fiat: columnData.fiat,
        };
      });

      localStorage.setItem(
        'proViewColumnsFilter',
        JSON.stringify(state.proViewColumnsFilter)
      );
    },
    selectAllCoins: (state) => {
      Object.keys(state.filters.coins).forEach((ticker) => {
        state.filters.coins[ticker] = true;
      });
    },
    deselectAllCoins: (state) => {
      Object.keys(state.filters.coins).forEach((ticker) => {
        state.filters.coins[ticker] = false;
      });
    },
  },
});

export const {
  setAccountList,
  setActiveAccountId,
  setAccountBalance,
  renameAccount,
  deleteAccount,
  addAccount,
  setProViewBalance,
  setProViewColumnsFilter,
  changeProViewColumnsFilter,
  setInitialFilters,
  updateFilters,
  switchCoinFilter,
  switchExchangeFilter,
  setColumnsToDefault,
  selectAllCoins,
  deselectAllCoins,
  setIsFetchingBalances,
  setAccountBalance2,
  setProViewBalance2,
} = accountSlice.actions;
export const getAccountBalance2 = createAction<number>(
  `${accountSlice.name}/getAccountBalance2`
);
export const selectAccountList = (state: RootState) => state.account.list;
export const selectActiveAccountId = (state: RootState) => state.account.activeAccountId;
export const selectActiveAccount = (state: RootState) =>
  state.account.list.find((item) => item.accountId === state.account.activeAccountId);
export const selectAccountBalance = (state: RootState) => state.account.balance;
export const selectAccountBalanceTotal = (state: RootState) => {
  return (
    state.account.balance?.exchangeBalances?.reduce((total, exchangeBalance) => {
      return (
        total +
        exchangeBalance.portfolioBalances.reduce((portfolioTotal, portfolioBalance) => {
          return (
            portfolioTotal +
            portfolioBalance.balanceSections.reduce((sectionTotal, balanceSection) => {
              return (
                sectionTotal +
                balanceSection.balances.reduce((balanceTotal, balance) => {
                  return balanceTotal + balance.amountUsdValue;
                }, 0)
              );
            }, 0)
          );
        }, 0)
      );
    }, 0) ?? 0
  );
};
export const selectAccountProBalanceTotal = (state: RootState) => {
  if (state.account.proViewBalance) {
    return (
      state.account.proViewBalance[0].exchangeBalances?.reduce(
        (total: any, exchangeBalance: any) => {
          return (
            total +
            exchangeBalance.portfolioBalances.reduce(
              (portfolioTotal: any, portfolioBalance: any) => {
                return (
                  portfolioTotal +
                  portfolioBalance.balanceSections.reduce(
                    (sectionTotal: any, balanceSection: any) => {
                      let blTotal = 0;
                      if (balanceSection.balances) {
                        blTotal = balanceSection.balances?.reduce(
                          (balanceTotal: any, balance: any) => {
                            return balanceTotal + balance.usdPrice;
                          },
                          0
                        );
                      }

                      let exTotal = 0;

                      if (balanceSection.expires) {
                        exTotal = balanceSection.expires?.reduce(
                          (expireTotal: any, expire: any) => {
                            return (
                              expireTotal +
                              expire.balances?.reduce(
                                (balanceTotal: any, balance: any) => {
                                  return balanceTotal + balance.usdPrice;
                                },
                                0
                              )
                            );
                          },
                          0
                        );
                      }

                      return sectionTotal + blTotal + exTotal;
                    },
                    0
                  )
                );
              },
              0
            )
          );
        },
        0
      ) ?? 0
    );
  }

  return 0;
};

export const selectAccountBalanceExchangesExposure = (state: RootState) => {
  let result: any = [];
  const dict: any = {};
  let totalValue = 0;

  state.account.balance?.exchangeBalances?.flatMap((exchangeBalance) =>
    exchangeBalance.portfolioBalances.flatMap((portfolioBalance) =>
      portfolioBalance.balanceSections.flatMap((balanceSection) =>
        balanceSection.balances.map((balance) => {
          if (balance.amountUsdValue >= 0) {
            if (exchangeBalance.exchangeType === 'CUSTOM') {
              if (dict[exchangeBalance.exchangeName]) {
                dict[exchangeBalance.exchangeName] += balance.amountUsdValue;
              } else {
                dict[exchangeBalance.exchangeName] = balance.amountUsdValue;
              }
            } else {
              if (dict[exchangeBalance.exchangeType]) {
                dict[exchangeBalance.exchangeType] += balance.amountUsdValue;
              } else {
                dict[exchangeBalance.exchangeType] = balance.amountUsdValue;
              }
            }

            totalValue += balance.amountUsdValue;
          }
        })
      )
    )
  );

  Object.keys(dict).forEach((key) => {
    result.push({
      exchangeType: key,
      percentOfTotal: (dict[key] / totalValue) * 100,
    });
  });

  result = orderBy(result, ['percentOfTotal'], ['desc']);

  result = result.reduce((accumulator: any, currentValue: any, index: number) => {
    if (index < 6) {
      accumulator.push(currentValue);
    } else if (index === 6) {
      currentValue.currencyTicker = 'Other';
      accumulator.push(currentValue);
    } else {
      accumulator[6].percentOfTotal += currentValue.percentOfTotal;
    }

    return accumulator;
  }, []);

  return result;
};

export const selectAccountBalanceAssetAllocation = (state: RootState) => {
  const dict: Record<string, number> = {};
  let totalValue = 0;

  state.account.balance?.exchangeBalances?.flatMap((exchangeBalance) =>
    exchangeBalance.portfolioBalances.flatMap((portfolioBalance) =>
      portfolioBalance.balanceSections.flatMap((balanceSection) =>
        balanceSection.balances.forEach((balance) => {
          if (balance.amountUsdValue >= 0) {
            const { ticker } = balance.instrument.baseCurrency;
            dict[ticker] ??= 0;
            dict[ticker] += balance.amountUsdValue;
            totalValue += balance.amountUsdValue;
          }
        })
      )
    )
  );

  const result = Object.entries(dict)
    .map(([currencyTicker, value]) => ({
      currencyTicker,
      percentOfTotal: (value / totalValue) * 100,
    }))
    .filter(({ percentOfTotal }) => percentOfTotal > 0)
    .sort(({ percentOfTotal: a }, { percentOfTotal: b }) => b - a)
    .reduce((accumulator: any, currentValue: any, index) => {
      if (index < 5) {
        accumulator.push(currentValue);
      } else if (index === 5) {
        accumulator.push({ currencyTicker: 'Other', percentOfTotal: 0 });
        accumulator[5].percentOfTotal = currentValue.percentOfTotal;
      } else {
        accumulator[5].percentOfTotal += currentValue.percentOfTotal;
      }
      return accumulator;
    }, []);

  return result;
};

export const selectStandardAccountBalanceTableData = (state: RootState) => {
  const exchanges = state.account.balance?.exchangeBalances || [];
  return exchanges.flatMap(({ exchangeId, exchangeName, portfolioBalances }) => {
    const portfolios = portfolioBalances.flatMap(
      ({ portfolioId, portfolioName, balanceSections }) => {
        const balances = balanceSections.flatMap(({ sectionName, balances }) => {
          return balances.map(({ instrument, amountUsdValue }) => ({
            id: `balance-${instrument.baseCurrency}-${instrument.instrumentName}-${instrument.instrumentType}`,
            cells: [amountUsdValue, 'bar', 'cux', 'zen'],
            nestedDataName: 'balances',
          }));
        });

        return {
          id: `portfolio-${portfolioId}`,
          cells: [portfolioName, 'bar', 'cux', 'zen'],
          nestedDataName: 'balances',
          balances,
        };
      }
    );

    return {
      id: `exchange-${exchangeId}`,
      cells: [exchangeName, 'bar', 'cux', 'zen'],
      nestedDataName: 'portfolios',
      portfolios,
    };
  });
};

export const selectAccountTableData = (state: RootState) => {
  const result: any = new Map();

  if (!state.account.balance || !state.account.balance.exchangeBalances) {
    return result;
  }

  state.account.balance.exchangeBalances.forEach(
    ({ exchangeId, exchangeName, exchangeType, portfolioBalances }) => {
      const exchangeBalanceRow = {
        id: `exchange-${exchangeId}`,
        parents: [],
        childrensNumber: 0,
        level: 0,
        isOpen: false,
        type: 'exchange',
        cells: [
          { text: exchangeName, platform: exchangeType },
          { text: '--' },
          { text: '--' },
          { text: '--' },
        ],
      };

      result.set(exchangeBalanceRow.id, exchangeBalanceRow);

      let totalPortfoliosValue = 0;

      portfolioBalances.forEach(
        ({ portfolioId, portfolioName, balanceSections }, index) => {
          exchangeBalanceRow.childrensNumber++;
          const portfolioBalanceRow = {
            id: `portfolio-${portfolioId}`,
            parentId: `exchange-${exchangeId}`,
            parents: [`exchange-${exchangeId}`],
            childrensNumber: 0,
            level: 1,
            isOpen: false,
            type: 'portfolio',
            cells: [
              { text: portfolioName },
              { text: '--' },
              { text: '--' },
              { text: '--' },
            ],
          };

          result.set(portfolioBalanceRow.id, portfolioBalanceRow);

          let totalBalanceSectionsValue = 0;
          let balancesCount = 0;

          balanceSections.forEach(
            ({ sectionName, initialMargin, maintenanceMargin, balances }, index) => {
              portfolioBalanceRow.childrensNumber++;
              const balanceSectionRow = {
                id: `balance-section-${portfolioId}-${sectionName}`,
                parentId: `portfolio-${portfolioId}`,
                parents: [`exchange-${exchangeId}`, `portfolio-${portfolioId}`],
                childrensNumber: 0,
                level: 2,
                isOpen: false,
                type: 'balance_section',
                cells: [
                  { text: sectionName, initialMargin, maintenanceMargin },
                  { text: '--' },
                  { text: '--' },
                  { text: '--' },
                ],
              };

              result.set(balanceSectionRow.id, balanceSectionRow);

              let totalBalancesValue = 0;

              balances.forEach(
                ({ amount, usdPrice, amountUsdValue, instrument }, index) => {
                  balancesCount++;
                  balanceSectionRow.childrensNumber++;
                  const balanceRow = {
                    id: `balance-${portfolioId}-${sectionName}-${instrument.instrumentName}-${index}`,
                    parentId: `balance-section-${portfolioId}-${sectionName}`,
                    parents: [
                      `exchange-${exchangeId}`,
                      `portfolio-${portfolioId}`,
                      `balance-section-${portfolioId}-${sectionName}`,
                    ],
                    level: 3,
                    isOpen: false,
                    type: 'balance',
                    cells: [
                      {
                        text: instrument.instrumentName,
                        pictureLink: instrument.baseCurrency.pictureLink,
                      },
                      { text: nf4.format(amount), fullNumber: amount },
                      { text: nf4.format(usdPrice), fullNumber: usdPrice },
                      { text: nf2.format(amountUsdValue), fullNumber: amountUsdValue },
                    ],
                  };

                  result.set(balanceRow.id, balanceRow);

                  totalBalancesValue += amountUsdValue;

                  if (index === balances.length - 1) {
                    result.set(balanceSectionRow.id, {
                      ...balanceSectionRow,
                      cells: [
                        { text: sectionName, initialMargin, maintenanceMargin },
                        { text: '--' },
                        { text: '--' },
                        {
                          text: nf2.format(totalBalancesValue),
                          fullNumber: totalBalancesValue,
                        },
                      ],
                    });
                  }
                }
              );

              totalBalanceSectionsValue += totalBalancesValue;

              if (index === balanceSections.length - 1) {
                result.set(portfolioBalanceRow.id, {
                  ...portfolioBalanceRow,
                  cells: [
                    { text: portfolioName },
                    { text: '--' },
                    { text: '--' },
                    {
                      text: nf2.format(totalBalanceSectionsValue),
                      fullNumber: totalBalanceSectionsValue,
                    },
                  ],
                });
              }
            }
          );

          totalPortfoliosValue += totalBalanceSectionsValue;

          if (index === portfolioBalances.length - 1) {
            result.set(exchangeBalanceRow.id, {
              ...exchangeBalanceRow,
              cells: [
                { text: exchangeName, platform: exchangeType },
                { text: '--' },
                { text: '--' },
                {
                  text: nf2.format(totalPortfoliosValue),
                  fullNumber: totalPortfoliosValue,
                },
              ],
            });
          }

          if (balancesCount === 0) {
            if (!state.ui.tableFilters.controls['Hide empty folders']) {
              result.set(portfolioBalanceRow.id, {
                id: `empty-portfolio-${portfolioId}`,
                parentId: `exchange-${exchangeId}`,
                parents: [`exchange-${exchangeId}`],
                level: 1,
                isOpen: false,
                type: 'portfolio',
                cells: [
                  { text: portfolioName },
                  { text: '--' },
                  { text: '--' },
                  { text: '--' },
                ],
              });
            } else {
              result.delete(portfolioBalanceRow.id);
            }
          }
        }
      );
    }
  );

  return result;
};

export const selectProViewBalance = (state: RootState) => {
  const result: any = {
    columns: [],
    rows: new Map(),
  };

  if (!state.account.proViewBalance) {
    return result;
  }

  if (!state.account.proViewColumnsFilter.filter) {
    return result;
  }

  state.account.proViewColumnsFilter
    .filter((item: any) => item.isChecked)
    .forEach((item: any) => {
      result.columns.push({
        name: item.name,
        formatting: item.formatting,
        isWide: item.isWide,
        grouping: item.grouping,
        percent: item.percent,
        fiat: item.fiat,
      });
    });

  const cells = state.account.proViewColumnsFilter
    .filter((item: any) => item.isChecked)
    .map((item: any) => ({
      text: '--',
      isColorized: item.isColorized,
      formatting: item.formatting,
      isWide: item.isWide,
      fullNumber: null,
      percent: item.percent,
      fiat: item.fiat,
    }));

  const balanceIndex = state.account.proViewBalance.findIndex((accountData: any) => {
    return accountData.accountId === state.account.activeAccountId;
  });

  if (!state.account.proViewBalance[balanceIndex]) {
    return result;
  }

  const totalCells = cloneDeep(cells);
  totalCells[0].text = 'TOTAL';

  const totalRow = {
    id: 'total',
    parents: [],
    childrensNumber: 0,
    level: 0,
    isOpen: false,
    type: 'total',
    cells: totalCells,
  };

  result.rows.set('total', totalRow);

  state.account.proViewBalance[balanceIndex]?.exchangeBalances?.forEach(
    ({ exchangeId, exchangeName, exchangeType, portfolioBalances }: any) => {
      if (state.account.filters.exchanges[exchangeName]) {
        const exchangeCells = [...cells];
        exchangeCells[0] = { text: exchangeName, platform: exchangeType };
        const exchangeBalanceRow = {
          id: `exchange-${exchangeId}`,
          parents: [],
          childrensNumber: 0,
          level: 0,
          isOpen: false,
          type: 'exchange',
          cells: exchangeCells,
        };

        result.rows.set(exchangeBalanceRow.id, exchangeBalanceRow);

        let notFilteredExchanges = 0;

        portfolioBalances?.forEach(
          ({ portfolioId, portfolioName, balanceSections }: any, index: number) => {
            exchangeBalanceRow.childrensNumber++;
            const portfolioCells = [...cells];
            portfolioCells[0] = { text: portfolioName };
            const portfolioBalanceRow = {
              id: `portfolio-${portfolioId}`,
              parentId: `exchange-${exchangeId}`,
              parents: [`exchange-${exchangeId}`],
              childrensNumber: 0,
              level: 1,
              isOpen: false,
              type: 'portfolio',
              cells: portfolioCells,
            };

            result.rows.set(portfolioBalanceRow.id, portfolioBalanceRow);

            let notFilteredPortfolioBalances = 0;

            balanceSections?.forEach(
              (
                {
                  sectionName,
                  initialMargin,
                  maintenanceMargin,
                  balances,
                  expires,
                  breakEvenVol: balanceSectionBreakEvenVol,
                }: any,
                index: number
              ) => {
                portfolioBalanceRow.childrensNumber++;
                const balanceSectionsCells = [...cells];
                balanceSectionsCells[0] = {
                  text: sectionName,
                  initialMargin,
                  maintenanceMargin,
                };

                const balanceSectionRow = {
                  id: `balance-section-${portfolioId}-${sectionName}`,
                  parentId: `portfolio-${portfolioId}`,
                  parents: [`exchange-${exchangeId}`, `portfolio-${portfolioId}`],
                  childrensNumber: 0,
                  level: 2,
                  isOpen: false,
                  isBold:
                    state.account.proViewBalance[
                      balanceIndex
                    ]?.metadata.boldSumList.includes('balanceSections'),
                  type: 'balance_section',
                  cells: balanceSectionsCells,
                };

                result.rows.set(balanceSectionRow.id, balanceSectionRow);

                let notFilteredBalances = 0;
                let notFilteredExpires = 0;

                expires?.forEach(
                  (
                    { sectionName: expiresSectionName, balances, breakEvenVol }: any,
                    index: number
                  ) => {
                    balanceSectionRow.childrensNumber++;
                    const expiresCells = [...cells];
                    expiresCells[0] = { text: expiresSectionName };

                    const expiresRow = {
                      id: `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                      parentId: `balance-section-${portfolioId}-${sectionName}`,
                      parents: [
                        `exchange-${exchangeId}`,
                        `portfolio-${portfolioId}`,
                        `balance-section-${portfolioId}-${sectionName}`,
                      ],
                      childrensNumber: 0,
                      level: 3,
                      isOpen: false,
                      type: 'expiry',
                      cells: expiresCells,
                    };

                    result.rows.set(expiresRow.id, expiresRow);

                    let notFilteredExpiresBalances = 0;

                    balances?.forEach((balance: any, index: number) => {
                      if (
                        state.account.filters.coins[
                          balance.instrument.baseCurrency.ticker
                        ]
                      ) {
                        expiresRow.childrensNumber++;
                        notFilteredExpiresBalances++;

                        const balanceCells = [...cells];
                        balanceCells[0] = {
                          text: balance.instrument.instrumentName,
                          pictureLink: balance.instrument.baseCurrency.pictureLink,
                        };

                        state.account.proViewColumnsFilter
                          .filter((item: any) => item.isChecked)
                          .forEach((column: any, index: number) => {
                            const isHighlighted =
                              state.ui.tableFilters.controls['Highlight ITM options'] &&
                              ((balance.instrument.optionType === 'CALL' &&
                                balance.forwardPrice > balance.instrument.strike) ||
                                (balance.instrument.optionType === 'PUT' &&
                                  balance.forwardPrice < balance.instrument.strike));

                            if (column.name === 'Name') {
                              balanceCells[index].isHighlighted = isHighlighted;
                              return;
                            }

                            if (column.fiat) {
                              let fullNumber = totalCells[index].fullNumber;

                              if (isNumber(balance[column.key])) {
                                fullNumber += balance[column.key];
                              }

                              totalCells[index] = {
                                ...totalCells[index],
                                isColorized: column.isColorized,
                                isWide: column.isWide,
                                text: isNumber(fullNumber)
                                  ? formatBalance(
                                      totalCells[index].fullNumber + balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    )
                                  : formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                fullNumber: fullNumber,
                              };
                            }

                            balanceCells[index] = {
                              ...balanceCells[index],
                              text: formatBalance(
                                balance[column.key],
                                column.formatting,
                                column.condNumFormatting,
                                column.percent
                              ),
                              isColorized: column.isColorized,
                              isWide: column.isWide,
                              fullNumber: column.percent
                                ? isNumber(balance[column.key])
                                  ? balance[column.key] * 100
                                  : balance[column.key]
                                : balance[column.key],
                              isHighlighted,
                            };

                            if (
                              column.grouping.expires === 'Sum' &&
                              isNumber(balance[column.key])
                            ) {
                              if (expiresCells[index].fullNumber === null) {
                                expiresCells[index] = {
                                  ...expiresCells[index],
                                  text: formatBalance(
                                    balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: balance[column.key],
                                };
                              } else {
                                const sum =
                                  expiresCells[index].fullNumber + balance[column.key];

                                expiresCells[index] = {
                                  ...expiresCells[index],
                                  text: formatBalance(
                                    sum,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),
                                  fullNumber: sum,
                                };
                              }

                              if (column.grouping.balanceSections === 'Sum') {
                                if (balanceSectionsCells[index].fullNumber === null) {
                                  balanceSectionsCells[index] = {
                                    ...balanceSectionsCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),

                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    balanceSectionsCells[index].fullNumber +
                                    balance[column.key];

                                  balanceSectionsCells[index] = {
                                    ...balanceSectionsCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }

                              if (column.grouping.portfolioBalances === 'Sum') {
                                if (portfolioCells[index].fullNumber === null) {
                                  portfolioCells[index] = {
                                    ...portfolioCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),

                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    portfolioCells[index].fullNumber +
                                    balance[column.key];

                                  portfolioCells[index] = {
                                    ...portfolioCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }

                              if (column.grouping.exchangeBalances === 'Sum') {
                                if (exchangeCells[index].fullNumber === null) {
                                  exchangeCells[index] = {
                                    ...exchangeCells[index],
                                    text: formatBalance(
                                      balance[column.key],
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),

                                    fullNumber: balance[column.key],
                                  };
                                } else {
                                  const sum =
                                    exchangeCells[index].fullNumber + balance[column.key];

                                  exchangeCells[index] = {
                                    ...exchangeCells[index],
                                    text: formatBalance(
                                      sum,
                                      column.formatting,
                                      column.condNumFormatting,
                                      column.percent
                                    ),
                                    fullNumber: sum,
                                  };
                                }
                              }
                            } else if (column.key === 'volatility') {
                              if (breakEvenVol !== null) {
                                expiresCells[index] = {
                                  ...expiresCells[index],
                                  text: formatBalance(
                                    breakEvenVol / 100,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),
                                  fullNumber: breakEvenVol,
                                };
                              }

                              if (balanceSectionBreakEvenVol !== null) {
                                balanceSectionsCells[index] = {
                                  ...balanceSectionsCells[index],
                                  text: formatBalance(
                                    balanceSectionBreakEvenVol / 100,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),
                                  fullNumber: balanceSectionBreakEvenVol,
                                };
                              }
                            }
                          });

                        const balanceRow = {
                          id: `balance-${portfolioId}-${sectionName}-${expiresSectionName}-${balance.instrument.instrumentName}-${index}`,
                          parentId: `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                          parents: [
                            `exchange-${exchangeId}`,
                            `portfolio-${portfolioId}`,
                            `balance-section-${portfolioId}-${sectionName}`,
                            `expiry-${portfolioId}-${sectionName}-${expiresSectionName}`,
                          ],
                          level: 4,
                          isOpen: false,
                          type: 'balance',
                          cells: balanceCells,
                        };

                        result.rows.set(balanceRow.id, balanceRow);
                      }
                    });

                    if (notFilteredExpiresBalances === 0) {
                      result.rows.delete(expiresRow.id);
                    } else {
                      notFilteredExpires++;
                    }
                  }
                );

                balances.forEach((balance: any, index: number) => {
                  if (
                    state.account.filters.coins[balance.instrument.baseCurrency.ticker]
                  ) {
                    balanceSectionRow.childrensNumber++;
                    notFilteredBalances++;

                    const balanceCells = [...cells];

                    state.account.proViewColumnsFilter
                      .filter((item: any) => item.isChecked)
                      .forEach((column: any, index: number) => {
                        const isHighlighted =
                          state.ui.tableFilters.controls['Highlight ITM options'] &&
                          ((balance.instrument.optionType === 'CALL' &&
                            balance.forwardPrice > balance.instrument.strike) ||
                            (balance.instrument.optionType === 'PUT' &&
                              balance.forwardPrice < balance.instrument.strike));
                        if (column.key === 'instrumentName') {
                          balanceCells[index] = {
                            text: balance.instrument.instrumentName,
                            pictureLink: balance.instrument.baseCurrency.pictureLink,
                            isColorized: column.isColorized,
                            isWide: column.isWide,
                            isHighlighted,
                          };
                        } else {
                          if (column.fiat) {
                            let fullNumber = totalCells[index].fullNumber;

                            if (isNumber(balance[column.key])) {
                              fullNumber += balance[column.key];
                            }

                            totalCells[index] = {
                              ...totalCells[index],
                              isColorized: column.isColorized,
                              isWide: column.isWide,
                              text: isNumber(fullNumber)
                                ? formatBalance(
                                    totalCells[index].fullNumber + balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  )
                                : formatBalance(
                                    balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),
                              fullNumber: fullNumber,
                            };
                          }

                          balanceCells[index] = {
                            ...balanceCells[index],
                            text: formatBalance(
                              balance[column.key],
                              column.formatting,
                              column.condNumFormatting,
                              column.percent
                            ),
                            isColorized: column.isColorized,
                            isWide: column.isWide,
                            fullNumber: column.percent
                              ? isNumber(balance[column.key])
                                ? balance[column.key] * 100
                                : balance[column.key]
                              : balance[column.key],
                            isHighlighted,
                          };

                          if (
                            column.grouping.balances === 'Sum' &&
                            isNumber(balance[column.key])
                          ) {
                            if (column.grouping.balanceSections === 'Sum') {
                              if (balanceSectionsCells[index].fullNumber === null) {
                                balanceSectionsCells[index] = {
                                  ...balanceSectionsCells[index],
                                  text: formatBalance(
                                    balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: balance[column.key],
                                };
                              } else {
                                const sum =
                                  balanceSectionsCells[index].fullNumber +
                                  balance[column.key];

                                balanceSectionsCells[index] = {
                                  ...balanceSectionsCells[index],
                                  text: formatBalance(
                                    sum,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: sum,
                                };
                              }
                            }

                            if (column.grouping.portfolioBalances === 'Sum') {
                              if (portfolioCells[index].fullNumber === null) {
                                portfolioCells[index] = {
                                  ...portfolioCells[index],
                                  text: formatBalance(
                                    balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: balance[column.key],
                                };
                              } else {
                                const sum =
                                  portfolioCells[index].fullNumber + balance[column.key];

                                portfolioCells[index] = {
                                  ...portfolioCells[index],
                                  text: formatBalance(
                                    sum,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: sum,
                                };
                              }
                            }

                            if (column.grouping.exchangeBalances === 'Sum') {
                              if (exchangeCells[index].fullNumber === null) {
                                exchangeCells[index] = {
                                  ...exchangeCells[index],
                                  text: formatBalance(
                                    balance[column.key],
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),

                                  fullNumber: balance[column.key],
                                };
                              } else {
                                const sum =
                                  exchangeCells[index].fullNumber + balance[column.key];

                                exchangeCells[index] = {
                                  ...exchangeCells[index],
                                  text: formatBalance(
                                    sum,
                                    column.formatting,
                                    column.condNumFormatting,
                                    column.percent
                                  ),
                                  fullNumber: sum,
                                };
                              }
                            }
                          }
                        }
                      });

                    const balanceRow = {
                      id: `balance-${portfolioId}-${sectionName}-${balance.instrument.instrumentName}-${index}`,
                      parentId: `balance-section-${portfolioId}-${sectionName}`,
                      parents: [
                        `exchange-${exchangeId}`,
                        `portfolio-${portfolioId}`,
                        `balance-section-${portfolioId}-${sectionName}`,
                      ],
                      level: 3,
                      isOpen: false,
                      type: 'balance',
                      cells: balanceCells,
                    };

                    result.rows.set(balanceRow.id, balanceRow);
                  }
                });

                if (notFilteredBalances || notFilteredExpires) {
                  notFilteredPortfolioBalances++;
                }

                if (!balances?.length && !expires?.length) {
                  result.rows.delete(balanceSectionRow.id);
                } else if (notFilteredBalances === 0 && notFilteredExpires === 0) {
                  result.rows.delete(balanceSectionRow.id);
                }
              }
            );

            if (notFilteredPortfolioBalances === 0) {
              // result.rows.delete(portfolioBalanceRow.id);
              if (!state.ui.tableFilters.controls['Hide empty folders']) {
                result.rows.set(portfolioBalanceRow.id, {
                  id: `empty-${portfolioBalanceRow.id}`,
                  parentId: `exchange-${exchangeId}`,
                  parents: [`exchange-${exchangeId}`],
                  level: 1,
                  isOpen: false,
                  type: 'portfolio',
                  cells: portfolioCells,
                });
              } else {
                result.rows.delete(portfolioBalanceRow.id);
              }
            } else {
              notFilteredExchanges++;
            }
          }
        );

        if (notFilteredExchanges === 0) {
          result.rows.set(exchangeBalanceRow.id, {
            id: `empty-${exchangeId}`,
            parents: [],
            level: 0,
            isOpen: false,
            type: 'exchange',
            cells: exchangeCells,
          });
        }
      }
    }
  );

  return result;
};

export const selectProViewColumnsFilter = (state: RootState) => {
  return state.account.proViewColumnsFilter;
};

export const selectProViewCoinsFilter = (state: RootState) => {
  return state.account.filters.coins;
};

export const selectProViewExchangesFilter = (state: RootState) => {
  return state.account.filters.exchanges;
};

export const selectExchangesByAccountId = (accountId: number) => (state: RootState) => {
  const account = state.account.list.find((account: Account) => {
    return account.accountId === accountId;
  });

  if (account) {
    return account.exchanges;
  }

  return null;
};

export const selectExchangesByActiveAccountId = (state: RootState) => {
  const account = state.account.list.find((account: Account) => {
    return account.accountId === state.account.activeAccountId;
  });

  if (account) {
    return account.exchanges;
  }

  return null;
};

export const selectIsFetchingBalances = (state: RootState) => {
  return state.account.isFetchingBalances;
};

export default accountSlice.reducer;
