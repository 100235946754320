import React from 'react';
import cl from 'clsx';
import { Typography } from 'src/ui-kit';

import css from './Checkbox.module.scss';
import { isString } from 'lodash';

export type CheckboxProps = {
  name: string;
  checked: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  className?: string;
  onChange: (name: string, checked: boolean) => void;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  checked,
  disabled = false,
  label,
  className,
  onChange,
}) => {
  const handleChange = () => onChange(name, !checked);

  return (
    <div
      className={cl(
        css.checkbox,
        label && css.wideLabel,
        disabled && css.disabled,
        className && className
      )}
    >
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        className={css.hiddenInput}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={name}>
        <div className={css.tickmark}></div>
        {label && (
          <div>
            {isString(label) ? (
              <Typography.P1 as="span" className={css.labelText}>
                {label}
              </Typography.P1>
            ) : (
              label
            )}
          </div>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
