import React from 'react';
import cl from 'clsx';

import css from './Switch.module.scss';

export type SwitchProps = {
  name: string;
  size?: 'small' | 'large';
  isChecked: boolean;
  className?: string;
  onChange: (isChecked: boolean) => void;
};

const Switch: React.FunctionComponent<SwitchProps> = ({
  name,
  size = 'small',
  isChecked,
  className,
  onChange,
}) => {
  const handleChange = () => {
    onChange(!isChecked);
  };

  return (
    <div className={cl(css.switch, css[size], className && className)}>
      <input
        name={name}
        id={name}
        type="checkbox"
        checked={isChecked}
        className={css.hiddenInput}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={name}></label>
    </div>
  );
};

export default Switch;
