import { useNavigate } from 'react-router-dom';
import cl from 'clsx';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectOnlyAttachedCustomViews } from 'src/store/slices/customViewsSlice';
import {
  CollapseSidebarButton,
  CustomViewsButton,
  Scrollbar,
  SidebarButton,
  SidebarLogo,
} from 'src/ui-kit';
import { selectActiveViewId, selectIsMainView } from 'src/store/slices/activeView';
import {
  selectIsCollapsedSidebar,
  selectIsDarkTheme,
  switchIsCollapsedSidebar,
} from 'src/store/slices/uiSlice';
import { MAIN_VIEW_ROUTE } from 'src/constants';
import { truncate } from 'src/utils';

import { ReactComponent as LogoIcon } from '@images/logo.svg';
import styles from './Sidebar.module.scss';
import { selectIsLoggedIn } from 'src/store/slices/userSlice';

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isCollapsedSidebar = useAppSelector(selectIsCollapsedSidebar);
  const onlyAttachedCustomViews = useAppSelector(selectOnlyAttachedCustomViews);
  const activeViewId = useAppSelector(selectActiveViewId);
  const isMainView = useAppSelector(selectIsMainView);
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const handleCollapseSidebarButtonClick = () => dispatch(switchIsCollapsedSidebar());
  const handleNavigateToMainView = () => navigate(`/${MAIN_VIEW_ROUTE}`);
  const handleNavigateToCustomView = (viewId: string) => () => navigate(`/${viewId}`);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <aside className={cl(styles.sideMenu, isCollapsedSidebar && styles.isCollapsed)}>
      <SidebarLogo collapsed={isCollapsedSidebar} isDarkTheme={isDarkTheme} />
      <SidebarButton
        isActive={isMainView}
        isCollapsed={isCollapsedSidebar}
        isMainView
        text="Main View"
        onClick={handleNavigateToMainView}
      />
      <CustomViewsButton
        isCollapsed={isCollapsedSidebar}
        isDisabled={onlyAttachedCustomViews.length === 0}
      >
        <Scrollbar
          height="calc(100vh - 306px)"
          trackYRight={isCollapsedSidebar ? -9 : undefined}
          isDarkTheme={isDarkTheme}
        >
          {onlyAttachedCustomViews.map((item: any, index: number) => {
            return (
              <SidebarButton
                isActive={activeViewId === item.viewId}
                isCollapsed={isCollapsedSidebar}
                text={truncate(item.viewName, 21)}
                onClick={handleNavigateToCustomView(item.viewId)}
                index={index + 1}
                key={`${item.viewName}-${index}`}
              />
            );
          })}
        </Scrollbar>
      </CustomViewsButton>
      <CollapseSidebarButton
        isCollapsed={isCollapsedSidebar}
        onClick={handleCollapseSidebarButtonClick}
        className={styles.collapseSidebarButton}
      />
    </aside>
  );
};

export default Sidebar;
