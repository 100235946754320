import React, { useEffect, useState } from 'react';
import { ModalScreen } from '../../ModalMyEnvironment';
import {
  ButtonPrimary,
  Dropdown,
  DropdownOption,
  TextInput,
  TextInputSize,
  Typography,
  DatePicker,
  Dropdown2,
} from 'src/ui-kit';
import { useDispatch } from 'react-redux';
import { ReactComponent as SuccessIcon } from 'src/assets/images/completeExc.svg';

import styles from 'src/components/ModalMyEnvironment/components/NewPosition/NewPosition.module.scss';
import { selectIsProView, setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectCustomPositionSelectedProvider,
  selectCustomPositionsMetaInstrumentTypes,
  selectCustomPositionsMetaInstrumentTypesAvailableAvgPrice,
  selectCustomPositionsMetaInstrumentValue,
  selectNewPositionPortfolioId,
} from 'src/store/slices/positionsSlice';
import { RootState } from 'src/store/store';
import { selectCustomInstrumentListFilteredByType } from 'src/store/slices/customInstrumentSlice';
import { CustomInstrument } from 'src/types/customInstrument';
import { isDerivative, logError } from 'src/utils';
import { CustomPositionService } from 'src/services/CustomPosition.service';
import { fetchAccountBalance } from 'src/thunks';
import { selectActiveAccountId } from 'src/store/slices/accountSlice';
import { ReactComponent as PlusIcon } from 'src/assets/images/modalPlus.svg';

const getDropdownValues = (arr: any) => {
  if (arr === null) {
    return [];
  }
  return arr.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });
};

const getCustomInstrumentDropdownValues = (arr: any) => {
  if (arr === null) {
    return [];
  }
  return arr.map((item: any) => {
    return {
      label: item.instrumentName,
      value: item.id,
    };
  });
};

const computeAmountTitle = (
  selectedCustomInstrumentsOption: DropdownOption | null,
  selectedInstrumentType: string,
  customInstruments: CustomInstrument[]
) => {
  if (selectedCustomInstrumentsOption === null || customInstruments.length === 0) {
    return '';
  }

  if (isDerivative(selectedInstrumentType)) {
    return `(${
      customInstruments.find((item) => item.id === selectedCustomInstrumentsOption.value)
        ?.nominalCurrency.ticker
    })`;
  } else {
    return `(${
      customInstruments.find((item) => item.id === selectedCustomInstrumentsOption.value)
        ?.baseCurrency.ticker
    })`;
  }
};

const computeAvgPriceTitle = (
  selectedCustomInstrumentsOption: DropdownOption | null,
  selectedInstrumentType: string,
  customInstruments: CustomInstrument[]
) => {
  if (selectedCustomInstrumentsOption === null || customInstruments.length === 0) {
    return '';
  }

  if (isDerivative(selectedInstrumentType)) {
    const customInstrument = customInstruments.find(
      (item) => item.id === selectedCustomInstrumentsOption.value
    );

    if (selectedInstrumentType === 'PERPETUAL' || selectedInstrumentType === 'FUTURES') {
      return `(${customInstrument?.baseCurrency.ticker}/${customInstrument?.quoteCurrency.ticker})`;
    } else {
      return `(${customInstrument?.settlementCurrency.ticker})`;
    }
  }

  return '';
};

type NewPositionProps = {
  setAccountSection: React.Dispatch<any>;
};

const NewPosition = ({ setAccountSection }: NewPositionProps) => {
  const [portfolios] = useState<DropdownOption[]>([{ value: 0, label: 'Main' }]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(0);
  const [instrumentTypes, setInstrumentTypes] = useState<DropdownOption[]>([]);
  const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
  const [customInstrumentsOptions, setCustomInstrumentsOptions] = useState<
    DropdownOption[]
  >([]);
  const [selectedCustomInstrumentsOption, setSelectedCustomInstrumentsOption] =
    useState<DropdownOption | null>(null);
  const [directionOptions, setDirectionOptions] = useState<DropdownOption[]>([]);
  const [selectedDirection, setSelectedDirection] = useState<DropdownOption | null>(null);
  const [amount, setAmount] = useState('');
  const [avgPrice, setAvgPrice] = useState('');
  const [tradeDate, setTradeDate] = useState<Date>(new Date());
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedProvider = useAppSelector(selectCustomPositionSelectedProvider);
  const metaInstrumentTypes = useAppSelector(selectCustomPositionsMetaInstrumentTypes);
  const direction = useAppSelector((state: RootState) =>
    selectCustomPositionsMetaInstrumentValue(state, selectedInstrumentType)
  );
  const customInstruments = useAppSelector((state: RootState) =>
    selectCustomInstrumentListFilteredByType(state, selectedInstrumentType)
  );
  const portfolioId = useAppSelector(selectNewPositionPortfolioId);
  const availableAvgPrice = useAppSelector(
    selectCustomPositionsMetaInstrumentTypesAvailableAvgPrice
  );

  const dispatch = useDispatch();

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleAvgPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvgPrice(event.target.value);
  };

  const isProView = useAppSelector(selectIsProView);
  const activeAccountId = useAppSelector(selectActiveAccountId);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const tradeTimestampUtc =
        Date.UTC(
          tradeDate.getUTCFullYear(),
          tradeDate.getUTCMonth(),
          tradeDate.getUTCDate(),
          tradeDate.getUTCHours(),
          tradeDate.getUTCMinutes(),
          tradeDate.getUTCSeconds()
        ) / 1000;

      await CustomPositionService.createCustomPosition({
        portfolioId: portfolioId,
        customInstrumentId: selectedCustomInstrumentsOption!.value,
        tradeDirection: selectedDirection!.value,
        amount: Number(amount),
        price: Number(avgPrice),
        tradeTimestamp: tradeTimestampUtc,
      });
      setIsSuccesful(true);
      await fetchAccountBalance(dispatch, activeAccountId, isProView);
    } catch (error) {
      logError(error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setInstrumentTypes(getDropdownValues(metaInstrumentTypes));
  }, [metaInstrumentTypes]);

  useEffect(() => {
    setSelectedDirection(null);
    setSelectedCustomInstrumentsOption(null);
    setCustomInstrumentsOptions([]);
    setDirectionOptions([]);
    setAmount('');
    setAvgPrice('');

    if (selectedInstrumentType === null) {
      return;
    }

    setCustomInstrumentsOptions(getCustomInstrumentDropdownValues(customInstruments));
    setDirectionOptions(getDropdownValues(direction));

    setTradeDate(new Date());
  }, [selectedInstrumentType]);

  useEffect(() => {
    setDirectionOptions([]);
    setAmount('');
    setAvgPrice('');

    setDirectionOptions(getDropdownValues(direction));
  }, [selectedCustomInstrumentsOption]);

  const requiredAvgPrice = () => {
    return availableAvgPrice.includes(selectedInstrumentType);
  };

  return (
    <div className={styles.newAccountScreen}>
      {!isSuccesful && (
        <>
          <div className={styles.topBar}>
            <div>
              <Typography.P1 color="purple" className={styles.providerName}>
                {selectedProvider?.name}
              </Typography.P1>
              <Typography.P1 color="secondary" className={styles.providerLabel}>
                Provider
              </Typography.P1>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.inputsWrapper}>
              <div className={styles.inputsColumnsWrapper}>
                <div>
                  <div className={styles.dropdownWrapper}>
                    <Dropdown
                      defaultValue={selectedPortfolio}
                      label=""
                      options={portfolios}
                      title="Choose portfolio"
                      onChange={(item) => {
                        setSelectedPortfolio(item.value);
                      }}
                      className={styles.dropdown}
                    />
                  </div>
                </div>

                <div className={styles.inputsColumns}>
                  <div className={styles.dropdownWrapper}>
                    <Dropdown
                      label=""
                      options={instrumentTypes}
                      title="Instrument type"
                      onChange={(item) => {
                        setSelectedInstrumentType(item.value);
                      }}
                      className={styles.dropdown}
                    />
                  </div>
                  <div className={styles.dropdownWrapperNameAndButton}>
                    <div className={styles.dropdownWrapperName}>
                      <Dropdown2
                        label=""
                        value={selectedCustomInstrumentsOption}
                        setValue={(option: DropdownOption | null) => {
                          setSelectedCustomInstrumentsOption(option);
                        }}
                        options={customInstrumentsOptions}
                        title="Custom instrument name"
                        disabled={selectedInstrumentType === null}
                        truncateNumber={30}
                        onChange={(item) => {
                          // setSelectedCustomInstrumentsOption(item);
                        }}
                        className={styles.dropdown}
                      />
                    </div>
                    <ButtonPrimary
                      icon={<PlusIcon />}
                      onClick={() => {
                        dispatch(
                          setMyEnvironmentModalScreen(ModalScreen.NewCustomInstrument)
                        );
                      }}
                      className={styles.newInstrumentButton}
                    />
                  </div>
                </div>
                <div className={styles.inputsColumns}>
                  <div className={styles.dropdownWrapper}>
                    <Dropdown2
                      label=""
                      value={selectedDirection}
                      setValue={(item) => {
                        setSelectedDirection(item);
                      }}
                      options={directionOptions}
                      title="Direction"
                      disabled={selectedInstrumentType === null}
                      onChange={(item) => {
                        // setSelectedDirection(item);
                      }}
                      className={styles.dropdown}
                    />
                  </div>
                  <div className={styles.dropdownWrapper}>
                    <TextInput
                      placeholder=""
                      value={amount}
                      title={`Amount ${computeAmountTitle(
                        selectedCustomInstrumentsOption,
                        selectedInstrumentType,
                        customInstruments
                      )}`}
                      disabled={selectedInstrumentType === null}
                      onChange={handleAmountChange}
                      inputClassName={styles.input}
                      size={TextInputSize.Small}
                      number
                    />
                  </div>
                  <div className={styles.dropdownWrapper}>
                    <TextInput
                      placeholder=""
                      value={avgPrice}
                      title={`Avg. Price ${computeAvgPriceTitle(
                        selectedCustomInstrumentsOption,
                        selectedInstrumentType,
                        customInstruments
                      )}`}
                      onChange={handleAvgPriceChange}
                      inputClassName={styles.input}
                      size={TextInputSize.Small}
                      disabled={selectedInstrumentType === null || !requiredAvgPrice()}
                      number
                    />
                  </div>
                  <div className={styles.dropdownWrapper}>
                    <DatePicker
                      selected={tradeDate}
                      title="Trade date"
                      disabled={selectedInstrumentType === null}
                      onChange={(date: Date) => {
                        setTradeDate(date);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              <ButtonPrimary
                text="Create"
                isDisabled={
                  selectedInstrumentType === null ||
                  selectedCustomInstrumentsOption === null ||
                  selectedDirection === null ||
                  amount.length === 0 ||
                  (requiredAvgPrice() && avgPrice.length === 0)
                }
                onClick={handleSubmit}
                className={styles.createButton}
              ></ButtonPrimary>
              <button
                onClick={() => {
                  dispatch(setMyEnvironmentModalScreen(ModalScreen.Positions));
                }}
                className={styles.cancelButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {isSuccesful && (
        <>
          <div className={styles.successTopWrapper}>
            <SuccessIcon className={styles.successIcon} />
            <Typography.P1 className={styles.successNote}>
              Setup finished succesfully
            </Typography.P1>
          </div>
          <div className={styles.buttonsWrapper}>
            <ButtonPrimary
              text="Finish setup"
              onClick={() => dispatch(setMyEnvironmentModalScreen(ModalScreen.Positions))}
              isDisabled={isSubmitting}
              className={styles.createButton}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NewPosition;
