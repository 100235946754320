import React from 'react';
import cl from 'clsx';
import { Typography } from 'src/ui-kit';

import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  label: string;
  isActive: boolean;
  onChange: () => void;
  className?: string;
};

const RadioButton = ({ label, isActive, onChange, className }: RadioButtonProps) => {
  return (
    <button
      className={cl(
        styles.radioButton,
        isActive && styles.isActive,
        className && className
      )}
      onClick={onChange}
    >
      <div className={styles.circle}></div>
      <Typography.P1>{label}</Typography.P1>
    </button>
  );
};

export default RadioButton;
