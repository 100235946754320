import cl from 'clsx';
import { Header } from 'src/components/Header';
import { AppRouter } from 'src/routes';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectIsCollapsedSidebar } from 'src/store/slices/uiSlice';

import styles from './Main.module.scss';

const Main = () => {
  const isCollapsedSidebar = useAppSelector(selectIsCollapsedSidebar);

  return (
    <div className={cl(styles.main, isCollapsedSidebar && styles.expandedContent)}>
      <Header />
      <main>
        <AppRouter />
      </main>
    </div>
  );
};

export default Main;
