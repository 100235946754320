import { configureStore } from '@reduxjs/toolkit';
import exchangeSlice from 'src/store/slices/exchangeSlice';
import priceSlice from 'src/store/slices/priceSlice';
import accountSlice from 'src/store/slices/accountSlice';
import customViewsSlice from 'src/store/slices/customViewsSlice';
import activeViewSlice from 'src/store/slices/activeView';
import uiSlice from 'src/store/slices/uiSlice';
import apiSlice from 'src/store/slices/apiSlice';
import createSagaMiddleware from '@redux-saga/core';
import userSlice from 'src/store/slices/userSlice';
import rootSaga from 'src/sagas/root.saga';
import positionsSlice from 'src/store/slices/positionsSlice';
import customInstrumentSlice from 'src/store/slices/customInstrumentSlice';

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      exchange: exchangeSlice,
      price: priceSlice,
      account: accountSlice,
      customView: customViewsSlice,
      activeView: activeViewSlice,
      ui: uiSlice,
      api: apiSlice,
      user: userSlice,
      positions: positionsSlice,
      customInstrument: customInstrumentSlice,
    },
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  });

  (store as any).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
