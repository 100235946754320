import { useState } from 'react';
import cl from 'clsx';
import {
  ButtonPrimary,
  ButtonSecondary,
  Card,
  Kebab,
  TextInput,
  TextInputSize,
  Typography,
} from 'src/ui-kit';
import { AccountService } from 'src/services/Account.service';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { ModalScreen } from '../../ModalMyEnvironment';
import { ReactComponent as ArrowIcon } from 'src/assets/images/custom-view-open.svg';

import styles from './AccountsScreenItem.module.scss';
import {
  selectIsProView,
  setMyEnvironmentModalScreen,
  setSelectedAcount,
} from 'src/store/slices/uiSlice';
import { fetchAllAccounts, fetchCustomViewBalance } from 'src/thunks';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { selectActiveViewId, selectIsMainView } from 'src/store/slices/activeView';
import { Exchange } from 'src/types/exchange';
import { selectAllExchangesPictureLinks } from 'src/store/slices/exchangeSlice';
import { ReactComponent as TickIcon } from 'src/assets/images/buttonTick.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/buttonCross.svg';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/renameSpinner.svg';

type AccountsScreenItemProps = {
  id: number;
  accountName: string;
  exchanges: Exchange[];
  index: number;
  setAccountSection: React.Dispatch<any>;
  onDelete: (account: any) => void;
};

const AccountsScreenItem = ({
  id,
  accountName,
  exchanges,
  index,
  setAccountSection,
  onDelete,
}: AccountsScreenItemProps) => {
  const dispatch = useAppDispatch();

  const [inputText, setInputText] = useState<string>(accountName);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSubmittingRename, setIsSubmittingRename] = useState<boolean>(false);

  const isProView = useAppSelector(selectIsProView);
  const isMainView = useAppSelector(selectIsMainView);
  const activeViewId = useAppSelector(selectActiveViewId);
  const exchangesPictures = useAppSelector(selectAllExchangesPictureLinks);

  const handleRenameBtnClick = () => {
    setIsEditMode(true);
  };

  const handleRename = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditMode(false);
  };

  const handleRenameSubmit = async () => {
    setIsSubmittingRename(true);
    await AccountService.renameView(id, {
      accountName: inputText,
    });

    await fetchAllAccounts(dispatch);

    if (!isMainView) {
      await fetchCustomViewBalance(dispatch, activeViewId, isProView);
    }

    setIsEditMode(false);
    setIsSubmittingRename(false);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.cardNameLine}>
        {!isEditMode && (
          <Typography.P1 className={styles.cardName}>{accountName}</Typography.P1>
        )}
        {isEditMode && (
          <div className={styles.renameWrapper}>
            <TextInput
              value={inputText}
              size={TextInputSize.Small}
              autoFocus
              disabled={isSubmittingRename}
              onChange={handleRename}
              className={styles.input}
            />
            <div className={styles.renameBtnWrapper}>
              {isSubmittingRename ? (
                <SpinnerIcon className={styles.spinner} />
              ) : (
                <>
                  <ButtonSecondary
                    text=""
                    onClick={handleBlur}
                    className={styles.renameBtn}
                  >
                    <CrossIcon className={styles.crossIcon} />
                  </ButtonSecondary>
                  <ButtonPrimary
                    text=""
                    size="small"
                    icon={<TickIcon />}
                    onClick={handleRenameSubmit}
                    className={styles.renameBtn}
                  />
                </>
              )}
            </div>
          </div>
        )}
        <Kebab
          items={[
            { title: 'Rename', onClick: handleRenameBtnClick },
            {
              title: 'Delete',
              onClick: () => {
                onDelete({ id, accountName });
              },
            },
          ]}
          menuPosition={(index + 1) % 3 === 0 ? 'right' : 'left'}
        />
      </div>
      <div>
        <div className={styles.exchanges}>
          {exchanges.map(({ exchangeType }, index) => {
            return (
              <img
                src={exchangesPictures[exchangeType]}
                alt={`${exchangeType} logo`}
                className={styles.exchangeLogo}
                key={`${exchangeType} ${index}`}
              />
            );
          })}
        </div>
        <div className={styles.bottomLine}>
          <div>
            <div className={styles.statusWrapper}>
              <div className={styles.status}>
                {exchanges.length === 0 ? (
                  <Typography.P1 as="span" className={styles.statusText}>
                    No providers connected
                  </Typography.P1>
                ) : (
                  <>
                    <div className={cl(styles.statusColor, styles.statusGreen)}>
                      <Typography.P1 as="span" className={styles.statusText}>
                        {exchanges.length}
                      </Typography.P1>
                    </div>
                    <Typography.P1 as="span" className={styles.statusText}>
                      {exchanges.length === 1 ? 'Provider' : 'Providers'} connected
                    </Typography.P1>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <button
              className={styles.manage}
              onClick={() => {
                setAccountSection({ accountId: id, accountName });
                dispatch(setSelectedAcount({ accountId: id, accountName }));
                dispatch(setMyEnvironmentModalScreen(ModalScreen.Exchanges));
              }}
            >
              <Typography.Small>Manage</Typography.Small>
              <ArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AccountsScreenItem;
