import React, { useEffect, useState } from 'react';
import cl from 'clsx';
import { ModalScreen } from '../../ModalMyEnvironment';
import {
  ButtonPrimary,
  Dropdown,
  TextInput,
  TextInputSize,
  Typography,
  DatePicker,
  LoadingIndicator,
  Dropdown2,
  DropdownOption,
  Switch,
} from 'src/ui-kit';
import { useDispatch } from 'react-redux';
import { ReactComponent as SuccessIcon } from 'src/assets/images/completeExc.svg';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './NewCustomInstrument.module.scss';
import { setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { getMonth, getShortenYear, isDerivative, logError } from 'src/utils';
import { CustomInstrumentService } from 'src/services/CustomInstrument.service';

const getDropdownValues = (arr: any) => {
  return arr.map((item: any) => {
    return {
      label: item === 'DOT' ? 'EXO' : item,
      value: item,
    };
  });
};

const getCurrenciesDropdownValues = (arr: any) => {
  return arr.map((item: any) => {
    return {
      label: item.ticker,
      value: item.id,
    };
  });
};

const getShortenSource = (source: 'DERIBIT' | 'OKX' | 'BYBIT' | 'BINANCE' | 'DOT') => {
  const shortenSources = {
    DERIBIT: 'DB',
    OKX: 'OX',
    BYBIT: 'BY',
    BINANCE: 'BN',
    DOT: 'EXO',
  };

  return shortenSources[source];
};

let allCurrencies: any = [];

const NewAccountScreen = () => {
  const [availableFields, setAvailableFields] = useState<any>({
    availableBaseCurrencyIds: [],
    availableInstrumentType: ['ASSET', 'OPTION', 'PERPETUAL', 'LOAN', 'FUTURES'],
    availableNominalCurrencyIds: [],
    availablePriceSource: [],
    availableQuoteCurrencyIds: [],
    availableSettlementCurrencyIds: [],
    isAllCurrencyAvailableForBase: false,
    isAllCurrencyAvailableForNominal: false,
    isAllCurrencyAvailableForQuote: false,
    isAllCurrencyAvailableForSettlement: false,
    isAvailableExpTime: false,
    isAvailableOptType: false,
    isAvailableStrike: false,
  });
  const [namePlaceholder, setNamePlaceholder] = useState('');
  const [instrumentTypesOptions, setInstrumentTypeOptions] = useState<any>([]);
  const [baseCurrency, setBaseCurrency] = useState<any>(null);
  const [quoteCurrency, setQuoteCurrency] = useState<any>(null);
  const [nominalCurrency, setNominalCurrency] = useState<any>(null);
  const [settlementCurrency, setSettlementCurrency] = useState<any>(null);
  const [priceSource, setPriceSource] = useState<any>(null);
  const [expiryDate, setExpiryDate] = useState<any>(() => {
    const today = new Date();
    today.setHours(8);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  });
  const [optionType, setOptionType] = useState<any>(null);
  const [priceSourceOptions, setPriceSourceOptions] = useState<any>([]);
  const [optionTypeOptions, setOptionTypeOptions] = useState<any>([]);
  const [currenciesDropdownOptions, setCurrenciesDropdownOptions] = useState<any>([]);
  const [quoteDropdownOptions, setQuoteDropdownOptions] = useState<any>([]);
  const [nominalDropdownOptions, setNominalDropdownOptions] = useState<any>([]);
  const [settlementDropdownOptions, setSettlementDropdownOptions] = useState<any>([]);
  const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [strikeText, setStrikeText] = useState('');
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingMeta, setIsFetchingMeta] = useState(false);
  const [isInverse, setIsInverse] = useState(false);
  const [selectedOptionTemplate, setSelectedOptionTemplate] =
    useState<DropdownOption | null>({
      value: 'CUSTOM',
      label: 'CUSTOM',
    });

  const dispatch = useDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const tradeTimestampUtc = availableFields.isAvailableExpTime
        ? Date.UTC(
            expiryDate.getUTCFullYear(),
            expiryDate.getUTCMonth(),
            expiryDate.getUTCDate(),
            expiryDate.getUTCHours(),
            expiryDate.getUTCMinutes(),
            expiryDate.getUTCSeconds()
          ) / 1000
        : null;

      const payload: any = {
        instrumentName: inputText,
        instrumentType: selectedInstrumentType.value,
        baseCurrencyId: baseCurrency.value,
        priceSource: priceSource.value,
      };

      if (isDerivative(selectedInstrumentType.value)) {
        payload.quoteCurrencyId = quoteCurrency.value;
        if (selectedInstrumentType.value === 'OPTION') {
          payload.nominalCurrencyId = nominalCurrency?.value;
          payload.settlementCurrencyId = settlementCurrency?.value;
        } else {
          payload.nominalCurrencyId = isInverse
            ? quoteCurrency?.value
            : baseCurrency?.value;
          payload.settlementCurrencyId = isInverse
            ? baseCurrency?.value
            : quoteCurrency?.value;
        }

        if (selectedInstrumentType.value === 'OPTION') {
          payload.optionType = optionType.value;
          payload.strike = strikeText;
          payload.expirationTimestamp = tradeTimestampUtc;
        } else if (selectedInstrumentType.value === 'FUTURES') {
          payload.expirationTimestamp = tradeTimestampUtc;
        }
      }
      await CustomInstrumentService.createCustomInstrument(payload);
      setIsSuccesful(true);
    } catch (error) {
      logError(error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const getMeta = async () => {
      setIsFetchingMeta(true);
      try {
        const response: any = await CustomInstrumentService.getMeta();
        setInstrumentTypeOptions(getDropdownValues(response.data.instrumentTypes));
        setOptionTypeOptions(getDropdownValues(response.data.optionTypes));
        allCurrencies = response.data.currencies;
        setIsFetchingMeta(false);
      } catch (error) {
        logError(error);
      }
    };

    getMeta();
  }, []);

  const handleInstrumentTypeChange = async (instrumentType: any) => {
    const response: any = await CustomInstrumentService.getTemplate({
      instrumentType,
    });

    setAvailableFields(response.data);
  };

  useEffect(() => {
    if (availableFields.isAllCurrencyAvailableForBase) {
      setCurrenciesDropdownOptions(getCurrenciesDropdownValues(allCurrencies));
    } else {
      setCurrenciesDropdownOptions(
        getCurrenciesDropdownValues(
          allCurrencies.filter((item: any) =>
            availableFields.availableBaseCurrencyIds.includes(item.id)
          )
        )
      );
    }
    if (availableFields.isAllCurrencyAvailableForQuote) {
      setQuoteDropdownOptions(getCurrenciesDropdownValues(allCurrencies));
    } else {
      setQuoteDropdownOptions(
        getCurrenciesDropdownValues(
          allCurrencies.filter((item: any) =>
            availableFields.availableQuoteCurrencyIds.includes(item.id)
          )
        )
      );
    }
    if (availableFields.isAllCurrencyAvailableForNominal) {
      setNominalDropdownOptions(getCurrenciesDropdownValues(allCurrencies));
    } else {
      setNominalDropdownOptions(
        getCurrenciesDropdownValues(
          allCurrencies.filter((item: any) =>
            availableFields.availableNominalCurrencyIds.includes(item.id)
          )
        )
      );
    }
    if (availableFields.isAllCurrencyAvailableForSettlement) {
      setSettlementDropdownOptions(getCurrenciesDropdownValues(allCurrencies));
    } else {
      setSettlementDropdownOptions(
        getCurrenciesDropdownValues(
          allCurrencies.filter((item: any) =>
            availableFields.availableSettlementCurrencyIds.includes(item.id)
          )
        )
      );
    }

    setPriceSourceOptions(getDropdownValues(availableFields?.availablePriceSource));
  }, [availableFields]);

  useEffect(() => {
    if (selectedOptionTemplate?.value === 'DERIBIT') {
      setNominalCurrency(baseCurrency);
      setSettlementCurrency(baseCurrency);
      setPriceSource({
        label: 'DERIBIT',
        value: 'DERIBIT',
      });
    } else if (selectedOptionTemplate?.value === 'OKX') {
      setNominalCurrency(baseCurrency);
      setSettlementCurrency(baseCurrency);
      setPriceSource({
        label: 'OKX',
        value: 'OKX',
      });
    }
  }, [selectedOptionTemplate?.value]);

  useEffect(() => {
    setBaseCurrency(null);
    setQuoteCurrency(null);
    setNominalCurrency(null);
    setSettlementCurrency(null);
    setPriceSource(null);
    setStrikeText('');
    setOptionType(null);
    setInputText('');
    setNamePlaceholder('');
    setIsInverse(false);
    setSelectedOptionTemplate({
      value: 'CUSTOM',
      label: 'CUSTOM',
    });
  }, [selectedInstrumentType]);

  const validateFields = () => {
    if (
      selectedInstrumentType === null ||
      inputText.length === 0 ||
      baseCurrency === null ||
      priceSource === null
    ) {
      return true;
    }

    if (
      (availableFields.isAllCurrencyAvailableForQuote ||
        availableFields.availableQuoteCurrencyIds.length > 0) &&
      quoteCurrency === null
    ) {
      return true;
    }

    if (
      (availableFields.isAllCurrencyAvailableForNominal ||
        availableFields.availableNominalCurrencyIds.length > 0) &&
      nominalCurrency === null
    ) {
      return true;
    }

    if (
      (availableFields.isAllCurrencyAvailableForSettlement ||
        availableFields.availableSettlementCurrencyIds.length > 0) &&
      nominalCurrency === null
    ) {
      return true;
    }

    if (availableFields.isAvailableExpTime && !expiryDate) {
      return true;
    }

    if (availableFields.isAvailableStrike && strikeText.length === 0) {
      return true;
    }

    if (availableFields.isAvailableOptType && optionType === null) {
      return true;
    }
  };

  useEffect(() => {
    if (selectedInstrumentType?.value === 'ASSET' && baseCurrency && priceSource) {
      setNamePlaceholder(`${baseCurrency.label} ${getShortenSource(priceSource.value)}`);

      setInputText(`${baseCurrency.label} (${getShortenSource(priceSource.value)})`);
    } else if (selectedInstrumentType?.value === 'LOAN' && baseCurrency && priceSource) {
      setNamePlaceholder(
        `${baseCurrency.label}-LOAN ${getShortenSource(priceSource.value)}`
      );

      setInputText(`${baseCurrency.label}-LOAN (${getShortenSource(priceSource.value)})`);
    } else if (
      selectedInstrumentType?.value === 'PERPETUAL' &&
      baseCurrency &&
      quoteCurrency &&
      priceSource
    ) {
      setNamePlaceholder(
        `${baseCurrency.label}${quoteCurrency.label}-PERP (${getShortenSource(
          priceSource.value
        )}})`
      );

      setInputText(
        `${baseCurrency.label}${quoteCurrency.label}-PERP (${getShortenSource(
          priceSource.value
        )})`
      );
    } else if (
      selectedInstrumentType?.value === 'OPTION' &&
      baseCurrency &&
      quoteCurrency &&
      priceSource &&
      strikeText &&
      optionType
    ) {
      setNamePlaceholder(
        `${baseCurrency.label}${quoteCurrency.label}-${expiryDate.getUTCDate()}${getMonth(
          expiryDate.getUTCMonth()
        )}${getShortenYear(expiryDate.getUTCFullYear())}-${strikeText}-${
          optionType?.label[0]
        } (${getShortenSource(priceSource.value)})`
      );

      setInputText(
        `${baseCurrency.label}${quoteCurrency.label}-${expiryDate.getUTCDate()}${getMonth(
          expiryDate.getUTCMonth()
        )}${getShortenYear(expiryDate.getUTCFullYear())}-${strikeText}-${
          optionType?.label[0]
        } (${getShortenSource(priceSource.value)})`
      );
    } else if (
      selectedInstrumentType?.value === 'FUTURES' &&
      baseCurrency &&
      quoteCurrency &&
      priceSource
    ) {
      setNamePlaceholder(
        `${baseCurrency.label}${quoteCurrency.label}-${expiryDate.getUTCDate()}${getMonth(
          expiryDate.getUTCMonth()
        )}${getShortenYear(expiryDate.getUTCFullYear())} (${getShortenSource(
          priceSource.value
        )})`
      );

      setInputText(
        `${baseCurrency.label}${quoteCurrency.label}-${expiryDate.getUTCDate()}${getMonth(
          expiryDate.getUTCMonth()
        )}${getShortenYear(expiryDate.getUTCFullYear())} (${getShortenSource(
          priceSource.value
        )})`
      );
    } else {
      setNamePlaceholder('');
      setInputText('');
    }
  }, [
    selectedInstrumentType,
    baseCurrency,
    priceSource,
    strikeText,
    optionType,
    expiryDate,
  ]);

  useEffect(() => {
    const updateTemplate = async () => {
      const response: any = await CustomInstrumentService.getTemplate({
        instrumentType: selectedInstrumentType?.value,
        baseCurrencyId: baseCurrency?.value,
      });
      setAvailableFields(response.data);

      if (
        selectedInstrumentType.value === 'OPTION' &&
        (selectedOptionTemplate?.value === 'DERIBIT' ||
          selectedOptionTemplate?.value === 'OKX')
      ) {
        setNominalCurrency(baseCurrency);
        setSettlementCurrency(baseCurrency);
        setPriceSource({
          label: selectedOptionTemplate?.value,
          value: selectedOptionTemplate?.value,
        });
      }
    };
    updateTemplate();
  }, [baseCurrency?.value]);

  useEffect(() => {
    const updateTemplate = async () => {
      const response: any = await CustomInstrumentService.getTemplate({
        instrumentType: selectedInstrumentType?.value,
        baseCurrencyId: baseCurrency?.value,
        quoteCurrencyId: quoteCurrency?.value,
      });
      setAvailableFields(response.data);
    };
    if (quoteCurrency?.value) {
      updateTemplate();
    }
  }, [quoteCurrency?.value]);

  useEffect(() => {
    if (
      baseCurrency &&
      quoteCurrency &&
      (selectedInstrumentType.value === 'PERPETUAL' ||
        selectedInstrumentType.value === 'FUTURES')
    ) {
      if (isInverse) {
        setNominalCurrency(quoteCurrency);
        setSettlementCurrency(baseCurrency);
      } else {
        setNominalCurrency(baseCurrency);
        setSettlementCurrency(quoteCurrency);
      }
    }
  }, [baseCurrency, quoteCurrency, isInverse]);

  return (
    <div className={cl(styles.newAccountScreen, isSuccesful && styles.successScreen)}>
      {isFetchingMeta ? (
        <div className={styles.loadingIndicatorWrapper}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {!isSuccesful && (
            <>
              <div className={styles.inputsWrapper}>
                <Typography.P1 className={styles.screenDescription}>
                  Fill the details below to create your custom instrument
                </Typography.P1>
                <div className={styles.inputsColumnsWrapper}>
                  <div className={styles.firstLineWrapper}>
                    <div className={styles.dropdownWrapper}>
                      <Dropdown
                        label=""
                        title="Instrument type"
                        options={instrumentTypesOptions}
                        onChange={(item) => {
                          setSelectedInstrumentType(item);
                          handleInstrumentTypeChange(item.value);
                        }}
                        className={styles.dropdown}
                      />
                    </div>
                  </div>

                  <div className={styles.inputsColumns}>
                    <div className={styles.dropdownWrapper}>
                      <Dropdown2
                        label=""
                        title="Base currency"
                        options={currenciesDropdownOptions}
                        value={baseCurrency}
                        setValue={async (item: DropdownOption | null) => {
                          setBaseCurrency(item);
                        }}
                        onChange={async (item) => {}}
                        filter
                        disabled={selectedInstrumentType === null}
                        className={styles.dropdown}
                      />
                    </div>
                    <div className={styles.dropdownWrapper}>
                      <Dropdown2
                        label=""
                        title="Quote currency"
                        options={quoteDropdownOptions}
                        value={quoteCurrency}
                        setValue={async (item: DropdownOption | null) => {
                          setQuoteCurrency(item);
                        }}
                        onChange={async (item) => {}}
                        className={styles.dropdown}
                        filter
                        disabled={
                          selectedInstrumentType === null ||
                          baseCurrency === null ||
                          (!availableFields.isAllCurrencyAvailableForQuote &&
                            availableFields.availableQuoteCurrencyIds.length === 0)
                        }
                      />
                    </div>
                    {(selectedInstrumentType?.value === 'PERPETUAL' ||
                      selectedInstrumentType?.value === 'FUTURES' ||
                      selectedInstrumentType?.value === 'OPTION') &&
                      baseCurrency &&
                      quoteCurrency && (
                        <div className={styles.underlyingAsset}>
                          <Typography.P1>
                            Underlying asset: {baseCurrency.label} / {quoteCurrency.label}
                          </Typography.P1>
                        </div>
                      )}
                  </div>
                  <div className={styles.inputsColumns}>
                    {selectedInstrumentType?.value === 'OPTION' && (
                      <div className={styles.dropdownWrapper}>
                        <Dropdown2
                          label=""
                          value={selectedOptionTemplate}
                          setValue={(item) => {
                            setSelectedOptionTemplate(item);
                          }}
                          title="Options templates"
                          options={[
                            {
                              value: 'CUSTOM',
                              label: 'CUSTOM',
                            },
                            {
                              value: 'DERIBIT',
                              label: 'DERIBIT',
                            },
                            {
                              value: 'OKX',
                              label: 'OKX',
                            },
                          ]}
                          disabled={
                            selectedInstrumentType === null ||
                            baseCurrency === null ||
                            quoteCurrency === null
                          }
                          onChange={(item) => {
                            // setSelectedInstrumentType(item);
                            // handleInstrumentTypeChange(item.value);
                          }}
                          className={styles.dropdown}
                        />
                      </div>
                    )}
                    <div className={styles.dropdownWrapper}>
                      {selectedInstrumentType?.value === 'OPTION' &&
                      selectedOptionTemplate?.value === 'CUSTOM' ? (
                        <Dropdown2
                          label=""
                          title="Nominal currency"
                          options={nominalDropdownOptions}
                          value={nominalCurrency}
                          setValue={async (item: DropdownOption | null) => {
                            setNominalCurrency(item);
                          }}
                          onChange={async (item) => {}}
                          className={styles.dropdown}
                          filter
                          disabled={
                            selectedInstrumentType === null ||
                            baseCurrency === null ||
                            quoteCurrency === null ||
                            (!availableFields.isAllCurrencyAvailableForNominal &&
                              availableFields.availableNominalCurrencyIds.length === 0)
                          }
                        />
                      ) : (
                        <TextInput
                          title="Nominal currency"
                          placeholder=""
                          value={nominalCurrency ? nominalCurrency.label : ''}
                          onChange={() => {}}
                          inputClassName={styles.input}
                          disabled={
                            !baseCurrency ||
                            !quoteCurrency ||
                            !selectedInstrumentType === null
                          }
                          flushed={
                            selectedInstrumentType && baseCurrency && quoteCurrency
                          }
                          size={TextInputSize.Small}
                        />
                      )}
                    </div>
                    <div className={styles.dropdownWrapper}>
                      {selectedInstrumentType?.value === 'OPTION' &&
                      selectedOptionTemplate?.value === 'CUSTOM' ? (
                        <Dropdown2
                          label=""
                          title="Settlement currency"
                          options={settlementDropdownOptions}
                          value={settlementCurrency}
                          setValue={async (item: DropdownOption | null) => {
                            setSettlementCurrency(item);
                          }}
                          onChange={async (item) => {}}
                          className={styles.dropdown}
                          filter
                          disabled={
                            selectedInstrumentType === null ||
                            baseCurrency === null ||
                            quoteCurrency === null ||
                            (!availableFields.isAllCurrencyAvailableForSettlement &&
                              availableFields.availableSettlementCurrencyIds.length === 0)
                          }
                        />
                      ) : (
                        <TextInput
                          title="Settlement currency"
                          placeholder=""
                          value={settlementCurrency ? settlementCurrency.label : ''}
                          onChange={() => {}}
                          inputClassName={styles.input}
                          disabled={
                            !baseCurrency ||
                            !quoteCurrency ||
                            !selectedInstrumentType === null
                          }
                          flushed={
                            selectedInstrumentType && baseCurrency && quoteCurrency
                          }
                          size={TextInputSize.Small}
                        />
                      )}
                    </div>
                    {(selectedInstrumentType?.value === 'PERPETUAL' ||
                      selectedInstrumentType?.value === 'FUTURES') &&
                      baseCurrency &&
                      quoteCurrency && (
                        <div className={styles.underlyingAsset}>
                          <Typography.P1>Linear</Typography.P1>
                          <Switch
                            name="inverse"
                            size="large"
                            isChecked={isInverse}
                            onChange={() => {
                              setIsInverse(!isInverse);
                            }}
                          />
                          <Typography.P1>Inverse</Typography.P1>
                        </div>
                      )}
                  </div>
                  <div className={styles.inputsColumns}>
                    <div className={styles.dropdownWrapper}>
                      <DatePicker
                        title="Expiry date (UTC)"
                        selected={availableFields.isAvailableExpTime ? expiryDate : null}
                        onChange={(date) => {
                          setExpiryDate(date);
                        }}
                        disabled={!availableFields.isAvailableExpTime}
                      />
                    </div>
                    <div className={styles.dropdownWrapper}>
                      <TextInput
                        title="Strike"
                        placeholder=""
                        value={strikeText}
                        onChange={(event) => {
                          setStrikeText(event.target.value);
                        }}
                        inputClassName={styles.input}
                        disabled={!availableFields.isAvailableStrike}
                        size={TextInputSize.Small}
                      />
                    </div>
                    <div className={styles.dropdownWrapper}>
                      <Dropdown2
                        title="Option type"
                        label=""
                        options={optionTypeOptions}
                        value={optionType}
                        setValue={(item) => {
                          setOptionType(item);
                        }}
                        onChange={(item) => {
                          // setOptionType(item);
                        }}
                        disabled={!availableFields.isAvailableOptType}
                        className={styles.dropdown}
                      />
                    </div>
                    <div className={styles.dropdownWrapper}>
                      {(selectedInstrumentType?.value === 'OPTION' &&
                        selectedOptionTemplate?.value === 'DERIBIT') ||
                      (selectedInstrumentType?.value === 'OPTION' &&
                        selectedOptionTemplate?.value === 'OKX') ? (
                        <TextInput
                          title="Price source"
                          placeholder=""
                          value={priceSource?.label ? priceSource?.label : ''}
                          onChange={() => {}}
                          inputClassName={styles.input}
                          disabled={!selectedInstrumentType === null}
                          flushed={
                            selectedInstrumentType && baseCurrency && quoteCurrency
                          }
                          size={TextInputSize.Small}
                        />
                      ) : (
                        <Dropdown2
                          label=""
                          title="Price source"
                          options={priceSourceOptions}
                          value={priceSource}
                          setValue={(item) => {
                            setPriceSource(item);
                          }}
                          onChange={(item) => {
                            // setPriceSource(item);
                          }}
                          disabled={selectedInstrumentType === null}
                          className={styles.dropdown}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={styles.instrumentName}>
                      <TextInput
                        placeholder={namePlaceholder}
                        disabled={validateFields()}
                        title="Choose custom instrument name"
                        value={inputText}
                        onChange={handleInputChange}
                        inputClassName={styles.input}
                        size={TextInputSize.Small}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonsWrapper}>
                <ButtonPrimary
                  text="Create"
                  isDisabled={validateFields()}
                  onClick={handleSubmit}
                  className={styles.createButton}
                ></ButtonPrimary>
                <button
                  onClick={() =>
                    dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomInstruments))
                  }
                  className={styles.cancelButton}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
          {isSuccesful && (
            <>
              <div className={styles.successTopWrapper}>
                <SuccessIcon className={styles.successIcon} />
                <Typography.P1 className={styles.successNote}>
                  Instrument setup completed
                </Typography.P1>
              </div>
              <div className={styles.buttonsWrapper}>
                <ButtonPrimary
                  text="Finish setup"
                  onClick={() =>
                    dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomInstruments))
                  }
                  isDisabled={isSubmitting}
                  className={styles.createButtonTwo}
                ></ButtonPrimary>
                <button
                  onClick={() => {
                    setBaseCurrency(null);
                    setQuoteCurrency(null);
                    setNominalCurrency(null);
                    setSettlementCurrency(null);
                    setPriceSource(null);
                    setStrikeText('');
                    setOptionType(null);
                    setInputText('');
                    setNamePlaceholder('');
                    setIsSuccesful(false);
                  }}
                  className={styles.cancelButton}
                >
                  Add new custom instrument
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NewAccountScreen;
