import React from 'react';
import cl from 'clsx';
import { ButtonPrimary, Typography } from 'src/ui-kit';
import { ReactComponent as GreenTickIcon } from 'src/assets/images/green_tick.svg';
import styles from './EmailConfirmed.module.scss';

const EmailConfirmed = ({ goBack }: { goBack: () => void }) => (
  <div className={cl(styles.signInForm, styles.confirmationLink)}>
    <>
      <GreenTickIcon />
      <div className={styles.confirmationWrapper}>
        <div>
          <Typography.H1
            isUppercase
            weight={700}
            align="center"
            className={styles.linkHeading}
          >
            success
          </Typography.H1>
          <Typography.H3 weight={500} align="center">
            Your email has been successfully verified
          </Typography.H3>
        </div>
        <div>
          <ButtonPrimary
            text="Continue"
            size="large"
            isFullWidth
            onClick={goBack}
          ></ButtonPrimary>
        </div>
      </div>
    </>
  </div>
);

export default EmailConfirmed;
