import React, { useState } from 'react';
import cl from 'clsx';

import { ReactComponent as KebabIcon } from '../../assets/images/kebab.svg';

import styles from './Kebab.module.scss';
import { Typography } from '../Typography';

export type KebabProps = {
  items: KebabItem[];
  menuPosition?: 'left' | 'right' | 'right-bottom';
  className?: string;
};

interface KebabItem {
  title: string;
  onClick: (e: React.SyntheticEvent<Element, Event>) => void;
}

const Kebab = ({ items, menuPosition = 'left', className }: KebabProps) => {
  const [isActive, setActive] = useState(false);

  const createClickHandler =
    (newState = !isActive) =>
    () =>
      setActive(newState);

  return (
    <>
      {isActive && (
        <div className={styles.backdrop} onClick={createClickHandler(false)} />
      )}
      <button
        className={cl(styles.kebab, isActive && styles.active, className && className)}
        onClick={createClickHandler()}
      >
        <>
          <KebabIcon className={styles.kebabIcon} />
          {isActive && (
            <div
              className={cl(
                styles.menu,
                menuPosition === 'right' && styles.menuRight,
                menuPosition === 'right-bottom' && styles.menuRightBottom
              )}
            >
              {items.map((item, index) => {
                return (
                  <div
                    onClick={item.onClick}
                    className={styles.menuItem}
                    key={`${item.title}-${index}`}
                  >
                    <Typography.P1 as="span">{item.title}</Typography.P1>
                  </div>
                );
              })}
            </div>
          )}
        </>
      </button>
    </>
  );
};

export default Kebab;
