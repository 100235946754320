import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { PriceService } from 'src/services/Price.service';
import { setPrices } from 'src/store/slices/priceSlice';
import { Price } from 'src/types/price';
import { logError } from 'src/utils';

export const fetchStablePrices = async (dispatch: Dispatch<AnyAction>) => {
  try {
    const result: { data: Price[] } = await PriceService.getStable();

    dispatch(setPrices(result.data));
  } catch (err) {
    logError(err);
  }
};
