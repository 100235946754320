import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { isBoolean, isEmpty } from 'lodash';
import { AccountService } from 'src/services/Account.service';
import {
  setAccountBalance,
  setAccountBalance2,
  setAccountList,
  setActiveAccountId,
  setInitialFilters,
  setIsFetchingBalances,
  setProViewBalance,
  setProViewBalance2,
  setProViewColumnsFilter,
  updateFilters,
} from 'src/store/slices/accountSlice';
import { Account } from 'src/types/account';
import { AccountBalance } from 'src/types/balance';
import { logError } from 'src/utils';

export const fetchAllAccounts = async (
  dispatch: Dispatch<AnyAction>,
  isMainView: boolean = false,
  isInitial: boolean = false
) => {
  const allAccounts: { data: Account[] } = await AccountService.allAccounts();

  dispatch(setAccountList(allAccounts.data));

  if (isInitial && isMainView && allAccounts.data.length) {
    const persistedActiveAccountId = localStorage.getItem('activeAccountId');

    if (persistedActiveAccountId !== null) {
      const persistedActiveAccount = allAccounts.data.find((account) => {
        return account.accountId === Number(persistedActiveAccountId);
      });

      if (persistedActiveAccount) {
        dispatch(setActiveAccountId(persistedActiveAccount.accountId));
      }
    } else {
      dispatch(setActiveAccountId(allAccounts.data[0].accountId));
    }
  }
};

const fetchAccountBalanceAbortControllers: Record<string, AbortController> = {};

export const fetchAccountBalance = async (
  dispatch: Dispatch<AnyAction>,
  accountId: number | null,
  isProView: boolean,
  calledFromIntervalCallback = false
) => {
  if (!isEmpty(fetchAccountBalanceAbortControllers)) {
    Object.keys(fetchAccountBalanceAbortControllers).forEach((prevControllerId) => {
      fetchAccountBalanceAbortControllers[prevControllerId].abort();
      delete fetchAccountBalanceAbortControllers[prevControllerId];
    });
  }

  const controllerId = uuidv4();

  const abortController = new AbortController();
  fetchAccountBalanceAbortControllers[controllerId] = abortController;

  if (isProView) {
    try {
      if (accountId) {
        const result: any = await AccountService.getProViewBalanceById(
          accountId,
          abortController.signal
        );

        dispatch(setProViewBalance2(result.data));

        if (!calledFromIntervalCallback) {
          dispatch(setInitialFilters());
        }

        dispatch(updateFilters());

        const savedState = localStorage.getItem('proViewColumnsFilter');

        if (!savedState) {
          if (result.data) {
            dispatch(
              setProViewColumnsFilter(
                result.data[0].metadata.column.map((columnData: any) => {
                  return {
                    key: columnData.key,
                    name: columnData.name,
                    isChecked: columnData.default || columnData.attach,
                    isDefault: columnData.default,
                    isAttached: columnData.attach,
                    isColorized: columnData.colorize,
                    isWide: columnData.wide,
                    formatting: columnData.formatting,
                    grouping: columnData.grouping,
                    percent: columnData.percent,
                    condNumFormatting: columnData.condNumFormatting,
                    fiat: columnData.fiat,
                  };
                })
              )
            );
          }
        } else {
          const columnsFilterPayload = result.data[0]?.metadata.column.map(
            (columnData: any) => {
              const persistedColumnState = JSON.parse(savedState).find(
                (savedCol: any) => {
                  return savedCol.key === columnData.key;
                }
              );

              return {
                key: columnData.key,
                name: columnData.name,
                isChecked: isBoolean(persistedColumnState?.isChecked)
                  ? persistedColumnState?.isChecked
                  : columnData.default || columnData.attach,
                isDefault: columnData.default,
                isAttached: columnData.attach,
                isColorized: columnData.colorize,
                isWide: columnData.wide,
                formatting: columnData.formatting,
                grouping: columnData.grouping,
                percent: columnData.percent,
                condNumFormatting: columnData.condNumFormatting,
                fiat: columnData.fiat,
              };
            }
          );

          if (columnsFilterPayload) {
            dispatch(setProViewColumnsFilter(columnsFilterPayload));
          } else {
            dispatch(setProViewColumnsFilter(JSON.parse(savedState)));
          }
        }
      }

      dispatch(setIsFetchingBalances(false));
    } catch (error) {
      logError(error);
    }
  } else {
    try {
      if (accountId !== null) {
        const result: { data: AccountBalance[] } = await AccountService.getBalanceById(
          accountId,
          abortController.signal
        );

        dispatch(setAccountBalance(result.data[0]));
      }

      dispatch(setIsFetchingBalances(false));
    } catch (error) {
      logError(error);
    }
  }

  delete fetchAccountBalanceAbortControllers[controllerId];
};
