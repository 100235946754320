import axios from 'axios';

export const instance = axios.create({
  baseURL: String(process.env.REACT_APP_BACKEND_BASE_URL),
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('access_token');

    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      // originalRequest._retry = true;
      // const accessToken = localStorage.getItem('access_token');
      // if (accessToken) {
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
      // }
      // return instance(originalRequest);
    } else if (
      error.response &&
      error.response.status === 401 &&
      !error.request.responseURL.includes('sign-in') &&
      !error.request.responseURL.includes('sendChangePasswordEmail') &&
      !error.request.responseURL.includes('logout')
    ) {
      localStorage.removeItem('access_token');
      window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  }
);
